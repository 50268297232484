import axios from 'axios';
import {
  NOTIFICATION_NEW_NOTIFICATION,
  NOTIFICATION_GET_RECENTS_NOTIFICATIONS,
  NOTIFICATION_REQUEST,
  NOTIFICATION_ERROR,
  NOTIFICATION_UPDATE_STATUS,
} from '../actions/notifications';
import config from '@/config/config';
import Vue from 'vue';

const state = { status: '', notifications: {} };

const getters = {
  getRecentNotifications: (state) => state.notifications,
};

const actions = {
  [NOTIFICATION_NEW_NOTIFICATION]: ({ commit, dispatch }, notification) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/new/notifications',
          {
            user_id: notification.user_id,
            message: notification.message,
            title: notification.title,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(NOTIFICATION_GET_RECENTS_NOTIFICATIONS);
          resolve(resp);
        })
        .catch((err) => {
          commit(NOTIFICATION_ERROR);
          reject(err);
        });
    });
  },
  [NOTIFICATION_GET_RECENTS_NOTIFICATIONS]: ({ commit }) => {
    commit(NOTIFICATION_REQUEST);
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/get/notifications', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(NOTIFICATION_GET_RECENTS_NOTIFICATIONS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [NOTIFICATION_UPDATE_STATUS]: (ids) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          config.data.apiUrl + '/user/update/notifications',
          {
            ids: ids,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [NOTIFICATION_GET_RECENTS_NOTIFICATIONS]: (state, resp) => {
    state.status = 'success';
    Vue.set(state, 'notifications', resp.data);
  },
  [NOTIFICATION_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [NOTIFICATION_ERROR]: (state) => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
