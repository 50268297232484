export const applyCouponInOrders = (invoice) => {
  const newInvoice = invoice;
  const coursesBelongToCoupon = invoice.coupon.courses.map(
    (course) => course.id,
  );
  newInvoice.orders.forEach((order) => {
    if (
      coursesBelongToCoupon &&
      coursesBelongToCoupon.includes(order.course.id)
    ) {
      order.course.discount = invoice.coupon.discount;

      order.course.priceWithDiscount =
        order.course.price -
        (newInvoice.coupon.discount * order.course.price) / 100;
    }
  });

  return newInvoice;
};
