import axios from 'axios';
import config from '@/config/config';
import { FETCH_INVOICE, FETCH_USER_INVOICES } from '../actions/invoice';
import { applyCouponInOrders } from '../mutations/invoice';

const state = {
  invoice: undefined,
  invoicesList: {
    total: 0,
    items: [],
  },
};

const getters = {
  getCurrentInvoice: (state) => state.invoice,
  getInvoicesList: (state) => state.invoicesList,
  getCoursesInvoice: (state) =>
    state.invoice && state.invoice.orders ? state.invoice.orders : [],
};

const actions = {
  [FETCH_INVOICE]: ({ commit }, invoiceId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/invoice/${invoiceId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(FETCH_INVOICE, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_USER_INVOICES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/invoice/list`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_USER_INVOICES, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [FETCH_INVOICE]: (state, invoice) => {
    if (!invoice.coupon) state.invoice = invoice;
    else state.invoice = applyCouponInOrders(invoice);
  },
  [FETCH_USER_INVOICES]: (state, invoicesList) => {
    state.invoicesList = {
      total: invoicesList && invoicesList.total ? invoicesList.total : 0,
      items: invoicesList && invoicesList.data ? invoicesList.data : [],
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
