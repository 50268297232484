import Vue from 'vue';
import store from '@/store/index';
import { GET_COUNTRIES } from '../store/actions/countries';
import {
  FETCH_PUBLIC_CATEGORIES,
  FETCH_PUBLIC_INSTRUCTORS_FEATURED,
} from '../store/actions/public';
const globalSettings = () => {
  return new Vue({
    data() {
      return {
        countryError: null,
      };
    },
    async created() {
      try {
        await this.fetchCountries();
        await this.fetchPublicCategories();
        await this.fetchPublicInstructors();
        this.countryError = null;
      } catch (e) {
        this.countryError = e.message;
      }
    },
    methods: {
      async fetchCountries() {
        await store.dispatch(GET_COUNTRIES);
      },
      async fetchPublicCategories() {
        await store.dispatch(FETCH_PUBLIC_CATEGORIES);
      },
      async fetchPublicInstructors(
        filter = {
          order_by: 'featured',
        },
      ) {
        await store.dispatch(FETCH_PUBLIC_INSTRUCTORS_FEATURED, filter);
      },
    },
  });
};

export const GlobalSettings = {
  install(Vue) {
    Vue.directive('GlobalSettings', globalSettings());
  },
};
