<template>
  <div class="vue-skeleton-loading">
    <div class="skeleton-bac-animation"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoading',
};
</script>

<style>
@keyframes pulse {
  0% {
    background-position: -200px;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.vue-skeleton-loading {
  position: relative;
  width: 100%;
}

.vue-skeleton-loading > .skeleton-bac-animation {
  position: absolute;
  z-index: auto;
  width: 100%;
  height: 100%;
  background-size: 30% 100%;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    )
    no-repeat;
  -webkit-animation: pulse 0.9s ease-in-out 0s infinite;
  -o-animation: pulse 0.9s ease-in-out 0s infinite;
  animation: pulse 0.9s ease-in-out 0s infinite;
}
</style>
