import axios from 'axios';
import config from '@/config/config';

import {
  ADD_COURSE_TO_CART,
  DELETE_COURSE_TO_CART_AND_SAVE,
  DELETE_CART,
  //to redirect to register: object form
  PAYMENT_CHECKOUT,
  PAYMENT_CHECKOUT_AUTORIZE,
  ISLOADINGPAYMENT,
  SET_TOTAL_WITH_COUPON,
  APPLY_COUPON_TO_CART,
  REMOVE_APPLY_COUPON_FROM_CART,
} from '../actions/cart';
import {
  addCourseToCart,
  applyCouponToCart,
  deleteCart,
  removeApplyCouponFromCart,
  removeCourseToCart,
} from '../mutations/cart';
import { getCartFromStorage, getCartTotalFromStorage } from '../utils/cart';

const state = {
  cart: getCartFromStorage(),
  isLoadingPayment: false,
  coupon: undefined,
  discount: 0,
  totalWithDiscount: 0,
  showCart: 0,
  total: getCartTotalFromStorage(),
};

const getters = {
  getCartCount: (state) => state.cart.length,
  getCart: (state) => state.cart,
  getCartCoupon: (state) => state.coupon,
  getShowCart: (state) => state.showCart,
  getIsLoadingPayment: (state) => state.isLoadingPayment,
  getCartTotalPrice: (state) =>
    state.cart.reduce((total, cartItem) => total + cartItem.price, 0),
  getCartTotalWithDiscount: (state) => state.totalWithDiscount,
  getCartDiscount: (state) => state.discount,
};

const actions = {
  [ADD_COURSE_TO_CART]: ({ commit }, course) => {
    commit(ADD_COURSE_TO_CART, course);
  },
  [DELETE_COURSE_TO_CART_AND_SAVE]: ({ commit }, courseId) => {
    commit(DELETE_COURSE_TO_CART_AND_SAVE, courseId);
  },

  [DELETE_CART]: ({ commit }) => {
    commit(DELETE_CART);
  },
  [REMOVE_APPLY_COUPON_FROM_CART]: ({ commit }) => {
    commit(REMOVE_APPLY_COUPON_FROM_CART);
  },
  [APPLY_COUPON_TO_CART]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/coupon/check',
          {
            code: data.code,
            courses: data.courses,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          if (resp.data && resp.data.message) {
            return reject(resp.data.message);
          }
          if (resp.data && resp.data.courses && !resp.data.courses.length) {
            return reject(
              'El cupon no se aplica para los cursos seleccionados',
            );
          }

          commit(APPLY_COUPON_TO_CART, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //TODO: CHECK THIS

  //TODO: MOVE TO PAYMENT MODULE
  [PAYMENT_CHECKOUT]: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/user/payment/process/${data.orderID}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            // eslint-disable-next-line no-undef
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          },
        })
        .then((resp) => {
          dispatch(PAYMENT_CHECKOUT_AUTORIZE, resp.data)
            .then((resp) => {
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYMENT_CHECKOUT_AUTORIZE]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/user/payment/authorize/${data.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            // eslint-disable-next-line no-undef
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [ISLOADINGPAYMENT]: ({ commit }, val) => {
    commit(ISLOADINGPAYMENT, val);
  },
  [SET_TOTAL_WITH_COUPON]: ({ commit }, total) => {
    commit(SET_TOTAL_WITH_COUPON, total);
  },
};

const mutations = {
  [ADD_COURSE_TO_CART]: addCourseToCart,
  [DELETE_COURSE_TO_CART_AND_SAVE]: removeCourseToCart,
  [DELETE_CART]: deleteCart,
  [APPLY_COUPON_TO_CART]: applyCouponToCart,
  [REMOVE_APPLY_COUPON_FROM_CART]: removeApplyCouponFromCart,

  [SET_TOTAL_WITH_COUPON]: (state, total) => {
    state.cartVerifyTotal = total;
  },

  [ISLOADINGPAYMENT]: (state, val) => {
    if (val) {
      state.isLoadingPayment = val;
    } else {
      state.isLoadingPayment = val;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
