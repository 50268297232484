import axios from 'axios';
import {
  PAYPAL_AUTHORIZE_LINK,
  PAYPAL_CHECKOUT_SUCCESS,
  PAYPAL_UPDATE_DEFAULT_BILLING,
  PAYPAL_HANDLE_PAYMENT,
  PAYPAL_DELETE_ACCOUNT,
  PAYPAL_CLIENT_TOKEN,
  PAYPAL_CREATE_ORDER,
  PAYPAL_ORDER_CAPTURE,
} from '../actions/paypal';

import { USER_UPDATE_PAYPAL_ACCOUNTS } from '../actions/user';
import config from '@/config/config';

const state = { status: '' };

const getters = {};

const actions = {
  [PAYPAL_AUTHORIZE_LINK]: (user) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/paypal/authorize',
          {
            user_id: user.user_id,
            return_url: user.return_url,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYPAL_CHECKOUT_SUCCESS]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/paypal/success',
          {
            token: data.token,
            user_id: data.user_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYPAL_UPDATE_DEFAULT_BILLING]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/paypal/update/default/billing',
          {
            billing_id: data.billing_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYPAL_HANDLE_PAYMENT]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/paypal/handle-payment',
          {
            billing_id: data.billing_id,
            course_id: data.course_id,
            coupon_id: data.coupon_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYPAL_DELETE_ACCOUNT]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          config.data.apiUrl + '/user/paypal/account/delete/' + data.account_id,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          commit(USER_UPDATE_PAYPAL_ACCOUNTS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYPAL_CLIENT_TOKEN]: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/paypal/client-token',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYPAL_CREATE_ORDER]: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/payment/create-order',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYPAL_ORDER_CAPTURE]: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/payment/capture-order',
          { id },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
