import axios from 'axios';
import config from '@/config/config';
import Vue from 'vue';

import {
  CHARTS_GET_PROFIT_REVENUE_BY_COURSE_DATA,
  CHARTS_GET_LAST_ORDERS_BY_COURSE_DATA,
  CHARTS_GET_GENERAL_STATS_BY_COURSE_DATA,
  CHARTS_GET_PROFIT_REVENUE_BY_USER_DATA,
  CHARTS_GET_GENERAL_STATS_BY_USER_DATA,
  CHARTS_GET_LAST_ORDERS_BY_USER_DATA,
  CHARTS_GET_TOP_COURSES,
  CHARTS_GET_TOP_CUSTOMERS,
} from '../actions/charts';

const state = {
  last_orders: [],
  general_stats: undefined,
  top_courses: [],
  top_customers: [],
  recent_courses: [],
};

const getters = {
  getLastOrders: (state) => state.last_orders,
  getGeneralStats: (state) => state.general_stats,
  getTopCourses: (state) => state.top_courses,
  getTopCustomers: (state) => state.top_customers,
  getRecentCourses: (state) => state.recent_courses,
};

const actions = {
  [CHARTS_GET_GENERAL_STATS_BY_COURSE_DATA]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          config.data.apiUrl +
            '/stats/course/' +
            params.course_id +
            '?' +
            params.filter,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          commit(CHARTS_GET_GENERAL_STATS_BY_COURSE_DATA, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHARTS_GET_GENERAL_STATS_BY_USER_DATA]: ({ commit }, { filter }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/stats/all?' + filter, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(CHARTS_GET_GENERAL_STATS_BY_COURSE_DATA, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHARTS_GET_PROFIT_REVENUE_BY_COURSE_DATA]: (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          config.data.apiUrl +
            '/stats/revenue/course/' +
            params.course_id +
            '?' +
            params.filter,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHARTS_GET_PROFIT_REVENUE_BY_USER_DATA]: (_, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/stats/revenue/all?' + params.filter, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHARTS_GET_LAST_ORDERS_BY_COURSE_DATA]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/stats/orders/course/' + params.course_id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(CHARTS_GET_LAST_ORDERS_BY_COURSE_DATA, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHARTS_GET_LAST_ORDERS_BY_USER_DATA]: ({ commit }, { filter }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/stats/orders/all?${filter}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(CHARTS_GET_LAST_ORDERS_BY_COURSE_DATA, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHARTS_GET_TOP_COURSES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/stats/top/courses', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(CHARTS_GET_TOP_COURSES, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHARTS_GET_TOP_CUSTOMERS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/stats/top/customers?' + params.filter, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          console.log('customers store');
          commit(CHARTS_GET_TOP_CUSTOMERS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [CHARTS_GET_LAST_ORDERS_BY_COURSE_DATA]: (state, resp) => {
    Vue.set(state, 'last_orders', resp.data);
  },
  [CHARTS_GET_GENERAL_STATS_BY_COURSE_DATA]: (state, resp) => {
    Vue.set(state, 'general_stats', resp.data);
  },
  [CHARTS_GET_TOP_COURSES]: (state, resp) => {
    Vue.set(state, 'top_courses', resp.data);
  },
  [CHARTS_GET_TOP_CUSTOMERS]: (state, resp) => {
    Vue.set(state, 'top_customers', resp.data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
