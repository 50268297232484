import {
  CLOSE_COURSE_VIDEO_FORM,
  FETCH_COURSE_VIDEOS,
  OPEN_COURSE_VIDEO_FORM,
  CREATE_COURSE_VIDEO,
  DELETE_COURSE_VIDEO,
  UPDATE_COURSE_VIDEO,
  CHANGE_COURSE_VIDEO_FEATURE,
} from '../actions/courseVideo';
import config from '@/config/config';
import axios from 'axios';
import { DEFAULT_COURSE_VIDEO_VALUES } from '../../util/constants';

const state = {
  courseVideos: [],
  isOpenVideoForm: false,
  courseVideo: undefined,
};

const getters = {
  isOpenCourseVideoForm: (state) => state.isOpenVideoForm,
  getCourseVideo: (state) => state.courseVideo,
};

const actions = {
  [FETCH_COURSE_VIDEOS]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/course/all`, {
          params: filter,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(FETCH_COURSE_VIDEOS, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_COURSE_VIDEO]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.data.apiUrl}/admin/course/video/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  [CREATE_COURSE_VIDEO]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.data.apiUrl}/admin/course/video/create`,

          data,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  [UPDATE_COURSE_VIDEO]: (_, data) => {
    console.log('data', data);
    return new Promise((resolve, reject) => {
      axios
        .patch(`${config.data.apiUrl}/admin/course/video/update`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  [CHANGE_COURSE_VIDEO_FEATURE]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${config.data.apiUrl}/admin/course/video/update/featured`,

          data,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  [OPEN_COURSE_VIDEO_FORM]: ({ commit }, video) => {
    commit(OPEN_COURSE_VIDEO_FORM, video);
  },

  [CLOSE_COURSE_VIDEO_FORM]: ({ commit }) => {
    commit(CLOSE_COURSE_VIDEO_FORM);
  },
};

const mutations = {
  [FETCH_COURSE_VIDEOS]: (state, courseVideos) => {
    state.courseVideos = courseVideos.data;
  },

  [OPEN_COURSE_VIDEO_FORM]: (state, courseVideo) => {
    state.isOpenVideoForm = true;

    state.courseVideo = courseVideo
      ? {
          id: courseVideo.id,
          title: courseVideo.title,
          position: courseVideo.position,
          content: courseVideo.content,
          slug: courseVideo.slug,
          featured: courseVideo.featured,
          isPublic: courseVideo.is_public,
          status: courseVideo.status,
          video: courseVideo.video,
          products: (courseVideo.products ?? []).map((item) => item.product),
          poster: courseVideo.poster,
        }
      : DEFAULT_COURSE_VIDEO_VALUES;
  },
  [CLOSE_COURSE_VIDEO_FORM]: (state) => {
    state.isOpenVideoForm = false;
    state.courseVideo = undefined;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
