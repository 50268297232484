<template></template>

<script>
//const PayPalButton = paypal.Buttons.driver("vue", window.Vue);

export default {
  name: 'PayPalButton',
  data() {
    return {
      PayPalButton: null,
    };
  },
  beforeCreate() {
    paypal
      .Buttons({
        createOrder: function () {
          var SETEC_URL = 'http://maquillate.test/paypal/connect';

          return fetch(SETEC_URL, {
            method: 'post',
            headers: {
              'content-type': 'application/json',
            },
          })
            .then(function (res) {
              return res.json();
            })
            .then(function (data) {
              return data.token;
            });
        },
        createBillingAgreement: function () {
          var CREATE_BILLING_AGREEMENT_URL =
            'http://maquillate.test/paypal/billing';

          return fetch(CREATE_BILLING_AGREEMENT_URL, {
            method: 'post',
            headers: {
              'content-type': 'application/json',
            },
          })
            .then(function (res) {
              return res.json();
            })
            .then(function (data) {
              return data.billingToken;
            });
        },
      })
      .render('body');
  },
};
</script>

<style></style>
