import {
  CHECKOUT_CHANGE_STEP,
  CHECKOUT_SELECTED_PAYMENT,
} from '../actions/checkout';

const state = {
  activeStep: 'unselected',
  selectedPayment: undefined,
};

const getters = {
  getActiveStep: (state) => state.activeStep,
  getSelectedPayment: (state) => state.selectedPayment,
};

const actions = {
  [CHECKOUT_CHANGE_STEP]: ({ commit }, step) => {
    commit(CHECKOUT_CHANGE_STEP, step);
  },
  [CHECKOUT_SELECTED_PAYMENT]: ({ commit }, selectedPayment = undefined) => {
    commit(CHECKOUT_SELECTED_PAYMENT, selectedPayment);
  },
};

const mutations = {
  [CHECKOUT_CHANGE_STEP]: (state, step) => {
    state.activeStep = step;
  },
  [CHECKOUT_SELECTED_PAYMENT]: (state, selectedPayment) => {
    state.selectedPayment = selectedPayment;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
