import axios from 'axios';
import config from '@/config/config';
import _ from 'lodash';

const actions = {
  setBanks: (item) => {
    const configHead = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
      },
    };
    return new Promise((resolve) => {
      axios
        .post(config.data.apiUrl + '/admin/bank-commission', item, configHead)
        .then(() => resolve(true));
    });
  },
  getBanks: () => {
    const configHead = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
      },
    };
    return new Promise((resolve) => {
      axios
        .get(config.data.apiUrl + '/admin/bank-commission', configHead)
        .then(({ data }) => {
          const dataStripe = _.find(data, (o) => o.type === 'stripe');
          const dataPaypal = _.find(data, (o) => o.type === 'paypal');
          const paypal = {
            commission: dataPaypal.percentage,
            transaction: dataPaypal.transaction,
          };
          const stripe = {
            commission: dataStripe.percentage,
            transaction: dataStripe.transaction,
          };
          resolve({ stripe, paypal });
        });
    });
  },
};

export default {
  actions,
};
