import axios from 'axios';
import config from '@/config/config';
import {
  REGISTER_REQUEST,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  RESEND_VERIFICATION,
  USER_REGISTER_PARTNER,
  USER_INVITE_PARTNER,
  USER_REMOVE_INVITE_PARTNER,
  USER_EMAIL_VERIFICATION,
} from '../actions/register';

import { USER_REQUEST, USER_INVITATION_STATUS } from '../actions/user';

import { NOTIFICATION_NEW_NOTIFICATION } from '../actions/notifications';

const state = {
  token: '',
  status: '',
  emailExist: '',
  hasLoadedOnce: false,
};

const getters = {
  authStatusRegister: (state) => state.status,
  EmailVerifyRegister: (state) => state.emailExist,
};

const actions = {
  [USER_EMAIL_VERIFICATION]: (_, email) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/user/register/verify/${email}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params: {
            email,
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REGISTER_REQUEST]: ({ commit, dispatch, getters }, data) => {
    return new Promise((resolve, reject) => {
      commit(REGISTER_REQUEST);
      axios
        .post(config.data.apiUrl + '/user/register', data, config.data.headers)
        .then((resp) => {
          localStorage.setItem('user-token', resp.data.token);
          dispatch(USER_REQUEST).then(() => {
            dispatch(NOTIFICATION_NEW_NOTIFICATION, {
              message: 'Bienvenido a maquillate.com',
              title: 'Nuevo usuario',
              user_id: getters.getProfile.id,
            });
          });
          commit(REGISTER_SUCCESS, resp);

          resolve(resp);
        })
        .catch((err) => {
          commit(REGISTER_ERROR, err.response.data.message);
          localStorage.removeItem('user-token');
          reject(err.response.data.message);
        });
    });
  },
  [USER_REGISTER_PARTNER]: ({ commit, dispatch, getters }, user) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/partner/register',
          {
            name: user.name,
            email: user.email,
            password: user.password,
            repeatPassword: user.repeatPassword,
            token: user.token,
            country_id: user.country.id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          localStorage.setItem('user-token', resp.data.token);
          dispatch(USER_REQUEST).then(() => {
            dispatch(NOTIFICATION_NEW_NOTIFICATION, {
              message: 'Bienvenido a maquillate.com',
              title: 'Nuevo usuario',
              user_id: getters.getProfile.id,
            });
          });
          commit(REGISTER_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          commit(REGISTER_ERROR, err);
          localStorage.removeItem('user-token');
          reject(err);
        });
    });
  },
  [USER_INVITE_PARTNER]: ({ dispatch }, user) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/admin/user/invite/partner',
          {
            email: user.email,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(USER_INVITATION_STATUS);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_REMOVE_INVITE_PARTNER]: ({ dispatch }, user) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + '/admin/user/invite/partner/' + user.id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          dispatch(USER_INVITATION_STATUS);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [RESEND_VERIFICATION]: (_, email) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/verification/resend`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params: {
            email,
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // [RESEND_VERIFICATION_BY_ADMIN]: ({ commit, dispatch }, userData) => {
  //     const user = {
  //         email: `user=${userData.email}`
  //     }
  //     return new Promise((resolve, reject) => {
  //         axios
  //             .get(config.data.apiUrl + `/admin/user/verification/resend?${user.email}`, {
  //                 headers: {
  //                     'Content-Type': 'application/json',
  //                     'Accept': 'application/json',
  //                     'Authorization': 'Bearer ' + localStorage.getItem('user-token') //the token is a variable which holds the token
  //                 }
  //             })
  //             .then(resp => {
  //                 resolve(resp)
  //             })
  //             .catch(err => {
  //                 reject(err);
  //             })
  //     });
  // }
};

const mutations = {
  [REGISTER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [REGISTER_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.token = resp.data.token;
    state.hasLoadedOnce = true;
  },
  [REGISTER_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true;
  },
  [USER_EMAIL_VERIFICATION]: (state, resp) => {
    state.emailExist = resp.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
