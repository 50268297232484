const data = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    // eslint-disable-next-line no-undef
    Authorization: 'Bearer ' + localStorage.getItem('user-token'),
  },
  apiUrl: process.env.VUE_APP_BACKEND_URL,
  stripePublicKey: 'pk_test_M3dWXEFLhHoaavx6RAZQqxRs',
  apiUrlLA: process.env.VUE_APP_FRONTEND_URL, //production
};

export default { data };
