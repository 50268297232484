export const ADD_COURSE_TO_CART = 'ADD_COURSE_TO_CART';
export const SET_STATE_CART_WITH_COUPON = 'SET_STATE_CART_WITH_COUPON';
export const DELETE_COURSE_TO_CART_AND_SAVE = 'DELETE_COURSE_TO_CART_AND_SAVE';
export const DELETE_CART = 'DELETE_CART';
export const APPLY_COUPON_TO_CART = 'APPLY_COUPON_TO_CART';
export const REMOVE_APPLY_COUPON_FROM_CART = 'REMOVE_APPLY_COUPON_FROM_CART';

//CHECKOUT
export const PAYMENT_CHECKOUT = 'PAYMENT_CHECKOUT';
export const PAYMENT_CHECKOUT_AUTORIZE = 'PAYMENT_CHECKOUT_AUTORIZE';
export const ISLOADINGPAYMENT = 'ISLOADINGPAYMENT';

export const SET_TOTAL_WITH_COUPON = 'SET_TOTAL_WITH_COUPON';
