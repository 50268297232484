export const USER_REQUEST = 'USER_REQUEST';
export const USER_REQUEST_ERROR = 'USER_REQUEST_ERROR';
export const USER_COURSES = 'USER_COURSES';
export const USER_COURSE_VIDEO_FAVORITE = 'USER_COURSE_VIDEO_FAVORITE';
export const USER_COURSE_VIDEO_PROGRESS = 'USER_COURSE_VIDEO_PROGRESS';

export const FETCH_USER_CLASS_PROGRESS = 'FETCH_USER_CLASS_PROGRESS';
export const FETCH_USER_CLASS_COMPLETED = 'FETCH_USER_CLASS_COMPLETED';
export const USER_COURSE_VIDEO_COMPLETED = 'USER_COURSE_VIDEO_COMPLETED';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const FETCH_USERS = 'FETCH_USERS';
export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';
export const FETCH_USER_INVOICES_BY_USER = 'FETCH_USER_INVOICES_BY_USER';
export const FETCH_ADMIN_USER_INVOICES = 'FETCH_ADMIN_USER_INVOICES';

export const USER_CONTACT = 'USER_CONTACT';
export const USER_DELETE = 'USER_DELETE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_IS_AUTHENTICATE = 'USER_IS_AUTHENTICATE';
export const AUTH_TOKEN = 'AUTH_TOKEN';

export const USER_UPDATE_DIRECT_PASSWORD = 'USER_UPDATE_DIRECT_PASSWORD';
export const USER_UPDATE_AVATAR_URL = 'USER_UPDATE_AVATAR_URL';
export const USER_REGISTER_NEW_PAYMENT_METHOD =
  'USER_REGISTER_NEW_PAYMENT_METHOD';
export const USER_DELETE_PAYMENT_METHOD = 'USER_DELETE_PAYMENT_METHOD';
export const USER_UPDATE_CARD_PAYMENT_METHOD =
  'USER_UPDATE_CARD_PAYMENT_METHOD';
export const USER_UPDATE_DEFAULT_PAYMENT_METHOD =
  'USER_UPDATE_DEFAULT_PAYMENT_METHOD';
export const USER_UPDATE_PAYMENT_METHOD = 'USER_UPDATE_PAYMENT_METHOD';
export const USER_GET_INVOICES = 'USER_GET_INVOICES';
export const USER_DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
export const USER_CHANGE_ACH_DATA = 'USER_CHANGE_ACH_DATA';
export const USER_UPDATE_BANK_ACCOUNTS = 'USER_UPDATE_BANK_ACCOUNTS';
export const USER_UPDATE_DEFAULT_BANK_ACCOUNT =
  'USER_UPDATE_DEFAULT_BANK_ACCOUNT';
export const DELETE_DEFAULT_ACH = 'DELETE_DEFAULT_ACH';
export const SETTINGS_NOTIFICATIONS = 'SETTINGS_NOTIFICATIONS';

export const USER_GET_PARTNERS = 'USER_GET_PARTNERS';
export const USER_INVITATION_STATUS = 'USER_INVITATION_STATUS';
export const DELETE_USER = 'DELETE_USER';

export const USER_REQUEST_SETUP_INTENT = 'USER_REQUEST_SETUP_INTENT';

/*
 * Payment options
 */

export const USER_ATTACH_COURSE = 'USER_ATTACH_COURSE';

export const USER_UPDATE_PAYPAL_ACCOUNTS = 'USER_UPDATE_PAYPAL_ACCOUNTS';

export const NOTIFICATION_USER_GET = 'NOTIFICATION_USER_GET';
export const NOTIFICATION_USER_SET = 'NOTIFICATION_USER_SET';
export const SEND_EMAIL_HELP = 'SEND_EMAIL_HELP';

export const USER_CLASS_FAVORITE = 'USER_CLASS_FAVORITE';

export const FETCH_USER_NOTIFICATION = 'FETCH_USER_NOTIFICATION';
export const DELETE_USER_NOTIFICATION_DASHBOARD =
  'DELETE_USER_NOTIFICATION_DASHBOARD';
