import Vuex from 'vuex';
import Vue from 'vue';
import Login from './modules/login';
import Register from './modules/register';
import User from './modules/user';
import Recovery from './modules/recovery';
import Notifications from './modules/notifications';
import Stripe from './modules/stripe';
import Video from './modules/video';
import Course from './modules/course';
import Instructor from './modules/instructor';
import Coupon from './modules/coupon';
import Paypal from './modules/paypal';
import Partner from './modules/partner';
import Charts from './modules/charts';
import Media from './modules/media';
import Products from './modules/products';
import Categories from './modules/categories';
import Countries from './modules/countries';
import Header from './modules/header';
import Payment from './modules/payment';
import BankCommission from './modules/bankCommission';
import Order from './modules/order';
import NavBar from './modules/navBar';
import search from './modules/search';
import cart from './modules/cart';
import card from './modules/card';
import advertisement from './modules/advertisement';
import checkout from './modules/checkout';
import invoice from './modules/invoice';
import courseVideo from './modules/courseVideo';
import courseVideoFile from './modules/courseVideoFile';
import publicStore from './modules/public';
import metric from './modules/metric';
import classes from './modules/classes';
import seo from './modules/seo';
// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    Login,
    Register,
    Recovery,
    Countries,
    User,
    Notifications,
    Stripe,
    Video,
    Course,
    Instructor,
    Coupon,
    Paypal,
    Partner,
    Charts,
    Media,
    Products,
    Categories,
    Header,
    BankCommission,
    Payment,
    Order,
    NavBar,
    search,
    cart,
    card,
    advertisement,
    checkout,
    invoice,
    courseVideo,
    courseVideoFile,
    publicStore,
    metric,
    classes,
    seo,
  },
});
