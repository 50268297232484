import axios from 'axios';
import config from '@/config/config';

import {
  FETCH_VIDEOS,
  VIDEO_GET_VIDEO,
 UPDATE_VIDEO, DELETE_VIDEO,
} from '../actions/video';

const state = { library: null, videos: [] };

const getters = {
  getListVideos: (state) => state.library,
  getVideos: (state) => state.videos,
};

const actions = {
  [FETCH_VIDEOS]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/video/all', {
          params: {
            ...filter,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(FETCH_VIDEOS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_VIDEO]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post( config.data.apiUrl + '/admin/video/update', data,{
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [DELETE_VIDEO]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete( config.data.apiUrl + `/admin/video/delete/${id}`,{
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //TODO:

  [VIDEO_GET_VIDEO]: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/courses/get/video/' + id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [FETCH_VIDEOS]: (state, videos) => {
    state.videos = videos.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
