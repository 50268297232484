import axios from 'axios';
import config from '@/config/config';

import {
  CREATE_ADVERTISEMENT,
  FETCH_ADVERTISEMENTS,
  UPDATE_ADVERTISEMENT,
  DELETE_ADVERTISEMENT,
} from '../actions/advertisement';

const state = {
  advertisements: {
    total: 0,
    items: [],
  },
};

const getters = {
  getAdvertisements: (state) => state.advertisements,
};

const actions = {
  [CREATE_ADVERTISEMENT]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/advertisement/create', data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_ADVERTISEMENTS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/advertisement/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_ADVERTISEMENTS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_ADVERTISEMENT]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/advertisement/update', data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_ADVERTISEMENT]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + `/admin/advertisement/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [FETCH_ADVERTISEMENTS]: (state, advertisement) => {
    state.advertisements = {
      total: advertisement && advertisement.total ? advertisement.total : 0,
      items: advertisement && advertisement.data ? advertisement.data : [],
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
