var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-nav',{staticClass:"navbar-top navbar-expand pt-4",class:{ 'navbar-dark': _vm.$route.meta.navbarType === 'default' },attrs:{"container-classes":"container-fluid"}},[_c('div',{staticClass:"mobile-navbar"},[_c('h4',{staticClass:"mb-0 text-muted",staticStyle:{"color":"black !important"}},[_vm._v(" "+_vm._s(_vm.title ? _vm.title : this.getTitle())+" ")]),(_vm.isLoading)?_c('div',{staticClass:"my-1"},[_c('skeleton-loading',[_c('skeleton-square',{attrs:{"box-properties":{
            width: '400px',
            height: '16px',
            top: 5,
          },"count":1}})],1)],1):_c('div',{staticClass:"welcome-text my-1"},[(!_vm.getSubTitleOption())?_c('div',[_vm._v(" "+_vm._s(_vm.subtitle ? _vm.subtitle : this.getSubtitle())+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.getSubTitleOption())+" ")])])]),_c('ul',{staticClass:"navbar-nav align-items-center ml-md-auto"},[_c('li',{staticClass:"nav-item d-xl-none"},[_c('div',{staticClass:"pr-3 sidenav-toggler",class:{
          active: this.$sidebar.showSidebar,
          'sidenav-toggler-dark': _vm.$route.meta.navbarType === 'default',
          'sidenav-toggler-light': _vm.$route.meta.navbarType === 'light',
        },on:{"click":_vm.toggleSidebar}},[_c('div',{staticClass:"sidenav-toggler-inner"},[_c('i',{staticClass:"sidenav-toggler-line"}),_c('i',{staticClass:"sidenav-toggler-line"}),_c('i',{staticClass:"sidenav-toggler-line"})])])]),_c('li',{staticClass:"nav-item d-sm-none"},[_c('a',{staticClass:"nav-link",attrs:{"data-action":"search-show","data-target":"#navbar-search-main","href":"#"}})])]),_c('ul',{staticClass:"navbar-nav align-items-center ml-auto ml-md-0"},[_c('base-dropdown',{staticClass:"nav-item",attrs:{"menu-on-right":"","tag":"li","title-classes":"nav-link pr-0","title-tag":"a"},scopedSlots:_vm._u([{key:"title-container",fn:function(){return [_c('a',{staticClass:"nav-link pr-0",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"media align-items-center"},[_c('span',{staticClass:"avatar avatar-sm rounded-circle"},[_c('img',{attrs:{"src":_vm.getProfile.avatar.url,"alt":"Image placeholder"}})]),_c('div',{staticClass:"media-body ml-2 d-none d-lg-block mb-2"},[_c('span',{staticClass:"mb-0 text-sm font-weight-bold text-active name-admin"},[_vm._v(_vm._s(_vm.getProfile.name))])])])])]},proxy:true}])},[_c('div',{staticClass:"dropdown-item"},[_c('router-link',{staticClass:"link",attrs:{"to":"/admin/configuracion/cuenta"}},[_c('span',[_vm._v("Configuración")])])],1),_c('div',{staticClass:"dropdown-item",on:{"click":_vm.logout}},[_c('span',[_vm._v("Cerrar sesión")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }