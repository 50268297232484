import Vue from 'vue';
import store from '@/store/index';
import { getAccessToken } from '../store/utils/user';
import { USER_CLASS_FAVORITE, USER_REQUEST } from '../store/actions/user';

const authenticationInstance = () => {
  return new Vue({
    data() {
      return {
        loading: true,
        error: undefined,
        user: undefined,
      };
    },
    async created() {
      try {
        this.user = await this.fetchCurrentUser();
        if (this.user) await this.fetchClasses();
        this.error = undefined;
      } catch (e) {
        console.log(e);
        this.error = e.message;
        this.user = undefined;
      } finally {
        this.loading = false;
      }
    },
    methods: {
      async fetchCurrentUser() {
        const accessToken = getAccessToken();
        if (accessToken) {
          return await store.dispatch(USER_REQUEST);
        }
        return undefined;
      },
      async fetchClasses() {
        await store.dispatch(USER_CLASS_FAVORITE, {
          is_favorite: 1,
          page: this.page,
          per_page: 100,
        });
      },
      changeLoading(value = false) {
        this.loading = value;
      },
    },
  });
};

export const AuthenticationPlugin = {
  install(Vue) {
    Vue.prototype.$authentication = authenticationInstance();
  },
};
