/**
 * Add course in cart.
 *
 * @param {{cart:{id:number}[]}}state - Cart state.
 * @param {{id:number}}course - Course selected for add in cart.
 */
export const addCourseToCart = (state, course) => {
  const isExists = state.cart.find((item) => item.id === course.id);
  if (!isExists) {
    state.cart.push(course);
    localStorage.setItem('cart', JSON.stringify(state.cart));
  }
};

/**
 * Remove course from cart.
 *
 * @param {{cart:{id:number}[]}}state - Cart state.
 * @param {number}courseId - Course id.
 */
export const removeCourseToCart = (state, courseId) => {
  const newCart = state.cart.filter((item) => item.id !== courseId);
  state.cart = newCart;
  state.total = newCart.reduce((total, cartItem) => total + cartItem.price, 0);
  localStorage.setItem('cart', JSON.stringify(newCart));
};

/**
 * Reset cart.
 *
 * @param {{cart:{id:number}[]}}state - Cart state.
 */
export const deleteCart = (state) => {
  state.cart = [];
  localStorage.removeItem('cart');
};

export const applyCouponToCart = (state, coupon) => {
  const cart = state.cart;
  cart.forEach((cartItem) => {
    if (coupon.courses && coupon.courses.includes(cartItem.id)) {
      cartItem.discount = coupon.discount;
      cartItem.withDiscount =
        cartItem.price - (coupon.discount * cartItem.price) / 100;
    }
  });

  state.cart = cart;
  state.coupon = coupon;
  const discount = cart.reduce((acc, curr) => {
    return curr.discount ? acc + curr.price * (curr.discount / 100) : acc;
  }, 0);

  state.discount = parseFloat(discount.toString()).toFixed(2);
  state.totalWithDiscount = parseFloat(
    (state.total - discount).toString(),
  ).toFixed(2);
};

export const removeApplyCouponFromCart = (state) => {
  const cart = state.cart;
  cart.forEach((cartItem) => {
    cartItem.discount = 0;
    cartItem.withDiscount = 0;
  });

  state.cart = cart;
  state.coupon = undefined;
  state.discount = 0;
  state.totalWithDiscount = 0;
};
