import config from '@/config/config';
import axios from 'axios';
import { DELETE_COURSE_VIDEO_FILE, FETCH_COURSE_VIDEO_FILES } from '../actions/courseVideoFile';

const state = {
  courseVideosFiles: [],
};

const getters = {
  getCourseVideoFiles: (state) => state.courseVideosFiles,
};

const actions = {

  [DELETE_COURSE_VIDEO_FILE]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios.delete(
        `${config.data.apiUrl}/admin/course/video/file/delete/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        },
      ).then((resp) => {
        resolve(resp.data);
      }).catch((err) => {
        reject(err.message);
      });
    });
  },
  [FETCH_COURSE_VIDEO_FILES]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios.get(
        `${config.data.apiUrl}/admin/course/video/file/all`,
        {
          params: filter,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        },
      ).then((resp) => {
        commit(FETCH_COURSE_VIDEO_FILES, resp.data);
        resolve(resp.data)
      }).catch((err) => {
        reject(err);
      });
    });
  },
};

const mutations = {
  [FETCH_COURSE_VIDEO_FILES]: (state, files) => {
    state.courseVideosFiles = files.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
