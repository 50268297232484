export const ORDERS_GET_ALL = 'ORDERS_GET_ALL';
export const ORDER_GET_BY_ORDER_ID = 'ORDER_GET_BY_ORDER_ID';
export const INVOICE_GET_BY_INVOICEID = 'INVOICE_GET_BY_INVOICEID';
export const ORDERS_GET_ALL_BY_USER = 'ORDERS_GET_ALL_BY_USER';
export const INVOICE_GET_PDF_BY_CODE = 'INVOICE_GET_PDF_BY_CODE';

//ORDERS DETAILS
export const ORDERS_DETAILS_GET_ALL = 'ORDERS_DETAILS_GET_ALL';
export const ORDERS_DETAILS_GET_THIS = 'ORDERS_DETAILS_GET_THIS';

//admin
export const INVOICES_ALL_ADMIN = 'INVOICES_ALL_ADMIN';
export const INVOICE_GET_BY_CODE = 'INVOICE_GET_BY_CODE';
export const INVOICE_GET_DETAIL_BY_INVOICEID =
  'INVOICE_GET_DETAIL_BY_INVOICEID';
