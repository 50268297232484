import axios from 'axios';
import config from '@/config/config';
import {
  STRIPE_SETUP_INTENT,
  STRIPE_SETUP_SUCCESS,
  STRIPE_NEW_CHARGE,
  STRIPE_GENERATE_PLAID_TOKEN,
  STRIPE_PLAID_NEW_ACCOUNT,
  STRIPE_PLAID_GET_AUTH,
  STRIPE_PLAID_NEW_CHARGE,
  STRIPE_PLAID_REGISTER_BANK_ACCOUNT,
  STRIPE_PLIAD_DELETE_BANK_ACCOUNT,
} from '../actions/stripe';

import { USER_UPDATE_BANK_ACCOUNTS, DELETE_DEFAULT_ACH } from '../actions/user';

const state = {
  tokenSetup: '',
  status: '',
};

const getters = {
  setupToken: (state) => state.tokenSetup,
};

const actions = {
  [STRIPE_SETUP_INTENT]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      user.stripe
        .confirmCardSetup(user.stripeClientSecret, {
          payment_method: {
            card: user.cardElement,
            billing_details: {
              address: {
                postal_code: user.address_zip,
              },
              name: user.name,
            },
          },
        })
        .then(function (result) {
          commit(STRIPE_SETUP_SUCCESS);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STRIPE_NEW_CHARGE]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/new/charge',
          {
            course_id: data.course_id,
            coupon_id: data.coupon_id,
            payment_id: data.payment_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STRIPE_GENERATE_PLAID_TOKEN]: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/plaid/generate-token', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STRIPE_PLAID_NEW_ACCOUNT]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/add/new/bank-account',
          {
            stripe_id: data.stripe_id,
            source: data.source,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STRIPE_PLAID_GET_AUTH]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/plaid/get/auth',
          {
            public_token: data.public_token,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STRIPE_PLAID_REGISTER_BANK_ACCOUNT]: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/plaid/register/bank-account',
          {
            public_token: data.public_token,
            account_id: data.account_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(USER_UPDATE_BANK_ACCOUNTS);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STRIPE_PLAID_NEW_CHARGE]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/plaid/new/charge',
          {
            amount: data.amount,
            source: data.source,
            description: data.description,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STRIPE_PLIAD_DELETE_BANK_ACCOUNT]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/plaid/delete/account',
          {
            source: data.source,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(USER_UPDATE_BANK_ACCOUNTS);

          commit(DELETE_DEFAULT_ACH, data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [STRIPE_SETUP_SUCCESS]: (state) => {
    state.status = 'success';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
