import axios from 'axios';
import {
  RECOVERY_REQUEST,
  RECOVERY_CHANGE_REQUEST,
  RECOVERY_SUCCESS,
  RECOVERY_ERROR,
} from '../actions/recovery';
import config from '@/config/config';

const state = {
  status: '',
  email: '',
  hasLoadedOnce: false,
};

const getters = {
  recoveryStatus: (state) => state.status,
  recoveryEmail: (state) => state.email,
};

const actions = {
  [RECOVERY_REQUEST]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit(RECOVERY_REQUEST, user.email);
      axios
        .post(
          config.data.apiUrl + '/recovery-password/email',
          {
            email: user.email,
          },
          config.data.headers,
        )
        .then((resp) => {
          commit(RECOVERY_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          commit(RECOVERY_ERROR, err);
          reject(err);
        });
    });
  },

  [RECOVERY_CHANGE_REQUEST]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit(RECOVERY_REQUEST);
      axios
        .post(
          config.data.apiUrl + '/recovery-password/reset',
          {
            email: user.email,
            token: user.token,
            password: user.password,
            password_confirmation: user.password_confirmation,
          },
          config.data.headers,
        )
        .then((resp) => {
          commit(RECOVERY_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          commit(RECOVERY_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  [RECOVERY_REQUEST]: (state, email) => {
    state.status = 'loading';
    state.email = email;
  },
  [RECOVERY_SUCCESS]: (state) => {
    state.status = 'success';
    state.hasLoadedOnce = true;
  },
  [RECOVERY_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
