import axios from 'axios';
import config from '@/config/config';

import {
  GET_PAYMENTS_PENDING,
  GET_PAYMENTS_COMPLETE,
  GET_ORDERS,
  GET_PAYMENTS_BY_PARTNER,
} from '../actions/payment';

const state = {
  pending: [],
  complete: [],
  orders: [],
  paymentsByPartner: [],
};

const getters = {
  getPaymentPending: (state) => state.pending,
  getPaymentComplete: (state) => state.complete,
  getOrders: (state) => state.orders,
  getPaymentsByPartner: (state) => state.paymentsByPartner,
};

const actions = {
  [GET_PAYMENTS_PENDING]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/order/pending', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then(() => {
          axios
            .get(config.data.apiUrl + '/admin/payment/pending', {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
              },
            })
            .then((resp) => {
              //console.log(resp)
              commit(GET_PAYMENTS_PENDING, resp.data);
              resolve(resp.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
    });
  },
  [GET_PAYMENTS_COMPLETE]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/payment/complete', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          //console.log(resp)
          commit(GET_PAYMENTS_COMPLETE, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_PAYMENTS_BY_PARTNER]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/partner/payment/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          console.log(resp);
          commit(GET_PAYMENTS_BY_PARTNER, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_ORDERS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/order/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          //console.log(resp)
          commit(GET_ORDERS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [GET_PAYMENTS_PENDING]: (state, resp) => {
    state.pending = resp;
  },
  [GET_PAYMENTS_COMPLETE]: (state, resp) => {
    state.complete = resp;
  },
  [GET_ORDERS]: (state, resp) => {
    state.orders = resp;
  },
  [GET_PAYMENTS_BY_PARTNER]: (state, resp) => {
    state.paymentsByPartner = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
