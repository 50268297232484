import Vue from 'vue';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import VueClipboard from 'vue-clipboard2';
import DashboardPlugin from './plugins/dashboard-plugin';
import Notifications from '@/components/NotificationPlugin';
import App from './App.vue';
import store from './store';
import vueCountryRegionSelect from 'vue-country-region-select';
import VueCardFormat from 'vue-credit-card-validation';
import VueCurrencyInput from 'vue-currency-input';
import ToggleButton from 'vue-js-toggle-button';
import VueEllipseProgress from 'vue-ellipse-progress';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
// Vue material
import VueMaterial from 'vue-material';
import VTooltip from 'v-tooltip';
import vSelect from 'vue-select';
import router from './routes/router';
import { PaypalPlugin } from './plugins/paypalPlugin';
import { AuthenticationPlugin } from './plugins/authenticationPlugin';
import { GlobalSettings } from './plugins/globalSettings';
import VueMeta from'vue-meta';
import '@/assets/css/main.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'USD' },
};

// Vue.use(Skeleton);

// Register the component globally
Vue.use(Notifications);

Vue.use(VueCurrencyInput, pluginOptions);

Vue.use(VueCardFormat);

Vue.use(VueMaterial);

Vue.component('VSelect', vSelect);

Vue.use(VTooltip);

Vue.use(ToggleButton);
Vue.use(VueEllipseProgress);

Vue.use(VueMeta)
Vue.use(VueClipboard);
Vue.use(AuthenticationPlugin);
Vue.use(PaypalPlugin);
Vue.use(DashboardPlugin);
Vue.use(vueCountryRegionSelect);
Vue.use(GlobalSettings);
VueClipboard.config.autoSetContainer = true; // add this line

Bugsnag.start({
  apiKey: '9c322a2380dcbe7ec1f8cec1ab934a44',
  plugins: [new BugsnagPluginVue()],
  autoTrackSessions:false
});

Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
