import axios from 'axios';
import { AUTH_REQUEST, AUTH_LOGOUT, AUTH_REQUEST_REFRESH } from '../actions/login';

import { AUTH_TOKEN } from '../actions/user';

import config from '@/config/config';

const state = {};

const getters = {};

const actions = {
  [AUTH_REQUEST]: ({ dispatch }, user) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/login',
          {
            email: user.email,
            password: user.password,
          },
          config.data.headers,
        )
        .then((resp) => {
          localStorage.setItem('user-token', resp.data.token);
          dispatch(AUTH_TOKEN, resp.data.token);
          resolve(resp);
        })
        .catch((err) => {
          // localStorage.removeItem('user-token');
          reject(err);
        });
    });
  },
  [AUTH_REQUEST_REFRESH]: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/oauth/refresh',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          localStorage.setItem('user-token', resp.data.token);
          dispatch(AUTH_TOKEN, resp.data.token);
          resolve(resp);
        })
        .catch((err) => {
          localStorage.removeItem('user-token');
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: () => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          config.data.apiUrl + `/logout`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then(() => {
          localStorage.removeItem('user-token');
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
