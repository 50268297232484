<template>
  <div style="height: 100%;">
    <notifications />
    <loading-panel v-if="$authentication.loading"></loading-panel>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import LoadingPanel from './components/LoadingPanel'
import Notifications from './components/NotificationPlugin/Notifications'
import { mapGetters } from 'vuex'

export default {
  components: {
    LoadingPanel,
    Notifications,
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated']),
  },
  watch: {
    '$authentication.error': function (error) {
      console.log({ error })
      // const token = localStorage.getItem('user-token') ?? '';
      // if (error && token) {
      //   localStorage.removeItem('user-token');
      //   const vm = this;
      //   setTimeout(function () {
      //     vm.$router.go(0);
      //   }, 1000);
      // }
    },
  },
}
</script>
