import axios from 'axios';
import config from '@/config/config';

import {
  COURSE_UPDATE_COURSE,
  COURSE_REPLICATE_COURSE,
  COURSE_UPDATE_CHAPTER,
  COURSE_DELETE_CHAPTER,
  COURSE_GET_COURSE,
  COURSE_GET_COURSEE,
  SUCCESS_ATTACH_NEW_LESSON,
  COURSE_UPDATE_PARTNER_DATA,
  COURSE_USER_CHECK,
  CHECK_COURSE_USER,
  GET_COURSES_BY_CATEGORY,
  GET_CATEGORY,
  GET_COURSES_CLASS_CATEGORIES_ID,
  //
  FETCH_COURSE,
  UPDATE_COURSE,
  FETCH_COURSES,
  UPDATE_COURSE_THUMBNAIL,
  CREATE_COURSE,
  CHANGE_COURSE_FEATURE,
  CHANGE_COURSE_FAVORITE,
  DELETE_COURSE,
  CREATE_COURSE_USER,
  FETCH_COURSE_USER,
  FETCH_COURSE_VIDEO_USER,
  CHANGE_COURSE_VIDEO_USER,
  FETCH_COURSE_BULLETS,
  CREATE_COURSE_BULLET,
  UPDATE_COURSE_BULLET,
  DELETE_COURSE_BULLET,
  UPDATE_COURSE_POSTER, UPDATE_TRAILER_COURSE_POSTER, UPDATE_COURSE_VIDEO_POSTER,
} from '../actions/course';
import { DEFAULT_COURSE_VALUES } from '../../util/constants';

const state = {
  allClassNews: null,
  coursesFeatured: [],
  coursesClassPopular: [],
  allCoursesClassPopular: null,
  allClass: null,
  coursesClassTrend: [],
  allCoursesClassTrends: null,
  coursesByCategory: null,
  courses: {
    total: 0,
    items: [],
  },
  tempBox: false,

  currentCourse: undefined,
  currentCourseUser: {
    id: undefined,
    course_videos: [],
  },
  currentCourseVideoUser: {
    title: '',
    content: '',
    files: [],
    products: [],
    courseVideoUser: {
      isCompleted: 0,
      currentTime: 0,
    },
  },
  currentCourseVideos: [],
  currentCourseBullets: [],

  currentCoursee: null,
  ContinueCourse: null,
  courseInformation: null,
  coursesFeaturedCategories: null,
  coursesClassPopularCategories: null,
  coursesClassTrendCategories: null,
  category: null,
  //
  categories_id: null,
};

const getters = {
  getCurrentCourse: (state) => state.currentCourse,
  getCurrentCourseVideos: (state) => state.currentCourseVideos,
  getCurrentCourseUser: (state) => state.currentCourseUser,
  getCurrentCourseVideoUser: (state) => state.currentCourseVideoUser,
  getAllCourses: (state) => state.courses,
  currentCourse: (state) => state.currentCourse,
  getCurrentCourseBullets: (state) => state.currentCourseBullets,
  getAllCoursesFeatured: (state) => state.coursesFeatured,
  getAllCoursesClassPopular: (state) => state.coursesClassPopular,
  getAllCoursesClassTrend: (state) => state.coursesClassTrend,
  getAllCoursesFeaturedCategories: (state) => state.coursesFeaturedCategories,
  getAllCoursesClassPopularCategories: (state) =>
    state.coursesClassPopularCategories,
  getAllCoursesClassTrendCategories: (state) =>
    state.coursesClassTrendCategories,
  getCategories_Id: (state) => state.categories_id,
  getAllCoursesByCategory: (state) => state.coursesByCategory,
  getAllCoursesClassPopulars: (state) => state.allCoursesClassPopular,
  getAllClass: (state) => state.allClass,
  getAllCoursesClassTrends: (state) => state.allCoursesClassTrends,
  getCourseInformation: (state) => state.courseInformation,
  getCategory: (state) => state.category,
};

const actions = {
  [FETCH_COURSE]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/course/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(FETCH_COURSE, resp.data);
          resolve(resp.data);
        })
        .then((err) => {
          reject(err);
        });
    });
  },

  [UPDATE_COURSE]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${config.data.apiUrl}/admin/course/update`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .then((err) => {
          reject(err);
        });
    });
  },

  [FETCH_COURSES]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/course/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params: {
            ...filter,
          },
        })
        .then((resp) => {
          commit(FETCH_COURSES, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_COURSE_THUMBNAIL]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/course/update/thumbnail', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_COURSE_POSTER]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/course/update/poster', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_TRAILER_COURSE_POSTER]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/course/update/trailer-poster', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_COURSE_VIDEO_POSTER]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/course/update/course-video-poster', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_COURSE]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/course/create`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .then((err) => {
          console.log('err', err);
          reject(err);
        });
    });
  },

  [CHANGE_COURSE_FEATURE]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${config.data.apiUrl}/admin/course/update/featured`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .then((err) => {
          reject(err);
        });
    });
  },
  [DELETE_COURSE]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.data.apiUrl}/admin/course/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },

  [CREATE_COURSE_USER]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/user/course/create`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_COURSE_USER]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/course', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(FETCH_COURSE_USER, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_COURSE_VIDEO_USER]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/course/video', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(FETCH_COURSE_VIDEO_USER, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHANGE_COURSE_VIDEO_USER]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/user/course/video/update`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .then((err) => {
          reject(err);
        });
    });
  },

  [CHANGE_COURSE_FAVORITE]: (_, data) => {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .put(`${config.data.apiUrl}/user/course/video/update/favorite`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .then((err) => {
          reject(err);
        });
    });
  },

  [FETCH_COURSE_BULLETS]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/course/bullet/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(FETCH_COURSE_BULLETS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_COURSE_BULLET]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/course/bullet/create`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_COURSE_BULLET]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/course/bullet/update`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [DELETE_COURSE_BULLET]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.data.apiUrl}/admin/course/bullet/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },

  //TODO: CHECK ALL ACTIONS

  [COURSE_REPLICATE_COURSE]: (_, course) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/admin/courses/replicate/' + course.id,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [COURSE_GET_COURSE]: ({ commit }, course) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/courses/' + course.id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(COURSE_GET_COURSEE, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [COURSE_USER_CHECK]: ({ commit }, dataObject) => {
    console.log(dataObject);
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/check/course',
          {
            dataObject: dataObject,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          commit(COURSE_GET_COURSE, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHECK_COURSE_USER]: (dataObject) => {
    //console.log("hollaaaaaaaaa");
    //console.log(dataObject);
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/course/check',
          {
            dataObject: dataObject,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          //commit(COURSE_GET_COURSE, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [COURSE_UPDATE_COURSE]: ({ commit }, course) => {
    console.log(course, 'course  envio de dato :vvvv');
    return new Promise((resolve, reject) => {
      axios
        .patch(
          config.data.apiUrl + '/admin/courses/update/' + course.id,
          {
            name: course.name,
            price: course.price,
            course_url: course.url,
            instructors: course.instructors,
            categories: course.categories,
            type: course.type,
            status: course.status,
            content: course.content,
            mincontent: course.mincontent,
            slug: course.slug,
            free: course.free,
            video_id: course.video_id,
            duration_trailer: course.duration_trailer,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          commit(COURSE_UPDATE_COURSE, resp.data.course);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [COURSE_UPDATE_CHAPTER]: (chapter) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          config.data.apiUrl + '/admin/courses/chapter/update/' + chapter.id,
          {
            title: chapter.name,
            description: chapter.description,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [COURSE_UPDATE_PARTNER_DATA]: (course) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          config.data.apiUrl + '/admin/courses/update/partner',
          {
            course_id: course.course_id,
            partner_id: course.partner_id,
            partner_percent: course.partner_percent,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [COURSE_DELETE_CHAPTER]: (_, chapter) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          config.data.apiUrl + '/admin/courses/chapter/delete/' + chapter.id,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [GET_COURSES_BY_CATEGORY]: ({ commit }, category_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/courses/all/' + category_id)
        .then((resp) => {
          commit(GET_COURSES_BY_CATEGORY, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_CATEGORY]: ({ commit }, category_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/category/' + category_id)
        .then((resp) => {
          //console.log(resp.data,"Category_Get");
          commit(GET_CATEGORY, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [FETCH_COURSE]: (state, course) => {
    state.currentCourseVideos =
      course && course.course_videos && course.course_videos.length
        ? course.course_videos.map((courseVideo) => ({
            ...courseVideo,
            seo: {
              title:
                courseVideo.seo && courseVideo.seo.title
                  ? courseVideo.seo.title
                  : '',
              description:
                courseVideo.seo && courseVideo.seo.description
                  ? courseVideo.seo.description
                  : '',
              id:
                courseVideo.seo && courseVideo.seo.id ? courseVideo.seo.id : '',
              keyPhrases:
                courseVideo.seo && courseVideo.seo.key_phrase
                  ? JSON.parse(courseVideo.seo.key_phrase)
                  : [],
            },
          }))
        : [];

    state.currentCourse = course
      ? {
          id: course.id,
          name: course.name,
          thumbnailUrl: course.thumbnail_url,
          content: course.content,
          minContent: course.mincontent,
          status: course.status,
          slugName: course.slugName,
          featured: course.featured,
          seo: {
            title: course.seo && course.seo.title ? course.seo.title : '',
            description:
              course.seo && course.seo.description
                ? course.seo.description
                : '',
            id: course.seo && course.seo.id ? course.seo.id : '',
            keyPhrases:
              course.seo && course.seo.key_phrase
                ? JSON.parse(course.seo.key_phrase)
                : [],
          },
          bullets: course.bullets,
          instructors: (course.instructors ?? []).map(
            (item) => item.instructor,
          ),
          trailer:
            course.trailer && course.trailer.video
              ? course.trailer.video
              : undefined,
          free: course.free.toString(),
          price: course.price,
          categories: (course.course_categories ?? []).map(
            (item) => item.course_category,
          ),
          poster: course.poster,
          trailer_poster: course.trailer_poster,
        }
      : DEFAULT_COURSE_VALUES;
  },
  [FETCH_COURSES]: (state, courses) => {
    state.courses = {
      total: courses && courses.total ? courses.total : 0,
      items: courses && courses.data ? courses.data : [],
    };
  },
  [FETCH_COURSE_USER]: (state, courseUser) => {
    state.currentCourseUser = courseUser.course ? courseUser.course : undefined;
  },
  [FETCH_COURSE_VIDEO_USER]: (state, courseVideoUser) => {
    console.log('test', state);
    state.currentCourseVideoUser = {
      id: courseVideoUser.id,
      title: courseVideoUser.title,
      content: courseVideoUser.content,
      products: courseVideoUser.products,
      files: courseVideoUser.files,
      courseVideoUser: courseVideoUser.course_video_user,
      video: courseVideoUser.video,
      seo: courseVideoUser.seo,
    };
  },

  [GET_COURSES_BY_CATEGORY]: (state, resp) => {
    state.coursesByCategory = resp.data;
  },
  [GET_CATEGORY]: (state, resp) => {
    state.category = resp.data[0];
  },

  [SUCCESS_ATTACH_NEW_LESSON]: (state) => {
    state.tempBox = false;
  },
  [COURSE_GET_COURSE]: (state, resp) => {
    state.currentCourse = resp.data;
  },
  [COURSE_GET_COURSEE]: (state, resp) => {
    state.currentCoursee = resp.data;
  },
  [COURSE_UPDATE_COURSE]: (state, resp) => {
    state.currentCoursee = resp;
  },
  [FETCH_COURSE_BULLETS]: (state, resp) => {
    state.currentCourseBullets = resp.data;
  },
  [GET_COURSES_CLASS_CATEGORIES_ID]: (state, resp) => {
    state.categories_id = resp.id;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
