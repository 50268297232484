export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const USER_EMAIL_VERIFICATION = 'USER_EMAIL_VERIFICATION';

export const USER_REGISTER_PARTNER = 'USER_REGISTER_PARTNER';
export const USER_INVITE_PARTNER = 'USER_INVITE_PARTNER';
export const USER_REMOVE_INVITE_PARTNER = 'USER_REMOVE_INVITE_PARTNER';

export const RESEND_VERIFICATION = 'RESEND_VERIFICATION';
export const RESEND_VERIFICATION_BY_ADMIN = 'RESEND_VERIFICATION_BY_ADMIN';
