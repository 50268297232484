import axios from 'axios';
import config from '@/config/config';
import {
  FETCH_CURRENT_CLASS,
  FETCH_POPULAR_CLASSES,
  FETCH_PUBLIC_ADVERTISEMENT_LIST,
  FETCH_PUBLIC_CATEGORIES,
  FETCH_PUBLIC_CLASSES,
  FETCH_PUBLIC_COURSE,
  FETCH_PUBLIC_COURSES,
  FETCH_PUBLIC_FEATURED_CLASSES,
  FETCH_PUBLIC_FEATURED_COURSE_VIDEOS,
  FETCH_PUBLIC_INSTRUCTOR,
  FETCH_PUBLIC_INSTRUCTORS,
  FETCH_PUBLIC_INSTRUCTORS_LIST,
  FETCH_PUBLIC_INSTRUCTORS_FEATURED,
  FETCH_TREND_CLASSES,
  FETCH_HOME_CLASSES,
} from '../actions/public';

const state = {
  categories: [],
  instructors: [],
  InstructorOrderByFeatured: [],
  instructorsList: {
    items: [],
    total: 0,
  },
  courses: {
    items: [],
    total: 0,
  },
  courseVideos: [],
  instructor: undefined,
  isLoadingCategories: true,
  currentCourse: undefined,
  currentCourseVideos: [],
  featuredCourseVideos: [],
  advertisements: [],
  classes: {
    total: 0,
    items: [],
  },
  homeClasses: {
    total: 0,
    items: [],
  },
  featuredClasses: {
    total: 0,
    items: [],
  },
  trendClasses: {
    total: 0,
    items: [],
  },
  currentClass: undefined,
  popularClasses: { items: [], count: 0 },
  search: {
    items: [],
    count: 0,
  },
};

const getters = {
  getPublicAdvertisements: (state) => state.advertisements,
  getPublicCategories: (state) => state.categories,
  getPublicInstructors: (state) => state.instructors,
  getPublicInstructorsList: (state) => state.instructorsList,
  getInstructorOrderByFeatured: (state) => state.InstructorOrderByFeatured,
  getPublicCourses: (state) => state.courses,
  getPublicCourseVideos: (state) => state.courseVideos,
  getPublicCurrentInstructor: (state) => state.instructor,
  getIsLoadingCategories: (state) => state.isLoadingCategories,
  getPublicCurrentCourse: (state) => state.currentCourse,
  getPublicCurrentCourseVideos: (state) => state.currentCourseVideos,
  getPublicClasses: (state) => state.classes,
  getPublicFeaturedClasses: (state) => state.featuredClasses,
  getPublicTrendClasses: (state) => state.trendClasses,
  getPublicCurrentClass: (state) => state.currentClass,
  getPopularClasses: (state) => state.popularClasses,
  getHomeClasses: (state) => state.homeClasses,
};

const actions = {
  [FETCH_PUBLIC_CATEGORIES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/categories`, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_CATEGORIES, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_PUBLIC_CLASSES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/classes/all`, {
          headers: {
            Accept: 'application/json',
          },
          params,
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_CLASSES, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_HOME_CLASSES]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/classes/all`, {
          headers: {
            Accept: 'application/json',
          },
          params: {
            ...filter,
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_HOME_CLASSES, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_POPULAR_CLASSES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/classes/popular`, {
          headers: {
            Accept: 'application/json',
          },
          params,
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_POPULAR_CLASSES, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_TREND_CLASSES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/classes/trend`, {
          headers: {
            Accept: 'application/json',
          },
          params,
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_TREND_CLASSES, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_CURRENT_CLASS]: ({ commit }, slug) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/classes/${slug}`, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_CURRENT_CLASS, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PUBLIC_FEATURED_CLASSES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/classes/all`, {
          headers: {
            Accept: 'application/json',
          },
          params,
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_FEATURED_CLASSES, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PUBLIC_INSTRUCTORS]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/instructor/all`, {
          headers: {
            Accept: 'application/json',
          },
          params: {
            ...filter,
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_INSTRUCTORS, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PUBLIC_COURSES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/course/all`, {
          headers: {
            Accept: 'application/json',
          },
          params,
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_COURSES, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_PUBLIC_COURSE]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/course/${id}`, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_COURSE, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PUBLIC_INSTRUCTORS_LIST]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/instructor/all`, {
          headers: {
            Accept: 'application/json',
          },
          params: {
            ...filter,
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_INSTRUCTORS_LIST, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PUBLIC_INSTRUCTORS_FEATURED]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/instructor/all`, {
          headers: {
            Accept: 'application/json',
          },
          params: {
            ...filter,
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_INSTRUCTORS_FEATURED, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PUBLIC_INSTRUCTOR]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/instructor/${id}`, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_PUBLIC_INSTRUCTOR, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PUBLIC_FEATURED_COURSE_VIDEOS]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/course/video/all`, {
          headers: {
            Accept: 'application/json',
          },
          params: {
            order_by: 'DESC',
            course_video_is_featured: 1,
            ...filter,
          },
        })
        .then((resp) => {
          resolve(resp.data);
          commit(FETCH_PUBLIC_FEATURED_COURSE_VIDEOS, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_PUBLIC_ADVERTISEMENT_LIST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/public/advertisement/all`, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((resp) => {
          resolve(resp.data);
          commit(FETCH_PUBLIC_ADVERTISEMENT_LIST, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [FETCH_PUBLIC_CATEGORIES]: (state, publicCategories) => {
    state.isLoadingCategories = false;
    state.categories = publicCategories.data;
  },
  [FETCH_PUBLIC_INSTRUCTORS]: (state, publicInstructors) => {
    state.instructors = publicInstructors.data;
  },
  [FETCH_PUBLIC_INSTRUCTORS_FEATURED]: (state, publicInstructors) => {
    state.InstructorOrderByFeatured = publicInstructors.data;
  },
  [FETCH_PUBLIC_INSTRUCTORS_LIST]: (state, publicInstructors) => {
    state.instructorsList = {
      items:
        publicInstructors && publicInstructors.data
          ? publicInstructors.data
          : [],
      total:
        publicInstructors && publicInstructors.total
          ? publicInstructors.total
          : 0,
    };
  },
  [FETCH_PUBLIC_ADVERTISEMENT_LIST]: (state, publicAdvertisements) => {
    state.advertisements = publicAdvertisements.data;
  },
  [FETCH_PUBLIC_COURSES]: (state, courses) => {
    state.courses = {
      items:
        courses && courses.data
          ? courses.data.map((d) => ({ ...d, type: 'course' }))
          : [],
      total: courses && courses.total ? courses.total : 0,
    };
  },
  [FETCH_PUBLIC_COURSE]: (state, publicCourse) => {
    state.currentCourse = publicCourse;
    state.currentCourseVideos =
      publicCourse &&
      publicCourse.course_videos &&
      publicCourse.course_videos.length
        ? publicCourse.course_videos
        : [];
  },
  [FETCH_PUBLIC_INSTRUCTOR]: (state, publicInstructor) => {
    state.instructor = publicInstructor;
  },

  [FETCH_PUBLIC_FEATURED_COURSE_VIDEOS]: (state, courseVideos) => {
    state.featuredCourseVideos = courseVideos.data;
  },
  [FETCH_PUBLIC_CLASSES]: (state, classes) => {
    state.classes = {
      total: classes && classes.total ? classes.total : 0,
      items:
        classes && classes.data
          ? classes.data.map((d) => ({ ...d, type: 'class' }))
          : [],
    };
  },
  [FETCH_HOME_CLASSES]: (state, classes) => {
    state.homeClasses = {
      total: classes && classes.total ? classes.total : 0,
      items:
        classes && classes.data
          ? classes.data.map((d) => ({ ...d, type: 'class' }))
          : [],
    };
  },
  [FETCH_PUBLIC_FEATURED_CLASSES]: (state, classes) => {
    state.featuredClasses = {
      total: classes && classes.total ? classes.total : 0,
      items: classes && classes.data ? classes.data : [],
    };
  },
  [FETCH_CURRENT_CLASS]: (state, _class) => {
    console.log('_class', _class);
    state.currentClass = _class;
  },
  [FETCH_TREND_CLASSES]: (state, classes) => {
    state.trendClasses = {
      items:
        classes && classes.data
          ? classes.data.map(({ class_video }) => class_video)
          : [],
      total: classes && classes.total ? classes.total : 0,
    };
  },
  [FETCH_POPULAR_CLASSES]: (state, popularClasses) => {
    state.popularClasses = {
      items:
        popularClasses && popularClasses.data
          ? popularClasses.data.map(({ class_video }) => class_video)
          : [],
      count: popularClasses && popularClasses.count ? popularClasses.count : 0,
    };
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
