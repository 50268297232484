<template>
  <div class="header" :class="{ [`bg-${type}`]: type }">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <h2 class="business pb-3">UN RESUMEN DE TU NEGOCIO</h2>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
          <h4
            class="pb-3 text-muted pr-3 text-right home-header"
            style="color: black !important"
          >
            Última actualización a las
            <span class="text-active pl-0 pr-1">
              {{ dateIn }} &nbsp;
              <i
                @click="updateDate"
                :class="isLoading ? 'fa-spin' : ''"
                class="fas cursor fa-sync-alt"
              ></i>
            </span>
          </h4>
        </div>
      </div>
      <div class="header-body"></div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {
  CHARTS_GET_TOP_COURSES,
  CHARTS_GET_TOP_CUSTOMERS,
} from '@/store/actions/charts';

export default {
  name: 'base-header',
  props: {
    type: {
      type: String,
      default: 'transparent',
      description: 'Header background type',
    },
  },
  data() {
    return {
      dateIn: moment().format('hh:mm a'),
      isLoading: false,
    };
  },
  methods: {
    async updateDate() {
      this.isLoading = true;
      this.dateIn = moment().format('hh:mm a');
      await this.$emit('updateValues');
      setTimeout(() => (this.isLoading = false), 4000);
    },
  },
};
</script>
