import axios from 'axios';
import config from '@/config/config';

import { CREATE_SEO, UPDATE_SEO } from '../actions/seo';

const actions = {
  [CREATE_SEO]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/seo/create`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .then((err) => {
          console.log('err', err);
          reject(err);
        });
    });
  },
  [UPDATE_SEO]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/seo/update`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .then((err) => {
          console.log('err', err);
          reject(err);
        });
    });
  },
};

export default {
  actions,
};
