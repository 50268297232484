import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';

// GeneralViews
import NotFoundPage from '@/views/NotFoundPage.vue';
import {
  checkAClass,
  checkAdminAuthentication,
  checkAuthentication,
  checkUserAuthentication,
  checkUserIsVerified,
  checkWithOutAuthentication,
  hasUserCourse,
  hasUserCourseVideo,
} from './permissions';

const Search = () =>
  import(
    /*webpackChuckName:"explore-search"*/ '@/views/Pages/ExploreSearch.vue'
  );

const Calendar = () =>
  import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

const Invoices = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/Pages/Admin/Users/Invoices.vue'
  );
const InvoiceCode = () =>
  import(
    /* webpackChunkName:  dashboard */ '@/views/Pages/Admin/Users/Invoice_id.vue'
  );
const InvoiceDetail = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/Pages/Admin/Users/InvoiceDetails.vue'
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/Pages/Admin/Users/OrderDetails.vue'
  );

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/Dashboard/Admin/Dashboard.vue'
  );
const AlternativeDashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue'
  );
const Widgets = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');
const MediaLibrary = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Media.vue');

// Dashboard reports
const DashboardReports = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Reports.vue');

const UserSettingsView = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/User/UserSettingsView.vue'
  );
const UserDashboard = () =>
  import(
    /* webpackChunkName: "UserDashboard" */ '@/views/Pages/User/UserDashboard.vue'
  );

const AdminSettings = () =>
  import(
    /* webpackChunkName: "AdminSettings" */ '@/views/Pages/Admin/Setting/Settings.vue'
  );

// const PartnerPerfil = () =>
//   import(
//     /* webpackChunkName "pages" */ '@/views/Pages/Partner/Profile/UserProfile.vue'
//   );
const AdminCourses = () =>
  import(/* webpackChunkName: "pages" */ '@/views/Pages/Admin/Courses.vue');

const Videos = () =>
  import(/* webpackChunkName: "pages" */ '@/views/Pages/Admin/Videos.vue');
const CourseDetails = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/CourseDetails.vue'
  );
const CourseInstructors = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/CourseInstructors.vue'
  );
const CourseInstructorCourses = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/CourseInstructorCourses.vue'
  );
const CourseInstructorClasses = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/CourseInstructorClasses.vue'
  );
const CourseCategoryCourses = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/CourseCategoryCourses.vue'
  );
const CourseCategoryClasses = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/CourseCategoryClasses.vue'
  );
const Partners = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/Partner/ListPartner.vue'
  );
const Users = () =>
  import(
    /* webpackChunkName: "UserView" */ '@/views/Pages/Admin/Users/UserView.vue'
  );
const Coupons = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/Coupon/ListCoupon.vue'
  );
const Products = () =>
  import(/* webpackChunkName: "pages" */ '@/views/Pages/Admin/Products.vue');
const ProductCategories = () =>
  import(
    /* webpackChunkName: "pages" */ '@/views/Pages/Admin/ProductCategories.vue'
  );

const Login = () =>
  import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');

const Register = () =>
  import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');

const CourseVideos = () =>
  import(/* webpackChunkName: "pages" */ '@/views/Pages/CourseVideos.vue');

const UserProgress = () =>
  import(
    /* webpackChunkName: "MyProgress" */ '@/views/Pages/User/UserProgress.vue'
  );
const UserProgressCompletedClasses = () =>
  import(
    /* webpackChunkName: "MyProgress" */ '@/views/Pages/User/UserProgressCompletedClasses.vue'
  );
const UserFavoritesView = () =>
  import(
    /* webpackChunkName: "Favorites" */ '@/views/Pages/User/UserFavoritesView.vue'
  );
const ClassLayout = () =>
  import(/* webpackChunkName: "Descubre" */ '@/views/Layout/ClassLayout.vue');
const UserLayout = () =>
  import(/* webpackChunkName: "UserLayout" */ '@/views/Layout/UserLayout.vue');

const PublicLayout = () =>
  import(
    /* webpackChunkName: "Public Layout" */ '@/views/Layout/PublicLayout.vue'
  );

const Home = () =>
  import(/* webpackChunkName: "Home" */ '@/views/Pages/Home.vue');
const AboutUs = () =>
  import(
    /* webpackChunkName: "AboutUs" */ '@/views/Pages/Home/Layouts/AboutUs.vue'
  );
const FrequentQuestions = () =>
  import(
    /* webpackChunkName: "FrequentQuestions" */ '@/views/Pages/Home/Layouts/FrequentQuestions.vue'
  );

const TermsConditions = () =>
  import(
    /* webpackChunkName: "TermsConditions" */ '@/views/Pages/TermsConditions.vue'
  );
const Contactanos = () =>
  import(
    /* webpackChunkName: "Contactanos" */ '@/views/Pages/Home/Layouts/Contactanos.vue'
  );
const InstructorDetails = () =>
  import(
    /* webpackChunkName: "Descubre" */ '@/views/Pages/InstructorDetails.vue'
  );

const PublicCourseDetails = () =>
  import(/* webpackChunkName: "Descubre" */ '@/views/Pages/CourseDetails.vue');

const PartnerDetails = () =>
  import(
    /* webpackChunkName: "Descubre" */ '@/views/Pages/Home/Partners/PartnerDetails.vue'
  );
const PrivacyPolitic = () =>
  import(
    /* webpackChunkName: "PrivacyPolitic" */ '@/views/Pages/PrivacyPolitic.vue'
  );

const ClassNewView = () =>
  import(
    /* webpackChunkName: "PrivacyPolitic" */ '@/views/Pages/Class/ClassNewView.vue'
  );
// const CategoryCourses = () =>
//   import(
//     /* webpackChunkName: "Descubre" */ '@/views/Pages/Home/Categories/CategoryCourses.vue'
//     );
// const CoursesPopulars = () =>
//   import(
//     /* webpackChunkName: "Descubre" */ '@/views/Pages/Home/Components/DescubreComponents/Explore/AllPopulars.vue'
//     );
// const AllClass = () =>
//   import(
//     /* webpackChunkName: "Descubre" */ '@/views/Pages/Home/Components/DescubreComponents/Explore/AllClass.vue'
//     );
// const CoursesTrends = () =>
//   import(
//     /* webpackChunkName: "Descubre" */ '@/views/Pages/Home/Components/DescubreComponents/Explore/AllTrends.vue'
//     );
// const AllCourses = () =>
//   import(
//     /* webpackChunkName: "Descubre" */ '@/views/Pages/Home/Components/DescubreComponents/Explore/AllCourses.vue'
//     );
// const Search = () =>
//   import(
//     /* webpackChunkName: "Descubre" */ '@/views/Pages/Home/Components/DescubreComponents/Explore/Search.vue'
//     );
const Cart = () =>
  import(/* webpackChunkName: "Cart" */ '@/views/Pages/Home/Layouts/Cart.vue');
const Checkout = () =>
  import(
    /* webpackChunkName: "Checkout" */ '@/views/Pages/Home/Layouts/Checkout.vue'
  );
const Instructors = () =>
  import(/* webpackChunkName: "instructors"*/ '@/views/Pages/Instructors.vue');

// const coursesLayout = () =>
//   import(
//     /* webpackChunkName: "Courses" */ '@/views/Pages/Home/Layouts/CoursesLayout.vue'
//     );

// Auth Pages
const RegisterSuccess = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Auth/RegisterSuccess.vue'
  );

const ConfirmUserResetEmail = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Auth/ConfirmUserResetEmail.vue'
  );
const RecoveryPassword = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Auth/PasswordRecovery.vue'
  );
const successRecoveryEmail = () =>
  import(/* webpackChunkName: "auth" */ '@/views/Pages/Auth/RecoveryEmail.vue');
const recoveryPasswordForm = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Auth/PasswordRecoveryForm.vue'
  );
const PasswordResetSuccess = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Auth/PasswordResetSuccess.vue'
  );
const ResetEmailForm = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Auth/ResetEmailForm.vue'
  );
const PaypalCheckoutAccount = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Auth/PaypalCheckoutAccount.vue'
  );
const Categories = () =>
  import(
    /* webpackChunkName: "components" */ '@/views/Pages/Admin/Categories.vue'
  );

const Advertisement = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Admin/Advertisement/Advertisement.vue'
  );

const PaymentsAdmin = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Admin/Partner/payments.vue'
  );
/*
 * Partner Routes
 */
// const PartnerCourses = () =>
//   import(/* webpackChunkName: "auth" */ '@/views/Pages/Partner/Courses.vue');

// const PartnerRewards = () =>
//   import(
//     /* webpackChunkName: "auth" */ '@/views/Pages/Partner/rewardsWithdraw.vue'
//   );

const ListParnetCourses = () =>
  import(
    /* webpackChunkName: "auth" */ '@/views/Pages/Admin/Partner/PartnerCourse.vue'
  );

const UserOrders = () =>
  import(
    /* webpackChunkName: "users orders" */ '@/views/Pages/Admin/Users/UserOrders.vue'
  );

const CompletePayment = () =>
  import(
    /* webpackChunkName: "checkout complete payment" */ '@/views/Pages/Checkout/CompletePayment.vue'
  );

const UserCoursesView = () =>
  import(
    /* webpackChunkName: "user classes" */ '@/views/Pages/User/UserCoursesView.vue'
  );

const CoursesView = () =>
  import(
    /* webpackChunkName: "user classes" */ '@/views/Pages/Courses/CoursesView.vue'
  );

const CoursePreview = () =>
  import(
    /* webpackChunkName: "user classes" */ '@/views/Pages/Admin/CoursePreview.vue'
  );

const ExploreClass = () =>
  import(
    /* webpackChunkName: "explore classes" */ '@/views/Pages/ExploreClass.vue'
  );

const ClassFeaturedView = () =>
  import(
    /* webpackChunkName: "explore classes" */ '@/views/Pages/Class/ClassFeaturedView.vue'
  );

const ClassPopularView = () =>
  import(
    /* webpackChunkName: "explore classes" */ '@/views/Pages/Class/ClassPopularView.vue'
  );

const ClassTrendView = () =>
  import(
    /* webpackChunkName: "explore classes" */ '@/views/Pages/Class/ClassTrendView.vue'
  );

const ClassVideo = () =>
  import(
    /* webpackChunkName: "explore classes" */ '@/views/Pages/Class/ClassView.vue'
  );

const CampaignEmailMarketing = () =>
  import(
    /* webpackChunkName: "campaign marketing" */ '@/views/Pages/Admin/Marketing/CampaignEmailMarketing.vue'
  );

const SegmentEmailMarketing = () =>
  import(
    /* webpackChunkName: "campaign marketing" */ '@/views/Pages/Admin/Marketing/SegmentEmailMarketing.vue'
  );

const SequenceEmailMarketing = () =>
  import(
    /* webpackChunkName: "campaign marketing" */ '@/views/Pages/Admin/Marketing/SequenceEmailMarketing.vue'
  );

const Classes = () => import('@/views/Pages/Admin/classes/Classes.vue');
const ClassDetails = () =>
  import('@/views/Pages/Admin/classes/ClassDetails.vue');
const ClassPreview = () =>
  import('@/views/Pages/Admin/classes/ClassPreview.vue');

const InactiveUser = () =>
  import(/* webpackChunkName: "auth" */ '@/views/Pages/Auth/InactiveUser.vue');

const userRoutes = {
  path: '/user',
  name: 'UserRoutes',
  component: UserLayout,
  beforeEnter: checkUserAuthentication,
  children: [
    {
      path: 'favoritos',
      name: 'UserFavoritesView',
      component: UserFavoritesView,
    },
    {
      path: 'dashboard',
      name: 'UserDashboard',
      component: UserDashboard,
    },
    {
      path: 'configuracion/:option',
      name: 'UserSettingsView',
      component: UserSettingsView,
    },
    {
      path: 'cursos',
      name: 'UserCoursesView',
      component: UserCoursesView,
    },
    {
      path: 'progreso',
      name: 'UserProgress',
      component: UserProgress,
    },
    {
      path: 'progreso/clases/completadas',
      name: 'UserProgressCompletedClasses',
      component: UserProgressCompletedClasses,
    },
  ],
};

const publicRoutes = {
  path: '/',
  name: 'PublicRoutes',
  component: PublicLayout,
  children: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: checkAuthentication,
    },
    {
      path: 'nosotros',
      name: 'Nosotros',
      component: AboutUs,
    },
    {
      path: 'contactanos',
      name: 'Contactanos',
      component: Contactanos,
    },
    {
      path: 'politica-privacidad',
      name: 'PrivacyPolitic',
      component: PrivacyPolitic,
    },
    {
      path: 'preguntas-frecuentes',
      name: 'FrequentQuestions',
      component: FrequentQuestions,
    },
    {
      path: 'terminos-condiciones',
      name: 'TermsConditions',
      component: TermsConditions,
    },

    {
      path: 'cart',
      component: Cart,
    },
    {
      path: 'cart/checkout',
      component: Checkout,
    },
    {
      path: 'cart/checkout/complete/:invoiceId',
      name: 'CompletePayment',
      component: CompletePayment,
    },
    {
      path: 'instructores',
      name: 'Instructors',
      component: Instructors,
    },
    {
      path: '/instructores/instructor/:id/:title',
      component: InstructorDetails,
    },
    {
      path: 'socio/:id',
      component: PartnerDetails,
    },
    {
      path: '/cursos/curso/:id/:title',
      component: PublicCourseDetails,
      beforeEnter: hasUserCourse,
    },
    {
      path: '/paypal/checkout',
      name: 'CheckoutPaypalAccount',
      component: PaypalCheckoutAccount,
    },

    {
      path: '/clase/:slug',
      name: 'ClassVideo',
      component: ClassVideo,
      beforeEnter: checkAClass,
    },
  ],
};

const authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  beforeEnter: checkWithOutAuthentication,
  children: [
    {
      path: '/recordar',
      name: 'RecoveryPassword',
      component: RecoveryPassword,
    },
    {
      path: '/recuperar-correo',
      name: 'successRecoveryEmail',
      component: successRecoveryEmail,
    },
    {
      path: '/formulario/recuperacion/:email/:token',
      name: 'recoveryPasswordForm',
      component: recoveryPasswordForm,
    },
    {
      path: '/recuperacion/exitosa',
      name: 'PasswordResetSuccess',
      component: PasswordResetSuccess,
    },
    {
      path: '/cambiar/correo',
      name: 'ResetEmailForm',
      component: ResetEmailForm,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
    },
  ],
};
/*
const partnerRoutes = {
  path: '/dashboard',
  component: DashboardLayout,
  name: 'Partner layout',
  beforeEnter: requirePartnerAuth,
  children: [
    {
      path: '',
      name: 'Dashboard',
      component: DashboardReports,
    },
    {
      path: '/partner/cursos',
      name: 'PartnerCourses',
      component: PartnerCourses,
    },
    {
      path: '/partner/medios',
      name: 'PartnerMedia',
      component: MediaLibrary,
    },
    {
      path: '/partner/retiros',
      name: 'PartnerRewards',
      component: PartnerRewards,
    },
    {
      path: '/partner/perfil/editar',
      name: 'Perfil editar',
      component: PartnerPerfil,
    },
    { path: '*', name: 'NotFound', component: NotFound },
  ],
};
*/
const adminRoutes = {
  path: '/admin',
  component: DashboardLayout,
  name: 'Dashboard layout',
  beforeEnter: checkAdminAuthentication,
  children: [
    {
      path: '',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: 'reportes',
      name: 'Reportes',
      component: DashboardReports,
    },
    {
      path: 'medios',
      name: 'Media',
      component: MediaLibrary,
    },
    {
      path: 'alternative',
      name: 'Alternative',
      component: AlternativeDashboard,
      meta: {
        navbarType: 'light',
      },
    },

    {
      path: 'ordenes/:id',
      name: 'OrdersDetails',
      component: OrderDetails,
    },
    {
      path: 'facturas',
      name: 'Invoices',
      component: Invoices,
    },
    {
      path: 'invoices/invoice/:code',
      name: 'Invoices',
      component: InvoiceCode,
    },
    {
      path: 'invoices/invoice/detail/:code',
      name: 'Invoices',
      component: InvoiceDetail,
    },
    {
      path: 'cursos',
      name: 'Admin Courses',
      component: AdminCourses,
    },
    {
      path: 'clases',
      name: 'Classes',
      component: Classes,
    },
    {
      path: 'clases/clase/:id',
      name: 'ClassDetails',
      component: ClassDetails,
    },
    {
      path: 'galeria',
      name: 'Videos',
      component: Videos,
    },
    {
      path: 'cursos/curso/:id',
      name: 'Edit Course',
      component: CourseDetails,
    },

    {
      path: 'instructores',
      name: 'Course Instructors',
      component: CourseInstructors,
    },
    {
      path: 'instructor/instructor/:id',
      name: 'Course Instructor Courses',
      component: CourseInstructorCourses,
    },
    {
      path: 'instructor/clases/:id',
      name: 'Course Instructor Classes',
      component: CourseInstructorClasses,
    },
    {
      path: 'curso/categoria/:id',
      name: 'Course Category Courses',
      component: CourseCategoryCourses,
    },
    {
      path: 'curso/categoria/:id/clases',
      name: 'Course Category Courses Classes',
      component: CourseCategoryClasses,
    },
    {
      path: 'partners/invitar',
      name: 'InvitePartner',
      component: CourseDetails,
    },
    {
      path: 'partners',
      name: 'Partners',
      component: Partners,
    },
    {
      path: 'partners/classes/:id',
      name: 'PartnerCourses',
      component: ListParnetCourses,
    },
    {
      path: 'partners/pagos',
      name: 'PartnersPayment',
      component: PaymentsAdmin,
    },
    {
      path: 'usuarios',
      name: 'ListUser',
      component: Users,
    },
    {
      path: 'usuarios/ordenes/:id',
      name: 'UserOrdersByUser',
      component: UserOrders,
    },
    {
      path: 'cupones',
      name: 'Coupons',
      component: Coupons,
    },
    {
      path: 'productos/libreria',
      name: 'Libreria',
      component: Products,
    },
    {
      path: 'productos/categorias',
      name: 'Categoria',
      component: ProductCategories,
    },

    {
      path: 'calendar',
      name: 'Calendar',
      component: Calendar,
    },
    {
      path: 'charts',
      name: 'Charts',
      component: Charts,
    },
    {
      path: 'widgets',
      name: 'Widgets',
      component: Widgets,
    },
    {
      path: 'categorias',
      name: 'Categories',
      component: Categories,
    },
    {
      path: 'anuncios',
      name: 'Advertisement',
      component: Advertisement,
    },
    {
      path: 'marketing/email/campaigns',
      name: 'Email Campaigns Marketing',
      component: CampaignEmailMarketing,
    },
    {
      path: 'marketing/email/segments',
      name: 'Email Segments Marketing',
      component: SegmentEmailMarketing,
    },
    {
      path: 'marketing/email/sequence',
      name: 'Email Sequence Marketing',
      component: SequenceEmailMarketing,
    },
    {
      path: 'configuracion/:option',
      name: 'Admin Settings',
      component: AdminSettings,
    },
  ],
};

const previewCourseRoute = {
  path: '/admin/cursos/curso/preview/:id',
  name: 'Preview Course',
  component: CoursePreview,
};

const previewClassRoute = {
  path: '/admin/clases/clase/preview/:id',
  name: 'Preview Class',
  component: ClassPreview,
};

const exploreRoutes = {
  path: '/explorar',
  name: 'CourseRoutes',
  component: ClassLayout,
  children: [
    {
      path: '',
      component: ExploreClass,
      name: 'ExploreView',
    },
    {
      path: '/explorar/categoria/:slug/',
      component: ExploreClass,
      name: 'ExploreView',
    },
    {
      path: '/explorar/clases/:slug/',
      component: ClassNewView,
      name: 'ClassNewView',
    },
    {
      path: '/explorar/clases/categoria/:slug/',
      component: ClassNewView,
      name: 'ClassNewView',
    },
    {
      path: '/explorar/destacados/categoria/:slug/',
      component: ClassFeaturedView,
      name: 'ClassFeatured',
    },
    {
      path: '/explorar/populares/categoria/:slug/',
      component: ClassPopularView,
      name: 'ClassPopular',
    },
    {
      path: '/explorar/tendencia/categoria/:slug/',
      component: ClassTrendView,
      name: 'ClassTrend',
    },
    {
      path: '/explorar/cursos/categoria/:slug/',
      component: CoursesView,
      name: 'CoursesView',
    },
    {
      path: '/explorar/clases',
      component: ClassNewView,
      name: 'ClassNewView',
    },
    {
      path: '/explorar/destacados',
      component: ClassFeaturedView,
      name: 'ClassFeatured',
    },

    {
      path: '/explorar/populares',
      component: ClassPopularView,
      name: 'ClassPopular',
    },

    {
      path: '/explorar/tendencia',
      component: ClassTrendView,
      name: 'ClassTrend',
    },
    {
      path: '/explorar/cursos',
      component: CoursesView,
      name: 'CoursesView',
    },
    {
      path: '/explorar/busqueda',
      component: Search,
      name: 'ExploreSearch',
    },
  ],
};

const courseRoute = {
  path: '/curso',
  name: 'Course Videos',
  component: PublicLayout,
  children: [
    {
      path: ':id/:name/video/:course_video_id',
      name: 'Course Video',
      component: CourseVideos,
      beforeEnter: hasUserCourseVideo,
    },
  ],
};

const verifyUserRoute = {
  path: '/confirmar-correo',
  name: 'RegisterSuccess',
  component: RegisterSuccess,
  beforeEnter: checkUserIsVerified,
};

const verifyUserChangeEmailRoute = {
  path: '/confirmar-correo/cambiar-correo',
  name: 'ConfirmUserResetEmail',
  component: ConfirmUserResetEmail,
  beforeEnter: checkUserIsVerified,
};

const inactiveUserRoute = {
  path: '/usuario-inactivo',
  name: 'Inactive User',
  component: InactiveUser,
};

const notFoundPage = { path: '*', name: 'NotFound', component: NotFoundPage };

const routes = [
  courseRoute,
  publicRoutes,
  exploreRoutes,
  userRoutes,
  //Authentication routes
  verifyUserChangeEmailRoute,
  verifyUserRoute,
  adminRoutes,
  authPages,
  previewCourseRoute,
  previewClassRoute,
  notFoundPage,
  inactiveUserRoute,
];

export default routes;
