export const FETCH_PUBLIC_CATEGORIES = 'FETCH_PUBLIC_CATEGORIES';
export const FETCH_PUBLIC_INSTRUCTORS = 'FETCH_PUBLIC_INSTRUCTORS';
export const FETCH_PUBLIC_COURSES = 'FETCH_PUBLIC_COURSES';
export const FETCH_PUBLIC_COURSE = 'FETCH_PUBLIC_COURSE';
export const FETCH_PUBLIC_FEATURED_COURSE_VIDEOS =
  'FETCH_PUBLIC_FEATURED_COURSE_VIDEOS';

export const FETCH_PUBLIC_INSTRUCTOR = 'FETCH_PUBLIC_INSTRUCTOR';

export const FETCH_PUBLIC_INSTRUCTORS_LIST = 'FETCH_PUBLIC_INSTRUCTORS_LIST';
export const FETCH_PUBLIC_ADVERTISEMENT_LIST =
  'FETCH_PUBLIC_ADVERTISEMENT_LIST';

export const FETCH_PUBLIC_INSTRUCTORS_FEATURED =
  'FETCH_PUBLIC_INSTRUCTORS_FEATURED';

export const FETCH_PUBLIC_CLASSES = 'FETCH_PUBLIC_CLASSES';
export const FETCH_PUBLIC_FEATURED_CLASSES = 'FETCH_PUBLIC_FEATURED_CLASSES';

export const FETCH_CURRENT_CLASS = 'FETCH_CURRENT_CLASS';

export const FETCH_POPULAR_CLASSES = 'FETCH_POPULAR_CLASSES';
export const FETCH_TREND_CLASSES = 'FETCH_TREND_CLASSES';

export const FETCH_HOME_CLASSES = 'FETCH_HOME_CLASSES';
