var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('side-bar',{scopedSlots:_vm._u([(_vm.getProfile.roles.includes('admin'))?{key:"links",fn:function(){return [_c('sidebar-item',{attrs:{"link":{
          name: 'Inicio',
          icon: 'fa fa-home',
          path: '/admin',
        }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a',{class:_vm.$route.fullPath === '/admin' ? 'nav-link active' : 'nav-link',attrs:{"href":"/admin"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Inicio")])])]},proxy:true}],null,false,1979885905)}),_c('sidebar-item',{attrs:{"link":{
          name: 'Videos',
          icon: 'far fa-play-circle',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Cursos', path: '/admin/cursos' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Clases', path: '/admin/clases' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Libreria de videos',
            path: '/admin/galeria',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Instructores',
            path: '/admin/instructores',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Categorias',
            path: '/admin/categorias',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Anuncios',
          icon: 'fa fa-bullhorn',
          path: '/admin/anuncios',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Activos',
          icon: 'fas fa-palette',
          path: '/admin/medios',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Productos',
          icon: 'far fa-chart-bar',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Libreria',
            path: '/admin/productos/libreria',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Categorias',
            path: '/admin/productos/categorias',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Cupones',
          icon: 'fas fa-ticket-alt',
          path: '/admin/cupones',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Socios',
          icon: 'far fa-handshake',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Ver Socios', path: '/admin/partners' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Pagos',
            path: '/admin/partners/pagos',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Usuarios',
          icon: 'fas fa-user',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Ver Usuarios', path: '/admin/usuarios' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Facturas', path: '/admin/facturas' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Mercadeo',
          icon: 'fas fa-hand-holding-usd',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Email',
            path: '/admin/marketing/email',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Campañas',
              path: '/admin/marketing/email/campaigns',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Segmentos',
              path: '/admin/marketing/email/segments',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Secuencias',
              path: '/admin/marketing/email/sequence',
            }}})],1),_c('sidebar-item',{attrs:{"link":{
            name: 'SMS',
            path: '/admin/marketing/text-marketing',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Reportes',
          icon: 'fas fa-chart-area',
          path: '/admin/reportes',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Configuración',
          icon: 'fab fa-whmcs',
          path: '/admin/configuracion/cuenta',
        }}})]},proxy:true}:null],null,true)},[(_vm.getProfile.roles.includes('partner'))?[_c('sidebar-item',{attrs:{"link":{
          name: 'Inicio',
          icon: 'fa fa-home',
          path: '/dashboard',
        }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a',{class:_vm.$route.fullPath == '/dashboard' ? 'nav-link active' : 'nav-link',attrs:{"href":"/dashboard"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Inicio")])])]},proxy:true}],null,false,2193418508)}),_c('sidebar-item',{attrs:{"link":{
          name: 'Mis cursos',
          icon: 'fas fa-archive',
          path: '/partner/cursos',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Activos',
          icon: 'fas fa-file-image',
          path: '/partner/medios',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Comisiones & Retiros',
          icon: 'fas fa-credit-card',
          path: '/partner/retiros',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Configuración',
          icon: 'fab fa-whmcs',
          path: '/partner/perfil/editar',
        }}})]:_vm._e(),(_vm.getProfile.roles.includes('user'))?[_c('sidebar-item',{attrs:{"link":{
          name: 'Inicio',
          icon: 'fa fa-home',
          path: '/user',
        }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a',{class:_vm.$route.fullPath === '/user' ? 'nav-link active' : 'nav-link',attrs:{"href":"/user"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Inicio")])])]},proxy:true}],null,false,47416273)}),_c('sidebar-item',{attrs:{"link":{
          name: 'Compras',
          icon: 'far fa-play-circle',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Ver compras', path: '/user/invoices' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Ordenes',
            path: '/user/orders',
          }}})],1)]:_vm._e()],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar'),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"mode":"out-in","origin":"center top"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }