//CARDS

export const ADD_NEW_CARD = 'ADD_NEW_CARD';
export const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';

export const GET_CREDIT_CARD_LIST = 'GET_CREDIT_CARD_LIST';
export const GET_THIS_CARD = 'GET_THIS_CARD';
export const ADD_FAVOURITE_THIS_CARD = 'ADD_FAVOURITE_THIS_CARD';
export const REMOVE_FAVOURITE_THIS_CARD = 'ADD_FAVOURITE_THIS_CARD';
export const GET_THIS_CARD_FAVOURITE = 'GET_THIS_CARD_FAVOURITE';

export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';

export const ADD_PRIMARY_CARD = 'ADD_PRIMARY_CARD';

// EVENTS FOR OPEN/CLOSE CREATE/UPDATE CREDIT CARD DIALOG
export const OPEN_CREDIT_CARD_DIALOG = 'OPEN_CREDIT_CARD_DIALOG';
export const CLOSE_CREDIT_CARD_DIALOG = 'CLOSE_CREDIT_CARD_DIALOG';

export const CHANGE_CHECKOUT_CREDIT_CARD = 'CHANGE_CHECKOUT_CREDIT_CARD';
