import axios from 'axios';
import config from '@/config/config';

import {
  CREATE_COUPON,
  FETCH_COUPONS,
  UPDATE_COUPON,
  DELETE_COUPON,
} from '../actions/coupon';

const state = {
  coupons: [],
  codeCouponArray: [],
  couponsArray: [],
};

const getters = {
  getCoupons: (state) => state.coupons,
  getCodeCouponArray: (state) => state.codeCouponArray,
  getCouponsArray: (state) => state.couponsArray,
};

const actions = {
  [CREATE_COUPON]: (_, coupon) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/coupon/create', coupon, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [UPDATE_COUPON]: (_, coupon) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${config.data.apiUrl}/admin/coupon/update/${coupon.id}`,
          coupon,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_COUPONS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/coupon/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_COUPONS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_COUPON]: (_, coupon) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + '/admin/coupon/delete/' + coupon.id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [FETCH_COUPONS]: (state, resp) => {
    state.coupons = resp.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
