<template>
  <div class="mt-4">
    <div class="row pr-3">
      <div class="col-md-9">
        <label class="style-text-config">Seleccionar socio</label>

        <multiselect
          v-model="partner"
          :options="partners"
          :searchable="true"
          :close-on-select="false"
          :show-labels="false"
          placeholder="Seleccione socio"
          track-by="email"
          label="email"
          class="partner-select"
          :allow-empty="true"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <strong>{{ option.name + ' - ' + option.email }}</strong>
          </template>
          <template #noResult>
            <div>Sin resultados</div>
          </template>
        </multiselect>
        <div class="col-12 dropdwon-icon">
          <i class="fas fa-chevron-down instructor float-right"></i>
        </div>
      </div>
      <div v-if="partner" class="col-md-9 mt-4">
        <label class="style-text-config">Porcentaje definido al socio</label>
        <base-input
          @focus="kickPlaceHolder"
          @blur="placeHolder()"
          v-model="percent"
          :placeholder="placeholder"
          class="partner-input"
        ></base-input>
      </div>
    </div>
    <div class="mt-5">
      <base-button
        v-if="this.percentA > 0"
        @click="showDialogPercentage = true"
        class="btn-maquillate"
      >
        Guardar cambios
      </base-button>
      <base-button v-else @click="savePartner($event)" class="btn-maquillate">
        Guardar cambios
      </base-button>
    </div>
    <div>
      <md-dialog
        :md-active.sync="showDialogPercentage"
        class="modal-close modalPercentage"
      >
        <span
          class="material-icons close text-right"
          @click="showDialogPercentage = false"
          >close</span
        >
        <div class="body-modal-course">
          <md-dialog-content class="mt-2">
            <span>{{
              `Estás seguro de cambiar el porcentaje de ${this.percentA} ? si lo cambias, los nuevos cálculos serán efectuados con este porcentaje ${this.percent} a partir de este momento.`
            }}</span></md-dialog-content
          >

          <md-dialog-actions>
            <md-button
              class="md-button md-primary md-theme-default"
              @click="showDialogPercentage = false"
              >CANCELAR</md-button
            >
            <md-button
              class="md-button md-primary md-theme-default"
              @click="savePartner($event)"
              >CAMBIAR</md-button
            >
          </md-dialog-actions>
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { USER_GET_PARTNERS } from '@/store/actions/user';
import { mapGetters, mapState } from 'vuex';
import {
  COURSE_UPDATE_PARTNER_DATA,
  COURSE_GET_COURSE,
} from '@/store/actions/course';

import Multiselect from 'vue-multiselect';

export default {
  name: 'CourseConfigPartner',

  components: {
    Multiselect,
  },
  data: function () {
    return {
      partner: null,
      partners: [],
      percent: '',
      percentA: 0.0,
      showDialogPercentage: false,
      placeholder: 'Porcentaje de ganancias al socio',
    };
  },
  mounted() {
    this.getInformation();
  },
  methods: {
    placeHolder() {
      console.log(this.percent.length);
      if (this.percent != '') {
        this.percent = this.percent;
      } else if (this.percent === '' || this.percent.length == 0) {
        this.placeholder = 'Porcentaje de ganancias al socio';
      }
    },
    kickPlaceHolder() {
      this.placeholder = '';
    },
    getInformation() {
      let course = {
        id: this.$route.params.id,
      };

      this.$store
        .dispatch(USER_GET_PARTNERS)
        .then((resp) => {
          let data = Object.values(this.getPartners).map((data) => data);
          this.partners = data;
          // console.log('dsdfdsf')
          // console.log(this.partners)
          //return
          this.$store
            .dispatch(COURSE_GET_COURSE, course)
            .then((part) => {
              // console.log('sdsdsdsdsdsdsdsd')
              // console.log(part.data[0].partner_id)
              this.partner = this.partners.filter((partner) => {
                return partner.id == part.data[0].partner_id;
              });

              // console.log('sssssssssss')
              // console.log(this.partner)

              if (this.partner.length == 0) {
                this.partner = null;
              }

              this.percentA = part.data[0].partner_percent
                ? (part.data[0].partner_percent * 100).toFixed(2)
                : 0.0;

              if (part.data[0].partner_percent > 0) {
                this.percent = (part.data[0].partner_percent * 100).toFixed(2);
              } else {
                this.percent = '';
              }
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    save(partner_id, e) {
      const data = {
        course_id: this.$route.params.id,
        partner_id: partner_id,
        partner_percent: this.percent / 100,
      };
      // console.log(data)
      // return
      this.$store
        .dispatch(COURSE_UPDATE_PARTNER_DATA, data)
        .then((resp) => {
          this.getInformation();
          this.showDialogPercentage = false;
          this.$notify({
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Se ha actualizado la información con éxito',
          });
        })
        .catch((err) => {
          console.log(err);
          this.showDialogPercentage = false;
          this.$notify({
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'No se ha podido actualizar la información',
          });
        })
        .finally(() => {
          e.target.removeAttribute('disabled');
        });
    },
    savePartner(e) {
      //e.target.setAttribute("disabled", true);
      // console.log(this.percentA)
      // console.log(this.percent)
      // return
      if (this.percentA == 0.0) {
        let partner_id = this.partner ? this.partner.id : null;
        // console.log('hola1')
        // return
        this.save(partner_id, e);
      } else if (this.percentA > 0 && this.percentA != this.percent) {
        //console.log('intentas cambiar el valor ' + this.percentA + ' a ' + this.percent)
        let partner_id = this.partner ? this.partner[0].id : null;
        // console.log('hola2')
        // return
        this.save(partner_id, e);
      }
    },
  },
  computed: {
    ...mapGetters(['getPartners']),
  },
  watch: {
    partner: function (val) {
      if (!val) {
        this.percent = null;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.partner-input input {
  padding-left: 12px !important;
}
.partner-input input:focus {
  border: 1px solid #dfdfdf;
  box-shadow: none;
}

.partner-select .multiselect__single {
  display: flex;
  align-items: center;
  padding-left: 0px;
}
.partner-select input {
  border: none !important;
}
.partner-select .multiselect__select {
  display: none;
}
</style>
