import axios from 'axios';
import config from '@/config/config';
import {
  SEARCH_ITEMS,
  SEARCH_ALL_INSTRUCTOR,
  SEARCH_ALL_ITEMS,
} from '../actions/search';

const state = {};

const getters = {};

const actions = {
  [SEARCH_ITEMS]: (search) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/search/get/items/${search}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SEARCH_ALL_ITEMS]: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/search/get/itemsAll`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SEARCH_ALL_INSTRUCTOR]: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/search/getAllInformation`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
