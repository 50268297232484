export const MEDIA_CREATE_CATEGORY = 'MEDIA_CREATE_CATEGORY';
export const MEDIA_GET_CATEGORIES = 'MEDIA_GET_CATEGORIES';
export const MEDIA_GET_CATEGORY = 'MEDIA_GET_CATEGORY';

export const MEDIA_DELETE_CATEGORY = 'MEDIA_DELETE_CATEGORY';

export const MEDIA_CREATE_FILE = 'MEDIA_CREATE_FILE';
export const MEDIA_CREATE_FILE_VIDEO = 'MEDIA_CREATE_FILE_VIDEO';
export const MEDIA_UPLOAD_FILE = 'MEDIA_UPLOAD_FILE';
export const MEDIA_UPDATE_FILE = 'MEDIA_UPDATE_FILE';
export const MEDIA_DELETE_FILE = 'MEDIA_DELETE_FILE';
export const MEDIA_DELETE_FILE_VIDEO = 'MEDIA_DELETE_FILE_VIDEO';
export const MEDIA_GET_FILES = 'MEDIA_GET_FILES';
export const MEDIA_GET_PARTNERS_BY_FILE = 'MEDIA_GET_PARTNERS_BY_FILE';
export const MEDIA_GET_PARTNERS_BY_FILES = 'MEDIA_GET_PARTNERS_BY_FILES';
export const MEDIA_ASSIGN_CATEGORY_TO_FILE = 'MEDIA_ASSIGN_CATEGORY_TO_FILE';
export const MEDIA_CATEGORY_UPDATE_NAME = 'MEDIA_CATEGORY_UPDATE_NAME';

export const MEDIA_SHARE_FILE_TO_PARTNERS = 'MEDIA_SHARE_FILE_TO_PARTNERS';
export const MEDIA_SHARE_CATEGORY_WITH_FILES = 'SHARE_CATEGORY_WITH_FILES';
export const MEDIA_GET_PARTNERS_BY_CATEGORIE =
  'MEDIA_GET_PARTNERS_BY_CATEGORIE';
export const MEDIA_GET_PARTNERS_BY_CATEGORIE_TO_UPDATE =
  'MEDIA_GET_PARTNERS_BY_CATEGORIE_TO_UPDATE';
export const MEDIA_DELETE_FILE_IN_CDN = 'MEDIA_DELETE_FILE_IN_CDN';
