import axios from 'axios';
import {
  FETCH_PRODUCTS,
  CREATE_PRODUCT,
  FETCH_PRODUCT_CATEGORIES,
  CREATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from '../actions/products';
import config from '@/config/config';

const state = {
  products: {
    total: 0,
    items: [],
  },
  categories: {
    total: 0,
    items: [],
  },
};

const getters = {
  getProducts: (state) => state.products,
  getProductsCategories: (state) => state.categories,
  getVideoCourseProducts: (state) =>
    state.products.map((product) => ({
      name: product.title,
      code: product.id,
    })),
};

const mutations = {
  [FETCH_PRODUCTS]: (state, products) => {
    state.products = {
      total: products && products.total ? products.total : 0,
      items: products && products.data ? products.data : [],
    };
  },
  [FETCH_PRODUCT_CATEGORIES]: (state, categories) => {
    state.categories = {
      total: categories && categories.total ? categories.total : 0,
      items: categories && categories.data ? categories.data : [],
    };
  },
};

const actions = {
  [CREATE_PRODUCT]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/product/create`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_PRODUCT]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/product/update`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [FETCH_PRODUCTS]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/product/all`, {
          params: {
            ...filter,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(FETCH_PRODUCTS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PRODUCT_CATEGORIES]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/product/category/all`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params: {
            ...filter,
          },
        })
        .then((resp) => {
          commit(FETCH_PRODUCT_CATEGORIES, resp.data);
          resolve(resp.data);
        })
        .catch((error) => reject(error));
    });
  },
  [CREATE_PRODUCT_CATEGORY]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/product/category/create`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_PRODUCT_CATEGORY]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + `/admin/product/category/update`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [DELETE_PRODUCT_CATEGORY]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + `/admin/product/category/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [DELETE_PRODUCT]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + `/admin/product/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
