import { CHANGE_STATE_MENU, RESET_MENU_STATUS } from '../actions/navBar';

const state = {
  collapse: {
    Cursos: false,
    Productos: false,
    Socios: false,
    Usuarios: false,
    Mercadeo: false,
    Email: false,
  },
};

const getters = {
  collapseStatus: (state) => state.collapse,
};

const actions = {
  [CHANGE_STATE_MENU]: ({ commit }, name) => {
    commit(CHANGE_STATE_MENU, name);
  },
  [RESET_MENU_STATUS]: ({ commit }) => {
    commit(RESET_MENU_STATUS);
  },
};

const mutations = {
  [CHANGE_STATE_MENU]: (state, action) => {
    if (action !== 'Mercadeo') {
      state.collapse.Mercadeo = false;
    }
    state.collapse = {
      Cursos: false,
      Productos: false,
      Socios: false,
      Usuarios: false,
      Mercadeo: action === 'Email' ? true : state.collapse.Mercadeo,
      Email: false,
      [action]: !state.collapse[action],
    };
  },

  [RESET_MENU_STATUS]: (state) => {
    state.collapse = {
      Cursos: false,
      Productos: false,
      Socios: false,
      Usuarios: false,
      Mercadeo: false,
      Email: false,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
