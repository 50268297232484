import axios from 'axios';
import config from '@/config/config';

import {
  ADD_NEW_CARD,
  GET_CREDIT_CARD_LIST,
  OPEN_CREDIT_CARD_DIALOG,
  CLOSE_CREDIT_CARD_DIALOG,
  ADD_PRIMARY_CARD,
  UPDATE_CREDIT_CARD,
  DELETE_CREDIT_CARD,
  CHANGE_CHECKOUT_CREDIT_CARD,
} from '../actions/card';
import { DEFAULT_CARD_FORM_VALUES } from '../../util/constants';

const state = {
  creditCardList: [],
  isOpenCreditCardDialog: false,
  creditCard: undefined,
  creditCardPrimary: undefined,
};

const getters = {
  getCreditCardList: (state) => state.creditCardList,
  getCreditCardPrimary: (state) => state.creditCardPrimary,
  isOpenCreditCardDialog: (state) => state.isOpenCreditCardDialog,
  getSelectedCreditCard: (state) => state.creditCard,
};

const actions = {
  [OPEN_CREDIT_CARD_DIALOG]: ({ commit }, card) => {
    commit(OPEN_CREDIT_CARD_DIALOG, card);
  },
  [CLOSE_CREDIT_CARD_DIALOG]: ({ commit }, card) => {
    commit(CLOSE_CREDIT_CARD_DIALOG, card);
  },
  [CHANGE_CHECKOUT_CREDIT_CARD]: ({ commit }, card) => {
    commit(CHANGE_CHECKOUT_CREDIT_CARD, card);
  },
  [ADD_NEW_CARD]: (_, card) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/user/card/create', card, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_CREDIT_CARD_LIST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/card/list', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(GET_CREDIT_CARD_LIST, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [ADD_PRIMARY_CARD]: (_, card) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + `/user/card/primary/${card.id}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [UPDATE_CREDIT_CARD]: (_, card) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          config.data.apiUrl + `/user/card/update/${card.id}`,
          {
            card: card,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            return reject(err.response.data.message);
          }
          reject(err.message);
        });
    });
  },

  [DELETE_CREDIT_CARD]: (_, card) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + `/user/card/delete/${card.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [GET_CREDIT_CARD_LIST]: (state, resp) => {
    state.creditCardList = resp.data;
    state.creditCardPrimary = resp.data.find(
      (creditCard) => creditCard.primary,
    );
  },
  [OPEN_CREDIT_CARD_DIALOG]: (state, card) => {
    state.creditCard = card
      ? {
          id: card.id ?? '',
          cardHolderName: card.card_holder_name,
          email: card.email,
          number: card.number,
          cvc: card.cvc,
          expiredDate: card.expired_date,
          city: card.city,
          state: card.state,
          postalCode: card.postal_code,
          country: card.country,
          address: card.address,
        }
      : DEFAULT_CARD_FORM_VALUES;

    state.isOpenCreditCardDialog = true;
  },
  [CLOSE_CREDIT_CARD_DIALOG]: () => {
    state.creditCard = DEFAULT_CARD_FORM_VALUES;
    state.isOpenCreditCardDialog = false;
  },

  [CHANGE_CHECKOUT_CREDIT_CARD]: (state, card) => {
    state.creditCardPrimary = card;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
