import axios from 'axios';
import config from '@/config/config';
import {
  ORDERS_GET_ALL,
  ORDER_GET_BY_ORDER_ID,
  ORDERS_DETAILS_GET_ALL,
  ORDERS_DETAILS_GET_THIS,
  INVOICE_GET_BY_INVOICEID,
  ORDERS_GET_ALL_BY_USER,
  //admin
  INVOICES_ALL_ADMIN,
  INVOICE_GET_BY_CODE,
  INVOICE_GET_DETAIL_BY_INVOICEID,
} from '../actions/order';

const state = {
  orders: [],
  ordersUser: [],
  InvoicesUser: [],
  InvoicesUserByInvoiceID: [],
  OrderDetailByOrderID: [],
  InvoiceByCode: [],
  OrdersAllByUserr: [],

  //admin
  InvoicesAdmin: [],
  OrdersByInvoiceCode: [],
  InvoiceByCodeForAdmin: [],
};

const getters = {
  getOrdersAll: (state) => state.orders,
  getOrdersAllByUser: (state) => state.ordersUser,
  getInvoicesAllUser: (state) => state.InvoicesUser,
  getInvoicesUserByInvoiceID: (state) => state.InvoicesUserByInvoiceID,
  getOrderDetailByOrderID: (state) => state.OrderDetailByOrderID,
  getInvoiceByCode: (state) => state.InvoiceByCode,
  getOrdersAllByUserr: (state) => state.OrdersAllByUserr,

  //admin
  getInvoicesAll: (state) => state.InvoicesAdmin,
  getOrdersByInvoiceCode: (state) => state.OrdersByInvoiceCode,
  getInvoiceByCodeForAdmin: (state) => state.InvoiceByCodeForAdmin,
};

const actions = {
  [ORDERS_GET_ALL]: ({ commit }, { filter }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/admin/order/allOrders?${filter}`, {
          headers: config.data.headers,
        })
        .then((resp) => {
          commit(ORDERS_GET_ALL, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [ORDERS_DETAILS_GET_ALL]: ({ commit }, { filter }) => {
    console.log(filter);
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/user/invoicess/get?${filter}`, {
          headers: config.data.headers,
        })
        .then((resp) => {
          console.log(resp.data);
          commit(ORDERS_DETAILS_GET_ALL, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ORDERS_DETAILS_GET_THIS]: ({ commit }, { type, invoice_id }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/user/invoices/${type}/${invoice_id}/get`, {
          headers: config.data.headers,
        })
        .then((resp) => {
          commit(ORDERS_DETAILS_GET_THIS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // [INVOICE_GET_PDF_BY_CODE]: ({commit}, {filter, type, code})=>{
  //   return new Promise((resolve, reject) => {
  //     axios.get(config.data.apiUrl + `/user/invoices/print/${type}/${code}/pdf?${filter}`,
  //     {
  //       headers: config.data.headers
  //     }).then((resp) => {

  //     }).catch((err) => {

  //     })
  //   })
  // },
  [ORDER_GET_BY_ORDER_ID]: ({ commit }, { order_id, type }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          config.data.apiUrl + `/user/invoices/${type}/order/get/${order_id}`,
          {
            headers: config.data.headers,
          },
        )
        .then((resp) => {
          commit(ORDER_GET_BY_ORDER_ID, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_GET_BY_INVOICEID]: ({ commit }, { code, type }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/user/invoices/${type}/detail/${code}`, {
          headers: config.data.headers,
        })
        .then((resp) => {
          commit(INVOICE_GET_BY_INVOICEID, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ORDERS_GET_ALL_BY_USER]: ({ commit }, { filter }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/user/get/orders?${filter}`, {
          headers: config.data.headers,
        })
        .then((resp) => {
          commit(ORDERS_GET_ALL_BY_USER, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICES_ALL_ADMIN]: ({ commit }, { filter }) => {
    console.log(filter);
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/admin/invoice/get/all?${filter}`, {
          headers: config.data.headers,
        })
        .then((resp) => {
          console.log(resp.data);
          commit(INVOICES_ALL_ADMIN, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_GET_BY_CODE]: ({ commit }, { code }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/admin/invoice/get/code/${code}`, {
          headers: config.data.headers,
        })
        .then((resp) => {
          commit(INVOICE_GET_BY_CODE, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_GET_DETAIL_BY_INVOICEID]: ({ commit }, code) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/admin/invoice/get/detail/${code}`, {
          headers: config.data.headers,
        })
        .then((resp) => {
          commit(INVOICE_GET_DETAIL_BY_INVOICEID, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [ORDERS_DETAILS_GET_ALL]: (state, { data }) => {
    state.InvoicesUser = data;
  },
  [ORDERS_DETAILS_GET_THIS]: (state, { data }) => {
    state.InvoicesUserByInvoiceID = data;
  },
  [ORDER_GET_BY_ORDER_ID]: (state, { data }) => {
    state.OrderDetailByOrderID = data;
  },
  [INVOICE_GET_BY_INVOICEID]: (state, { data }) => {
    state.InvoiceByCode = data;
  },
  [ORDERS_GET_ALL_BY_USER]: (state, { data }) => {
    state.OrdersAllByUserr = data;
  },
  [ORDERS_GET_ALL]: (state, { data }) => {
    state.orders = data;
  },

  [INVOICES_ALL_ADMIN]: (state, { data }) => {
    state.InvoicesAdmin = data;
  },
  [INVOICE_GET_BY_CODE]: (state, { data }) => {
    state.OrdersByInvoiceCode = data;
  },
  [INVOICE_GET_DETAIL_BY_INVOICEID]: (state, { data }) => {
    state.InvoiceByCodeForAdmin = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
