<template>
  <loading-panel
    v-if="isLoading"
    class="loading-course-detail"
    title="Cargando detalles del curso"
  ></loading-panel>
  <div v-else id="courseForm">
    <div class="form-group row">
      <div class="course-image-container col-md-6 mx-auto">
        <img
          v-if="formData.thumbnailUrl"
          :src="formData.thumbnailUrl"
          alt="Thumbnail"
          class="course-image"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">Imagen de portada</label>
        <file-input @change="filesChange"></file-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="course-image-container col-md-6 mx-auto">
        <img
          v-if="formData.poster"
          :src="formData.poster"
          alt="Thumbnail"
          class="course-image"
        />
        <img
          v-else
          alt="Thumbnail"
          class="course-image"
          src="@/assets/images/logo/placeholder.png"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">Imagen de portada informativa</label>
        <file-input @change="posterChange"></file-input>
      </div>
    </div>

    <div class="form-group row">
      <div class="course-image-container col-md-6 mx-auto">
        <img
          v-if="formData.trailer_poster"
          :src="formData.trailer_poster"
          alt="Thumbnail"
          class="course-image"
        />
        <img
          v-else
          alt="Thumbnail"
          class="course-image"
          src="@/assets/images/logo/placeholder.png"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">Imagen de trailer</label>
        <file-input @change="trailerPosterChange"></file-input>
      </div>
    </div>

    <div class="form-group row mb-0">
      <div class="col-md-12">
        <label class="style-text-config">Nombre del curso</label>
        <base-input
          v-model="formData.name"
          class="name-course-input border-name"
          placeholder="Titulo de la lección"
        ></base-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">DESCRIPCIÓN DE CURSO</label>
        <quill-editor
          ref="myQuillEditor"
          v-model="formData.content"
          :options="editorOption"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">Asignar Trailer</label>
        <multiselect
          v-model="formData.trailer"
          :allow-empty="false"
          :loading="isLoadingTrailer"
          :option-height="104"
          :options="this.getVideos"
          :show-labels="true"
          class="modify-input"
          label="title"
          placeholder="Seleccione video de la galeria"
          track-by="id"
          @search-change="searchTrailer"
        >
          <template #options="props">
              <img
              :src="props.option.thumbnail"
              alt="Video"
              class="option__image"
            />
            <div class="option__desc">
              <span class="option__title">{{
                props.option.title ? props.option.title : ''
              }}</span>
              <span class="option__small">{{
                props.option.content ? props.option.content : ''
              }}</span>
            </div>
          </template>
          <template #noResult>
            <div>Sin resultados</div>
          </template>
          <template #noOptions>
            <div>Sin resultados</div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">TIPO</label>

        <base-radio v-model="formData.free" class="base-radio" name="1">
          CURSO GRATIS
        </base-radio>
        <base-radio v-model="formData.free" class="base-radio" name="0">
          CURSO PAGO
        </base-radio>

        <currency-input
          v-if="!parseInt(formData.free)"
          v-model="formData.price"
          class="form-control col-3 ml-4 input-usd mt-2"
          currency="USD"
          locale="en"
          :precision="2"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">Bullets</label>
        <template v-if="loadingCourseBullet">
          <skeleton-course-bullet />
        </template>
        <template v-else>
          <div
            v-for="_bullet in getCurrentCourseBullets"
            :key="_bullet.id"
            class="d-flex"
          >
            <div style="width: 100%; margin-right: 10px">
              <base-input
                v-model="_bullet.bullet"
                class="name-course-input"
                placeholder="Bullet"
              ></base-input>
            </div>
            <div>
              <base-button
                style="height: 52px; margin-right: 10px; width: 100px"
                class="primary btn-maquillate py-2 px-3"
                :loading="isLoadingUpdateBullet === _bullet.id"
                @click="updateBullet(_bullet)"
                >Editar
              </base-button>
            </div>
            <div>
              <base-button
                style="height: 52px; width: 100px"
                class="primary btn-maquillate py-2 px-3"
                :loading="isLoadingDeleteBullet === _bullet.id"
                @click="deleteBullet(_bullet)"
                >Eliminar
              </base-button>
            </div>
          </div>
          <div class="d-flex">
            <div style="width: 100%; margin-right: 10px">
              <base-input
                v-model="bullet"
                class="name-course-input"
                placeholder="Titulo de la lección"
              ></base-input>
            </div>

            <div>
              <base-button
                class="primary btn-maquillate py-2 px-3"
                style="height: 52px"
                :loading="isLoadingCreateBullet"
                @click="addBullet"
                >Agregar
              </base-button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">Instructor</label>

        <multiselect
          v-model="formData.instructors"
          :loading="isLoadingInstructors"
          :multiple="true"
          :option-height="104"
          :options="this.getInstructors.items"
          :show-labels="true"
          class="border-0 select-config select-instructors-config"
          label="firstname"
          placeholder="Instructores"
          track-by="id"
          @search-change="searchInstructor"
        >
          <template #option="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.firstname + ' ' + props.option.lastname }}
              </span>
              <span
                v-if="
                  props.values && props.values.length && !props.values.isOpen
                "
                class="multiselect__single"
                >{{ props.values.length }}</span
              >
            </div>
          </template>
          <template #noResult>
            <div>Sin resultados</div>
          </template>
          <template #noOptions>
            <div>Sin resultados</div>
          </template>
        </multiselect>
        <!-- <div class="col-12 dropdwon-icon">
          <i class="fas fa-chevron-down instructor float-right"></i>
        </div> -->
      </div>
    </div>

    <!--Select Categoria-->
    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">Categoria</label>

        <multiselect
          v-model="formData.categories"
          :loading="isLoadingCategories"
          :multiple="true"
          :option-height="104"
          :options="this.getCategories.items"
          :show-labels="true"
          :taggable="true"
          class="border-0 select-config"
          label="title"
          placeholder="Categorías"
          track-by="id"
        >
          <template #option="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.title }}</span>
              <span
                v-if="
                  props.values && props.values.length && !props.values.isOpen
                "
                class="multiselect__single"
                >{{ props.values.length }}</span
              >
            </div>
          </template>
          <template #noResult>
            <div>Sin resultados</div>
          </template>
          <template #noOptions>
            <div>Sin resultados</div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <select v-model="formData.status" class="select-status">
          <option
            v-for="status in statuses"
            :key="status.status"
            :value="status.status"
          >
            {{ status.name }}
          </option>
        </select>
        <i class="fas fa-chevron-down details-select"></i>
      </div>
    </div>

    <base-button
      :loading="isUpdateCourse"
      class="primary btn-maquillate py-2 px-3"
      @click="updateCourse"
      >Guardar cambios
    </base-button>
  </div>
</template>

<script>
import FileInput from '@/components/Inputs/FileInput';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import LoadingPanel from '../../LoadingPanel';
import { quillEditor } from 'vue-quill-editor';
import { DEFAULT_COURSE_VALUES } from '../../../util/constants';
import { FETCH_VIDEOS } from '../../../store/actions/video';
import { FETCH_INSTRUCTORS } from '../../../store/actions/instructor';
import { FETCH_CATEGORIES } from '../../../store/actions/categories';
import {
  CREATE_COURSE_BULLET,
  DELETE_COURSE_BULLET,
  FETCH_COURSE,
  FETCH_COURSE_BULLETS,
  UPDATE_COURSE,
  UPDATE_COURSE_BULLET,
  UPDATE_COURSE_THUMBNAIL,
  UPDATE_COURSE_POSTER, UPDATE_TRAILER_COURSE_POSTER,
} from '../../../store/actions/course';
import SkeletonCourseBullet from '../../../views/Components/skeletons/SkeletonCourseBullet.vue';

export default {
  name: 'CourseConfigForm',
  components: {
    FileInput,
    quillEditor,
    Multiselect,
    LoadingPanel,
    SkeletonCourseBullet,
  },
  data: function () {
    return {
      isLoading: true,
      loadingCourseBullet: true,
      formData: DEFAULT_COURSE_VALUES,
      isLoadingTrailer: true,
      isLoadingInstructors: true,
      isLoadingCategories: true,
      isUpdateCourse: false,
      isLoadingCreateBullet: false,
      isLoadingUpdateBullet: undefined,
      isLoadingDeleteBullet: undefined,
      bullet: '',
      editorOption: {
        placeholder: 'Inserta un texto aquí...',
      },
      statuses: [
        {
          status: 'DRAFT',
          name: 'Editando',
        },
        {
          status: 'PUBLISH',
          name: 'Publicado',
        },
      ],
      bullets: [],
    };
  },
  computed: {
    ...mapGetters([
      'getListVideos',
      'getCurrentCourse',
      'getVideos',
      'getInstructors',
      'getCategories',
      'getCurrentCourseBullets',
    ]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  watch: {
    getCurrentCourse: function (newValue) {
      if (newValue) this.isLoading = false;
      this.formData = newValue ? newValue : DEFAULT_COURSE_VALUES;
    },
    getCurrentCourseBullets: function (newValue) {
      if (newValue) {
        this.bullets = newValue.map((value) => value.bullet);
      }
    },
  },
  mounted() {
    this.fetchCourseTrailer();
    this.fetchInstructors();
    this.fetchCategories();
    this.fetchCourseBullets();
  },
  methods: {
    fetchCourseBullets() {
      this.$store
        .dispatch(FETCH_COURSE_BULLETS, { course_id: this.$route.params.id })
        .then(() => (this.loadingCourseBullet = false))
        .catch(() => (this.loadingCourseBullet = false));
    },
    searchTrailer(searchTrailerQuery) {
      this.fetchCourseTrailer({ search: searchTrailerQuery });
    },
    searchInstructor(searchInstructorQuery) {
      this.fetchInstructors({ search: searchInstructorQuery });
    },
    searchCategory(searchCategoryQuery) {
      this.fetchCategories({ search: searchCategoryQuery });
    },
    fetchCourseTrailer(filter = { search: '' }) {
      this.isLoadingTrailer = true;
      this.$store.dispatch(FETCH_VIDEOS, filter).then(() => {
        this.isLoadingTrailer = false;
      });
    },
    fetchInstructors(filter = { search: '' }) {
      this.isLoadingInstructors = true;
      this.$store.dispatch(FETCH_INSTRUCTORS, filter).then(() => {
        this.isLoadingInstructors = false;
      });
    },
    fetchCategories(filter = { search: '' }) {
      this.isLoadingCategories = true;
      this.$store.dispatch(FETCH_CATEGORIES, filter).then(() => {
        this.isLoadingCategories = false;
      });
    },

    refetchCourse() {
      this.isLoading = true;
      this.$store
        .dispatch(FETCH_COURSE, this.$route.params.id)
        .then(() => (this.isLoading = false));
    },
    updateCourse() {
      this.isUpdateCourse = true;
      const oldFormData = this.formData;
      const thumbnailUrl = oldFormData.thumbnailUrl;
      delete oldFormData.thumbnailUrl;
      oldFormData.thumbnail_url = thumbnailUrl;

      this.$store
        .dispatch(UPDATE_COURSE, oldFormData)
        .then(() => {
          this.notify('success', 'Se ha actualizado el curso con éxito');
          this.refetchCourse();
          this.isUpdateCourse = false;
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para actualizar el curso'),
        );
    },

    filesChange(files) {
      this.files = files;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.files[0]);
      const formData = new FormData();

      formData.append('id', this.formData.id);
      formData.append('file', this.files[0]);
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_COURSE_THUMBNAIL, formData)
        .then(() => {
          this.refetchCourse();
          this.notify('success', 'Se ha actualizado la imagen correctamente');
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar la imagen');
          this.isLoading = false;
        });
    },

    posterChange(files) {
      this.files = files;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.files[0]);
      const formData = new FormData();

      formData.append('id', this.formData.id);
      formData.append('file', this.files[0]);
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_COURSE_POSTER, formData)
        .then(() => {
          this.refetchCourse();
          this.notify('success', 'Se ha actualizado la imagen correctamente');
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar la imagen');
          this.isLoading = false;
        });
    },


    trailerPosterChange(files) {
      this.files = files;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.files[0]);
      const formData = new FormData();

      formData.append('id', this.formData.id);
      formData.append('file', this.files[0]);
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_TRAILER_COURSE_POSTER, formData)
        .then(() => {
          this.refetchCourse();
          this.notify('success', 'Se ha actualizado la imagen correctamente');
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar la imagen');
          this.isLoading = false;
        });
    },



    addBullet() {
      if (!this.bullet) {
        this.notify('danger', 'El campo bullet no puede estar vacio');
        return;
      }
      this.isLoadingCreateBullet = true;
      this.$store
        .dispatch(CREATE_COURSE_BULLET, {
          bullet: this.bullet,
          course_id: this.$route.params.id,
        })
        .then(() => {
          this.loadingCourseBullet = true;
          this.isLoadingCreateBullet = false;
          this.fetchCourseBullets();
          this.bullet = '';
          this.notify('success', 'El Bullet se ha creado correctamente');
        })
        .catch(() => {
          this.isLoadingCreateBullet = false;
          this.notify('danger', 'Tenemos problemas para crear el bullet');
        });
    },
    updateBullet({ bullet, id }) {
      if (!bullet) {
        this.notify('danger', 'El campo bullet no puede estar vacio');
      }

      this.isLoadingUpdateBullet = id;
      this.$store
        .dispatch(UPDATE_COURSE_BULLET, {
          bullet: bullet,
          id: id,
        })
        .then(() => {
          this.loadingCourseBullet = true;
          this.isLoadingUpdateBullet = undefined;
          this.fetchCourseBullets();
          this.notify('success', 'El Bullet se ha actualizado correctamente');
        })
        .catch(() => {
          this.isLoadingUpdateBullet = undefined;
          this.notify('danger', 'Tenemos problemas para actualizar el bullet');
        });
    },

    deleteBullet({ id }) {
      this.isLoadingDeleteBullet = id;
      this.$store
        .dispatch(DELETE_COURSE_BULLET, id)
        .then(() => {
          this.loadingCourseBullet = true;
          this.isLoadingDeleteBullet = undefined;
          this.fetchCourseBullets();
          this.notify('success', 'El Bullet se ha eliminado correctamente');
        })
        .catch(() => {
          this.isLoadingDeleteBullet = undefined;
          this.notify('danger', 'Tenemos problemas para eliminar el bullet');
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.course-url-col {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.course-slug {
  margin: 0 0 0 10px;
}

.course-slug .form-group {
  margin: 0;
}

.course-image {
  width: auto;
  height: auto;
  object-fit: cover;
}

.course-image-container {
  padding: 15px 20px;
  width: 100%;
}

.course-image-container > img {
  width: 100%;
}

.custom-radio .custom-control-input ~ .custom-control-label {
  padding-top: 1px;
}

.ql-container {
  height: 40vh;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.ql-editor:hover {
  box-shadow: none;
}

.col-md-9 .form-group input,
.input-usd {
  padding: 6px;
  padding-left: 12px !important;
  width: 100% !important;
}

.custom-file-label::after {
  background-color: #b7227e !important;
  color: white !important;
  border: 1px solid #b7227e !important;
  border-radius: 0 !important;
}

.base-radio {
  color: #000;
}

.style-text-config {
  font-size: 15px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.ql-editor {
  box-shadow: none !important;
  border-top: 1px solid #dee2e6;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: #000;
  border-radius: 50%;
}

.dropdwon-icon {
  margin-top: -34px;
}

.dropdwon-icon i {
  padding: 6px;
  background: white;
  margin-right: -5px;
}

.select-config .multiselect__select:before,
.multiselect__select:before {
  border: none;
  font-size: 16px !important;
}

.select-config .multiselect__select {
  transition: none;
  transform: scale(1);
}

.multiselect__tag {
  line-height: 20px !important;
  background-color: #b7227e;
  margin-top: 5px;
}

.multiselect__tag .multiselect__tag-icon:after {
  color: white;
}

.multiselect__tag .multiselect__tag-icon:hover {
  color: white;
  background-color: #b7227e;
}

.main-container__librery input.multiselect__input {
  padding-left: 0px;
  border: none !important;
}

.alert-notify {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  max-width: 600px;
  width: calc(100% - 30px);
  padding-right: 80px;
  color: rgba(255, 255, 255, 0.85);
}

.name-course-input input {
  width: 100% !important;
  padding-left: 10px !important;
}

.loading-course-detail {
  color: black;
}

.multiselect__option--highlight {
  background: black;
  outline: none;
  color: #fff;
}

.multiselect__option--highlight::after {
  background: black;
  outline: none;
  color: #000;
}

.select-status {
  height: 52px;
}

.multiselect--active .multiselect__select {
  -webkit-transform: none;
  transform: none;
}

.multiselect__select::before {
  top: 35%;
}

.multiselect__single {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 0.01em;
}
.multiselect__placeholder {
  padding-top: 0px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 0.01em;
}
.multiselect__tags {
  height: 52px;
  padding: 0px 40px 0px 13px !important;
  display: flex;
}

.multiselect__input,
.multiselect__tag {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  line-height: 20px !important;
}
.multiselect__input {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 30px;
}

.multiselect__select {
  height: 50px;
  width: 50px;
  margin-top: 0px !important;
  padding: 0px !important;
}

.main-container__librery input {
  background-color: #fff;
  border-radius: 3px;
}
.select-status {
  padding: 0px 10px 0px 10px !important;
  font-size: 16px !important;
}

.details-select {
  height: 30px !important;
  width: 40px !important;
  padding: 0px !important;
  margin-top: 10px !important;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 10px;
  font-size: 14px;
  text-align: center;
}
.fa-chevron-down:before {
  margin-top: 15px;
  font-size: 16px;
  margin-right: 5px;
}

.multiselect__select::before {
  top: 35% !important;
}

.main-container__librery .border-name input {
  border: 1px solid #ddd !important;
}
.multiselect__tag .multiselect__tag-icon:after {
  -ms-flex-line-pack: center;
  align-content: center;
  position: absolute;
  top: 10%;
  right: 30%;
}

.multiselect__content-wrapper {
  top: 100% !important;
  bottom: auto !important;
}

.multiselect__option--selected {
  background: #b5016d !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.multiselect--above .multiselect__content-wrapper {
  top: 100% !important;
  bottom: auto !important;
}

.option_trailer_video
{
  display: flex !important;
}

.option__image
{
  height: 50px !important;
}
.option__title
{
  padding-left: 10px;

}
.option__desc
{
  padding-top: 15px;
}
.multiselect__option
{
  display: flex;
}
.multiselect__option.multiselect__option--highlight::after
{
  background-color: transparent !important;
}
</style>
