const state = {
  path: {
    title: 'Maquillate',
    welcome: 'Maquillate',
  },
};

const getters = {
  getPath: (state) => state.path,
};

const actions = {
  setPath: ({ commit }, item) => {
    commit('setPath', item);
  },
};

const mutations = {
  setPath: (state, item) => {
    state.path = item;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
