import store from '@/store/index';
import jwtDecode from 'jwt-decode';
import { getTokenUserRoles } from './permission-utils';
import {
  FETCH_CURRENT_CLASS,
  FETCH_PUBLIC_COURSE,
} from '../store/actions/public';
import { FETCH_COURSE_USER } from '../store/actions/course';

/**
 * Check user when exists token.
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
export function checkAuthentication(to, from, next) {
  const urlParams = new URLSearchParams(window.location.search);
  const newToken = urlParams.get('token');

  if (newToken) {
    localStorage.setItem('user-token', newToken);
  }

  const token = store.getters.getToken;

  if (!token) return next();

  const tokenDecode = jwtDecode(token);
  let roles = [];

  if (tokenDecode && tokenDecode.user && tokenDecode.user.roles) {
    roles = getTokenUserRoles(tokenDecode.user.roles || []);
  }

  return roles.includes('admin')
    ? next({
        path: '/admin',
      })
    : next({
        path: '/user/dashboard',
      });
}

/**
 * Check permission for admin panel.
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
export function checkAdminAuthentication(to, from, next) {
  const token = store.getters.getToken;

  if (!token)
    return next({
      path: '/',
    });

  const tokenDecode = jwtDecode(token);
  let roles = [];

  if (tokenDecode && tokenDecode.user && tokenDecode.user.roles) {
    roles = getTokenUserRoles(tokenDecode.user.roles || []);
  }

  return roles.includes('admin') ? next() : next({ path: '/' });
}

/**
 * Check permission for user panel.
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
export function checkUserAuthentication(to, from, next) {
  const token = store.getters.getToken;

  if (!token)
    return next({
      path: '/',
    });

  const tokenDecode = jwtDecode(token);
  let roles = [];
  if (tokenDecode && tokenDecode.user && tokenDecode.user.roles) {
    roles = getTokenUserRoles(tokenDecode.user.roles || []);
  }

  return roles.includes('user') ? next() : next({ path: '/' });
}

/**
 * Check permission for user without authentication
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
export function checkWithOutAuthentication(to, from, next) {
  const token = store.getters.getToken;

  if (!token) return next();

  return next({
    path: '/',
  });
}

/**
 * Check access for page.
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
export function checkUserIsVerified(to, from, next) {
  const token = store.getters.getToken;

  if (!token)
    return next({
      path: '/',
    });

  const tokenDecode = jwtDecode(token);
  let roles = [];
  let user = undefined;

  if (tokenDecode && tokenDecode.user && tokenDecode.user.roles) {
    roles = getTokenUserRoles(tokenDecode.user.roles || []);
    user = tokenDecode.user;
  }

  if (user && !user.email_verified_at) return next();

  return roles.includes('admin')
    ? next({
        path: '/admin',
      })
    : next({
        path: '/user/dashboard',
      });
}

export async function checkAClass(to, from, next) {
  const currentClass = await store.dispatch(
    FETCH_CURRENT_CLASS,
    to.params.slug,
  );
  if (currentClass && currentClass.data && currentClass.data.id) {
    return next();
  }

  return next({ name: 'NotFound' });
}

export async function hasUserCourse(to, from, next) {
  const token = store.getters.getToken;

  if (token) {
    const tokenDecode = jwtDecode(token);
    const user = tokenDecode.user;

    try {
      const courseUser = await store.dispatch(FETCH_COURSE_USER, {
        course_id: to.params.id,
        user_id: user.id,
      });
      if (
        courseUser &&
        courseUser.course &&
        courseUser.course.course_videos &&
        courseUser.course.course_videos.length
      ) {
        return next({
          path: `/curso/${courseUser.course.id}/${courseUser.course.slug_name}/video/${courseUser.course.course_videos[0].id}`,
        });
      } else {
        return next();
      }
    } catch (e) {
      return next();
    }
  }

  return next();
}

export async function hasUserCourseVideo(to, from, next) {
  const token = store.getters.getToken;
  console.log('hasUserCourseVideo', to.params);

  if (token) {
    try {
      const course = await store.dispatch(FETCH_PUBLIC_COURSE, to.params.id);
      console.log('course', to.params);

      if (
        course &&
        course.data &&
        course.data.id &&
        course.data.course_videos &&
        course.data.course_videos.length
      ) {
        console.log('course_videos', course.data.course_videos);

        const courseVideo = course.data.course_videos.find(
          (course_video) =>
            course_video.id === parseInt(to.params.course_video_id),
        );
        console.log('courseVideo', courseVideo);
        if (courseVideo) return next();
        return next({ name: 'NotFound' });
      } else {
        return next({ name: 'NotFound' });
      }
    } catch (e) {
      return next({ name: 'NotFound' });
    }
  }

  return next({ path: `/cursos/curso/${to.params.id}/${to.params.name}` });
}
