import axios from 'axios';
import config from '@/config/config';

import {
  FETCH_INSTRUCTORS,
  CREATE_INSTRUCTOR,
  UPDATE_INSTRUCTOR,
  DELETE_INSTRUCTOR,
  FETCH_INSTRUCTOR,
  DELETE_INSTRUCTOR_COURSE,
  UPDATE_INSTRUCTOR_FEATURE,
  DELETE_INSTRUCTOR_CLASS,
} from '../actions/instructor';

const state = {
  instructor: undefined,
  instructors: {
    total: 0,
    items: [],
  },
};

const getters = {
  getInstructors: (state) => state.instructors,
  getInstructor: (state) => state.instructor,
};

const actions = {
  [FETCH_INSTRUCTORS]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/course/instructor/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            ...filter,
          },
        })
        .then((resp) => {
          commit(FETCH_INSTRUCTORS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_INSTRUCTOR]: ({ commit }, { id, search_course }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/course/instructor/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            search_course,
          },
        })
        .then((resp) => {
          resolve(resp);
          commit(FETCH_INSTRUCTOR, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [CREATE_INSTRUCTOR]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/course/instructor/create`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [UPDATE_INSTRUCTOR]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/course/instructor/update`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [DELETE_INSTRUCTOR]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.data.apiUrl}/admin/course/instructor/delete/${id}`, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [DELETE_INSTRUCTOR_COURSE]: (_, { instructorId, courseId }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${config.data.apiUrl}/admin/course/instructor/${instructorId}/course/${courseId}/delete`,
          {
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [DELETE_INSTRUCTOR_CLASS]: (_, { instructorId, classId }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${config.data.apiUrl}/admin/course/instructor/${instructorId}/class/${classId}/delete`,
          {
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [UPDATE_INSTRUCTOR_FEATURE]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${config.data.apiUrl}/admin/course/instructor/update/featured`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp.data);
        })
        .then((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [FETCH_INSTRUCTORS]: (state, instructors) => {
    state.instructors = {
      total: instructors && instructors.total ? instructors.total : 0,
      items: instructors && instructors.data ? instructors.data : [],
    };
  },
  [FETCH_INSTRUCTOR]: (state, instructor) => {
    state.instructor = {
      ...instructor,
      courses: (instructor.courses || []).filter(({ course }) => course),
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
