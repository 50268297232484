export const STRIPE_SETUP_INTENT = 'STRIPE_SETUP_INTENT';
export const STRIPE_SETUP_SUCCESS = 'STRIPE_SETUP_SUCCESS';
export const STRIPE_GENERATE_PLAID_TOKEN = 'STRIPE_GENERATE_PLAID_TOKEN';
export const STRIPE_PLAID_GET_AUTH = 'STRIPE_PLAID_GET_AUTH';
export const STRIPE_PLAID_NEW_CHARGE = 'STRIPE_PLAID_NEW_CHARGE';
export const STRIPE_PLAID_NEW_ACCOUNT = 'STRIPE_PLAID_NEW_ACCOUNT';
export const STRIPE_PLAID_REGISTER_BANK_ACCOUNT =
  'STRIPE_PLAID_REGISTER_BANK_ACCOUNT';
export const STRIPE_PLIAD_DELETE_BANK_ACCOUNT =
  'STRIPE_PLIAD_DELETE_BANK_ACCOUNT';
export const STRIPE_NEW_CHARGE = 'STRIPE_NEW_CHARGE';
