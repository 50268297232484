<template>
  <div class="VuePassword">
    <div class="VuePassword__Container">
      <!-- <div class="input-group-prepend"><span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span></div> -->
      <slot
        name="password-input"
        :strength="this.strength"
        :type="type"
        :updatePassword="updatePassword"
        :value="value"
      >
        <input
          ref="input"
          :class="[
            classes,
            'VuePassword__Input',
            { 'VuePassword__Input--has-toggle': !disableToggle },
          ]"
          :title="strengthMessage"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
        />
      </slot>
      <slot v-if="!disableToggle" name="password-toggle">
        <button
          class="VuePassword__Toggle"
          :title="toggleMessage"
          type="button"
          @click="togglePassword"
        >
          <slot name="password-hide" v-if="this.type === 'text'">
            <svg
              class="VuePassword__Toggle-Icon VuePassword__Toggle-Icon--hide"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
              />
            </svg>
          </slot>
          <slot name="password-show" v-else>
            <svg
              class="VuePassword__Toggle-Icon VuePassword__Toggle-Icon--show"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
              />
            </svg>
          </slot>
        </button>
      </slot>
    </div>

    <slot
      v-if="!disableStrength"
      name="strength-meter"
      :strength="this.strength"
      :strength-class="strengthClass"
      :strength-classes="strengthClasses"
      :strength-message="strengthMessage"
      :strength-messages="strengthMessages"
    >
      <div class="VuePassword__Meter" :title="strengthMessage">
        <svg
          v-for="i in 4"
          :key="i"
          :class="i <= strength ? strengthClass : ''"
          preserveAspectRatio="none"
          :data-strength="i"
          viewBox="0 0 2 1"
        >
          <rect width="100%" height="100%"></rect>
        </svg>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      type: this.$attrs.type,
    };
  },
  props: {
    classes: {
      type: [Object, Array, String],
    },
    disableStrength: Boolean,
    disableToggle: Boolean,
    strength: {
      type: Number,
      default: 0,
    },
    /**
     * Classes to apply for the various strength levels.
     */
    strengthClasses: {
      type: Array,
      default() {
        return [
          'VuePassword--very-weak',
          'VuePassword--weak',
          'VuePassword--medium',
          'VuePassword--good',
          'VuePassword--great',
        ];
      },
    },
    /**
     * Messages for the password strength values.
     */
    strengthMessages: {
      type: Array,
      default() {
        return [
          'Very Weak Password',
          'Weak Password',
          'Medium Password',
          'Strong Password',
          'Very Strong Password',
        ];
      },
    },
    value: String,
  },
  computed: {
    inputElement() {
      if (this.$refs.input) {
        return this.$refs.input;
      }
      if (this.$el) {
        return this.$el.querySelector('.VuePassword__Container input');
      }
      return null;
    },
    listeners() {
      return Object.assign({}, this.$listeners, { input: this.updatePassword });
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    strengthClass() {
      return (
        Array.isArray(this.strengthClasses) &&
        this.strengthClasses[this.strength]
      );
    },
    strengthMessage() {
      if (this.disableStrength) {
        return false;
      }
      return (
        Array.isArray(this.strengthMessages) &&
        this.strengthMessages[this.strength]
      );
    },
    toggleMessage() {
      return this.type === 'password' ? 'Show Password' : 'Hide Password';
    },
  },
  watch: {
    strength(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('strength-updated', newValue);
      }
    },
  },
  mounted() {
    if (!this.$attrs.type) {
      this.togglePassword(false);
      console.log('hola');
    }
    console.log(this.$attrs.type); //password
  },
  methods: {
    /**
     * Get the current strength class based on the current strength score.
     *
     * @param  {Number} strength
     * @return {String}
     */
    getStrengthClass(strength) {
      if (this.strength > strength) {
        return this.strengthClasses[strength];
      }
      return '';
    },
    /**
     * Toggle the visibilty of the password.
     */
    togglePassword(focus = true) {
      this.type = this.type === 'password' ? 'password' : 'text';
      this.inputElement.setAttribute('type', this.type);

      if (focus) {
        this.inputElement.focus();
      }
      console.log('Ejecuto togglePassword');
    },
    /**
     * Update the password input.
     *
     * @param  {String} password
     */
    updatePassword(event) {
      this.model = event.target.value;
    },
  },
};
</script>
