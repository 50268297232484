import axios from 'axios';
import config from '@/config/config';

import {
  PARTNER_GET_ALL_COURSES,
  PARTNER_GET_BY_PARTNER,
  GET_PARTNER_COURSES,
  UPDATE_PARTNER,
  DELETE_PARTNER,
  PARTNER_GET_COURSE_BY_PARTNET_AND_COURSE,
} from '../actions/partner';

const state = {
  courses: [],
  ordersPartner: [],
  partnerCourses: [],
};

const getters = {
  getPartnerCoursess: (state) => state.courses,
  getPartnerOrders: (state) => state.ordersPartner,
  getPartnerCourses: (state) => state.partnerCourses,
};

const actions = {
  [PARTNER_GET_ALL_COURSES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/partner/courses/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(PARTNER_GET_ALL_COURSES, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PARTNER_GET_BY_PARTNER]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/partner/courses/getByPartner', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          },
        })
        .then(({ data }) => {
          commit(PARTNER_GET_BY_PARTNER, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_PARTNER_COURSES]: ({ commit }, partner) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/admin/partner/getPartner/${partner.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          },
        })
        .then(({ data }) => {
          commit(GET_PARTNER_COURSES, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_PARTNER]: ({ formData }) => {
    // console.log(partner)
    // return
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + `/admin/partner/update`, formData, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_PARTNER]: (partner) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/admin/partner/delete/` + partner.id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PARTNER_GET_COURSE_BY_PARTNET_AND_COURSE]: (dataObject) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + `/partner/courses/getByPartnerAndCourse`,
          {
            dataObject: dataObject,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'),
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [PARTNER_GET_ALL_COURSES]: (state, resp) => {
    state.courses = resp.data;
  },
  [PARTNER_GET_BY_PARTNER]: (state, resp) => {
    state.ordersPartner = resp;
  },
  [GET_PARTNER_COURSES]: (state, resp) => {
    state.partnerCourses = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
