export const PAYPAL_AUTHORIZE_LINK = 'PAYPAL_AUTHORIZE_LINK';
export const PAYPAL_CHECKOUT_SUCCESS = 'PAYPAL_CHECKOUT_SUCCESS';

export const PAYPAL_UPDATE_DEFAULT_BILLING = 'PAYPAL_UPDATE_DEFAULT_BILLING';
export const PAYPAL_HANDLE_PAYMENT = 'PAYPAL_HANDLE_PAYMENT';

export const PAYPAL_DELETE_ACCOUNT = 'PAYPAL_DELETE_ACCOUNT';

export const PAYPAL_CLIENT_TOKEN = 'PAYPAL_CLIENT_TOKEN';

export const PAYPAL_CREATE_ORDER = 'PAYPAL_CREATE_ORDER';

export const PAYPAL_ORDER_CAPTURE = 'PAYPAL_ORDER_CAPTURE';
