<template>
  <div class="auth-layout-container bg-default">
    <div class="main-content login-app">
      <div class="auth-super-container container" style="height: 90vh">
        <div class="row justify-content-center">
          <div class="xs-size">
            <div class="auth-container">
              <div class="auth-container__logo">
                <router-link :to="'/'">
                  <img
                    :src="
                      require(`@/assets/images/logo/logo-maquillate-white.png`)
                    "
                    alt="Maquillate logo"
                    class=""
                  />
                </router-link>
              </div>
              <div class="container mt-4">
                <div class="text-center">
                  <div class="row flex-column">
                    <div class="col-12">
                      <h1 style="color: white">Ops!</h1>
                      <h1 style="color: white">
                        La página que estás intentando visitar no existe.
                      </h1>
                    </div>
                    <div class="col-12 mt-4">
                      <router-link
                        class="btn-maquillate btn-notfoundpage rounded mt-3"
                        style="color: white !important"
                        to="/explorar"
                      >
                        DESCUBRE NUEVAS CLASES
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFoundPage',
};
</script>
