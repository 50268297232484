var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"square-list"},_vm._l((_vm.count),function(i){return _c('div',{key:i,staticClass:"square",style:({
      backgroundColor: _vm.backColor,
      width: _vm.boxProperties.width ? _vm.boxProperties.width : '100%',
      height: _vm.boxProperties.height ? _vm.boxProperties.height : '16px',
      marginTop: _vm.boxProperties.top ? _vm.boxProperties.top : '0',
      marginBottom: _vm.boxProperties.bottom ? _vm.boxProperties.bottom : '0',
    })})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }