import axios from 'axios';
import {
  MEDIA_CREATE_CATEGORY,
  MEDIA_GET_CATEGORIES,
  MEDIA_CREATE_FILE,
  MEDIA_CREATE_FILE_VIDEO,
  MEDIA_UPLOAD_FILE,
  MEDIA_GET_FILES,
  MEDIA_UPDATE_FILE,
  MEDIA_DELETE_FILE,
  MEDIA_DELETE_FILE_VIDEO,
  MEDIA_ASSIGN_CATEGORY_TO_FILE,
  MEDIA_GET_CATEGORY,
  MEDIA_DELETE_CATEGORY,
  MEDIA_SHARE_FILE_TO_PARTNERS,
  MEDIA_GET_PARTNERS_BY_FILE,
  MEDIA_CATEGORY_UPDATE_NAME,
  MEDIA_SHARE_CATEGORY_WITH_FILES,
  MEDIA_DELETE_FILE_IN_CDN,
  MEDIA_GET_PARTNERS_BY_CATEGORIE,
  MEDIA_GET_PARTNERS_BY_CATEGORIE_TO_UPDATE,
} from '../actions/media';

import config from '@/config/config';

const state = {
  categories: [],
  files: [],
};

const getters = {
  getMediaCategories: (state) => state.categories,
  getMediaFiles: (state) => state.files,
};

const actions = {
  [MEDIA_GET_FILES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/media/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(MEDIA_GET_FILES, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_CREATE_CATEGORY]: ({ dispatch }, media) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/media/category/create',
          {
            title: media.title,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(MEDIA_GET_CATEGORIES);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_DELETE_CATEGORY]: ({ dispatch }, category) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          config.data.apiUrl +
            '/media/category/' +
            category.id +
            '?delete_files=' +
            category.deleteFile,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(MEDIA_GET_FILES);
          dispatch(MEDIA_GET_CATEGORIES);

          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_GET_CATEGORIES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/media/category', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(MEDIA_GET_CATEGORIES, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_GET_CATEGORY]: (category) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/media/category/' + category.id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_DELETE_FILE_IN_CDN]: (fileToDelete) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.data.apiUrl}/media/cdn/delete`,
          { file: JSON.stringify(fileToDelete) },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('user-token'),
              'Cache-Control': '',
            },
          },
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  [MEDIA_UPLOAD_FILE]: (formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/media/upload`, formData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
            'Cache-Control': '',
          },
        })
        .then((res) => {
          resolve(res.data.file);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_CREATE_FILE]: ({ dispatch }, fileToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/media/create',
          { file: JSON.stringify(fileToSend) },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(MEDIA_GET_FILES);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_CREATE_FILE_VIDEO]: ({ dispatch }, fileToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/media/create',
          { file: JSON.stringify(fileToSend) },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'Access-Control-Allow-Origin': '*',
              video: null,
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(MEDIA_GET_FILES);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_UPDATE_FILE]: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          config.data.apiUrl + '/media/update/' + data.id,
          {
            file: JSON.stringify(data),
          },
          {
            headers: {
              'Content-Type': 'aplication/json',
              Accept: 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(MEDIA_GET_FILES);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_DELETE_FILE]: ({ dispatch }, file) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + '/media/delete/' + file.id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          dispatch(MEDIA_GET_FILES);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_DELETE_FILE_VIDEO]: ({ dispatch }, file) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + '/media/delete/videoResource/' + file.id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          dispatch(MEDIA_GET_FILES);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_ASSIGN_CATEGORY_TO_FILE]: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/media/category/attach/file',
          {
            media_id: data.media_id,
            category_id: data.category_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          dispatch(MEDIA_GET_FILES);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_SHARE_FILE_TO_PARTNERS]: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + `/media/file/share/${data.file_id}`,
          {
            ids: `[${data.ids}]`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((res) => {
          dispatch(MEDIA_GET_FILES);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_GET_PARTNERS_BY_FILE]: ({ dispatch }, file) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/media/file/partners/${file}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((res) => {
          dispatch(MEDIA_GET_FILES);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  [MEDIA_CATEGORY_UPDATE_NAME]: ({ dispatch }, category) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          config.data.apiUrl + `/media/category/${category.id}`,
          {
            title: category.title,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((res) => {
          dispatch(MEDIA_GET_CATEGORIES);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  [MEDIA_SHARE_CATEGORY_WITH_FILES]: (category) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + `/media/category/share/${category.fileId}`,
          {
            ids: `[${category.ids}]`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_GET_PARTNERS_BY_CATEGORIE]: ({ dispatch }, category) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/media/category/partners/${category}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then(({ data }) => {
          const usersIds = [];
          data.forEach(({ id }) => usersIds.push(id));
          const dataUsers = { fileId: category, ids: usersIds };
          dispatch(MEDIA_SHARE_CATEGORY_WITH_FILES, dataUsers);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MEDIA_GET_PARTNERS_BY_CATEGORIE_TO_UPDATE]: (category) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + `/media/category/partners/${category}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [MEDIA_GET_CATEGORIES]: (state, resp) => {
    state.categories = resp.data;
  },
  [MEDIA_GET_FILES]: (state, resp) => {
    state.files = resp.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
