export const CREDIT_CARD_NUMBER_MESSAGE_ERROR =
  'El campo de la tarjeta es obligatorio';
export const CREDIT_CARD_HOLDER_NAME_MESSAGE_ERROR =
  'El campo de nombre es obligatorio';
export const CREDIT_CVV_MESSAGE_ERROR = 'El campo cvc es obligatorio';
export const CREDIT_EXPIRED_DATE_MESSAGE_ERROR =
  'El campo de caducidad es obligatorio';
export const CREDIT_CARD_EMAIL_MESSAGE_ERROR =
  'El campo de correo electrónico es obligatorio';
export const CREDIT_CARD_CITY = 'El campo ciudad es obligatorio';
export const CREDIT_CARD_COUNTRY = 'El campo País es obligatorio';
export const CREDIT_CARD_STATE = 'El campo Estado es obligatorio';
export const CREDIT_CARD_POSTAL_CODE = 'El campo de código es obligatorio';

export const PAYPAL_METHOD = 'PAYPAL_METHOD';
export const PAYPAL_CREDIT_CARD_METHOD = 'PAYPAL_CREDIT_CARD_METHOD';

export const USER_STATUS_UNVERIFIED = 'UNVERIFIED';
export const USER_STATUS_ACTIVE = 'ACTIVE';
export const USER_STATUS_INACTIVE = 'INACTIVE';

export const COURSE_VIDEO_STATUS_QUEUED = 'QUEUED';
export const COURSE_VIDEO_STATUS_PROCESSING = 'PROCESSING';
export const COURSE_VIDEO_STATUS_FINISHED = 'FINISHED';
export const COURSE_VIDEO_STATUS_FAILED = 'FAILED';

export const DEFAULT_CARD_FORM_VALUES = {
  cardHolderName: '',
  number: '',
  cvc: '',
  expiredDate: '',
  city: '',
  state: '',
  postalCode: '',
  id: '',
  country: '',
  address: '',
};

export const DEFAULT_VIDEO_VALUES = {
  url: '',
  title: '',
  extension: '',
  hashName: '',
  size: 0,
  avaible: 0,
  duration: 0,
  thumbnail: '',
  content: null,
  captions: null,
  createdAt: '',
  updatedAt: '',
  id: '',
};

export const DEFAULT_COURSE_VIDEO_VALUES = {
  id: '',
  createdAt: '',
  updatedAt: '',
  title: '',
  position: 0,
  content: '',
  slug: '',
  featured: 0,
  isPublic: '0',
  video: undefined,
  poster: undefined,
  files: [],
  products: [],
};

export const DEFAULT_COURSE_VALUES = {
  id: '',
  updatedAt: '',
  name: '',
  price: 0,
  thumbnailUrl: '',
  courseUrl: '',
  content: '',
  minContent: '',
  status: '',
  slugName: '',
  featured: 0,
  categories: [],
  instructors: [],
  bullets: [],
  free: '0',
  trailer: {},
  seo: undefined,
};

export const CHECKOUT_FIRST_STEP = 'CHECKOUT_FIRST_STEP';
export const CHECKOUT_SECOND_STEP = 'CHECKOUT_SECOND_STEP';

export const DEFAULT_COURSE_CATEGORY = {
  title: '',
  url: '',
  categoryFile: {
    url: '',
  },
  categoryIconFile: {
    url: '',
  },
  categorySeoFile: {
    url: '',
  },
  seoTitle: '',
  seoDescription: '',
};

export const USER_INVOICES_BY_USER_NAME = 'UserOrdersByUser';

export const USER_INVOICES_BY_USER_TITLE = 'Facturas';
//Course - Courses
export const COURSE_ROUTE_NAME = 'Admin Courses';
export const COURSE_DETAIL_NAME = 'Edit Course';
export const ADVERTISEMENT_NAME = 'Advertisement';

export const CLASS_NAME = 'Classes';
export const CLASS_DETAILS_NAME = 'ClassDetails';
export const CLASS_TITLE = 'Clases';

export const CLASS_SUBTITLE = 'Lista de clases';
export const ADVERTISEMENT_TITLE = 'Anuncios';
export const ADVERTISEMENT_SUBTITLE = 'Lista de anuncios';

export const ADMIN_COURSE_TITLE = 'Cursos';
export const ADMIN_COURSE_SUBTITLE = 'Lista de cursos';

//Course - Instructors
export const INSTRUCTOR_ROUTE_NAME = 'Course Instructors';

export const ADMIN_COURSE_INSTRUCTOR_TITLE = 'Cursos';
export const ADMIN_COURSE_INSTRUCTOR_SUBTITLE = 'Instructores';

//Instructors - Courses
export const INSTRUCTOR_COURSE_ROUTE_NAME = 'Course Instructor Courses';

export const ADMIN_COURSE_INSTRUCTOR_COURSES_TITLE = 'Instructores';
export const ADMIN_COURSE_INSTRUCTOR_COURSES_SUBTITLE = 'Cursos';

//Instructors - Classes
export const INSTRUCTOR_CLASS_ROUTE_NAME = 'Course Instructor Classes';

export const ADMIN_COURSE_INSTRUCTOR_CLASSES_TITLE = 'Instructores';
export const ADMIN_COURSE_INSTRUCTOR_CLASSES_SUBTITLE = 'Clases';

//Course - Categories
export const COURSE_CATEGORY_ROUTE_NAME = 'Categories';

export const ADMIN_COURSE_CATEGORY_TITLE = 'Cursos';
export const ADMIN_COURSE_CATEGORY_SUBTITLE = 'Categorías';

//Course - Videos
export const COURSE_VIDEOS_ROUTE_NAME = 'Videos';

export const ADMIN_COURSE_VIDEOS_TITLE = 'Cursos';
export const ADMIN_COURSE_VIDEOS_SUBTITLE = 'Librería de Videos';

//Actives
export const ACTIVE_ROUTE_NAME = 'Media';

export const ADMIN_ACTIVE_TITLE = 'Activos';
export const ADMIN_ACTIVE_SUBTITLE = 'Librería de Medios';

//Users
export const USERS_ROUTE_NAME = 'ListUser';

export const ADMIN_USERS_TITLE = 'Usuarios';
export const ADMIN_USERS_SUBTITLE = 'Lista de usuarios';

//Edit Courses
export const EDIT_COURSE_ROUTE_NAME = 'Edit Course';

export const EDIT_COURSE_TITLE = 'Cursos';
export const EDIT_COURSE_SUBTITLE = 'Lista de Cursos';

//Edit Media
export const MEDIA_ROUTE_NAME = 'Libreria';

export const MEDIA_TITLE = 'Productos';
export const MEDIA_SUBTITLE = 'Libreria';

//Products- Categories
export const ADMIN_PRODUCT_CATEGORY_ROUTE_NAME = 'Categoria';

export const ADMIN_PRODUCT_CATEGORY_TITLE = 'Productos';
export const ADMIN_PRODUCT_CATEGORY_SUBTITLE = 'Categorías';

//Products- Categories Courses
export const ADMIN_COURSE_CATEGORY_COURSE_ROUTE_NAME =
  'Course Category Courses';

export const ADMIN_COURSE_CATEGORY_COURSE_TITLE = 'Categorías';
export const ADMIN_COURSE_CATEGORY_COURSE_SUBTITLE = 'Cursos Asignados';

//Products- Categories Classes
export const ADMIN_COURSE_CATEGORY_CLASS_ROUTE_NAME =
  'Course Category Courses Classes';
export const ADMIN_COURSE_CATEGORY_CLASS_TITLE = 'Categorías';
export const ADMIN_COURSE_CATEGORY_CLASS_SUBTITLE = 'Clases Asignadas';

//Coupons- Coupons
export const ADMIN_COUPONS_ROUTE_NAME = 'Coupons';

export const ADMIN_COUPONS_TITLE = 'Cupones';
export const ADMIN_COUPONS_SUBTITLE = 'Cupones';

//Partners- Parterns List
export const ADMIN_PARTNERS_ROUTE_NAME = 'Partners';

export const ADMIN_PARTNERS_TITLE = 'Socios';
export const ADMIN_PARTNERS_SUBTITLE = 'Lista de socios';

//Partners- Parterns Payment
export const ADMIN_PARTNERSPAYMENT_ROUTE_NAME = 'PartnersPayment';

export const ADMIN_PARTNERSPAYMENT_TITLE = 'Socios';
export const ADMIN_PARTNERSPAYMENT_SUBTITLE = 'Pagos';

//Users - Invoices
export const ADMIN_USER_INVOICE_ROUTE_NAME = 'Invoices';

export const ADMIN_USER_INVOICE_TITLE = 'Usuarios';
export const ADMIN_USER_INVOICE_SUBTITLE = 'Facturas';

//Reports - Dashboard
export const ADMIN_REPORTS_ROUTE_NAME = 'Reportes';

export const ADMIN_REPORTS_TITLE = 'Reportes';
export const ADMIN_REPORTS_SUBTITLE = 'Tablero';

//Settings - My Account
export const ADMIN_USER_SETTING_ROUTE_NAME = 'Admin Settings';

export const ADMIN_USER_SETTING_TITLE = 'Configuración';
export const ADMIN_USER_SETTING_SUBTITLE = 'Mi Cuenta';

//Email Marketing Campaign
export const MARKETING_EMAIL_CAMPAIGN_ROUTE_NAME = 'Email Campaigns Marketing';

export const MARKETING_EMAIL_CAMPAIGN_ROUTE_TITLE = 'Mercadeo';
export const MARKETING_EMAIL_CAMPAIGN_ROUTE_SUBTITLE = 'Email - Campaña';

//Email Marketing Segment
export const MARKETING_EMAIL_SEGMENT_ROUTE_NAME = 'Email Segments Marketing';

export const MARKETING_EMAIL_SEGMENT_ROUTE_TITLE = 'Mercadeo';
export const MARKETING_EMAIL_SEGMENT_ROUTE_SUBTITLE = 'Email - Segmento';

//Email Marketing Sequence
export const MARKETING_EMAIL_SEQUENCE_ROUTE_NAME = 'Email Sequence Marketing';

export const MARKETING_EMAIL_SEQUENCE_ROUTE_TITLE = 'Mercadeo';
export const MARKETING_EMAIL_SEQUENCE_ROUTE_SUBTITLE = 'Email - Secuencias';

//Settings - My Account Reports
export const ADMIN_USER_SETTING_REPORTS_ROUTE_NAME_OPTION_ACCOUNT = 'cuenta';
export const ADMIN_USER_SETTING_REPORTS_ROUTE_NAME_OPTION_REPORTS = 'reportes';

export const ADMIN_USER_SETTING_REPORTS_TITLE = 'Configuración';
export const ADMIN_USER_SETTING_REPORTS_SUBTITLE_OPTION_ACCOUNT = 'Mi cuenta';
export const ADMIN_USER_SETTING_REPORTS_SUBTITLE_OPTION_REPORTS = 'Reportes';

export const DASHBOARD_NAME = 'Dashboard';
export const DASHBOARD_TITLE = 'Tablero';
export const DASHBOARD_SUBTITLE = 'Inicio';

export const RANGED_LAST = 'last';
export const USER_METRIC = 'USER_METRIC';
export const PROFIT_METRIC = 'PROFIT_METRIC';

export const CLASS_DEFAULT_VALUES = {
  id: '',
  title: '',
  slug: '',
  content: '',
  status: 'DRAFT',
  featured: 0,
  video: undefined,
  instructors: [],
  categories: [],
  products: [],
  poster: {
    url: '',
  },
};

export const CLASS_POPULAR_VIEW = 'ClassPopular';
export const CLASS_FEATURED_VIEW = 'ClassFeatured';
export const COURSES_VIEW = 'CoursesView';
export const CLASS_TREND_VIEW = 'ClassTrend';
export const CLASS_NEW_VIEW = 'ClassNewView';
