import axios from 'axios';
import {
  USER_REQUEST,
  USER_CONTACT,
  USER_DELETE,
  USER_LOGOUT,
  USER_REQUEST_SETUP_INTENT,
  USER_REGISTER_NEW_PAYMENT_METHOD,
  USER_DELETE_PAYMENT_METHOD,
  USER_UPDATE_CARD_PAYMENT_METHOD,
  USER_UPDATE_PAYMENT_METHOD,
  USER_GET_INVOICES,
  USER_DOWNLOAD_INVOICE,
  USER_UPDATE_DEFAULT_PAYMENT_METHOD,
  USER_CHANGE_ACH_DATA,
  USER_UPDATE_BANK_ACCOUNTS,
  USER_UPDATE_DEFAULT_BANK_ACCOUNT,
  DELETE_DEFAULT_ACH,
  USER_GET_PARTNERS,
  USER_INVITATION_STATUS,
  USER_UPDATE_PAYPAL_ACCOUNTS,
  UPDATE_USER,
  DELETE_USER,
  //
  NOTIFICATION_USER_GET,
  NOTIFICATION_USER_SET,
  SEND_EMAIL_HELP,
  USER_IS_AUTHENTICATE,
  AUTH_TOKEN,
  USER_REQUEST_ERROR,
  USER_COURSES,
  USER_COURSE_VIDEO_FAVORITE,
  USER_COURSE_VIDEO_PROGRESS,
  USER_COURSE_VIDEO_COMPLETED,
  UPDATE_USER_AVATAR,
  UPDATE_USER_EMAIL,
  UPDATE_USER_PASSWORD,
  FETCH_USERS,
  UPDATE_USER_NOTIFICATION,
  FETCH_USER_INVOICES_BY_USER,
  FETCH_ADMIN_USER_INVOICES,
  FETCH_USER_CLASS_PROGRESS,
  FETCH_USER_CLASS_COMPLETED,
  USER_CLASS_FAVORITE,
  FETCH_USER_NOTIFICATION,
  DELETE_USER_NOTIFICATION_DASHBOARD,
} from '../actions/user';
import { AUTH_LOGOUT } from '../actions/login';
import config from '@/config/config';

import { STRIPE_SETUP_INTENT } from '../actions/stripe';
import { getAccessToken } from '../utils/user';

const state = {
  profile: undefined,
  token: getAccessToken(),
  isAuthenticated: false,
  updateStatus: '',
  invoices: {},
  partners: {},
  invitations: {},
  notifications: null,

  error: null,
  users: {
    items: [],
    total: 0,
  },
  courses: [],
  favorites: {
    items: [],
    count: 0,
  },
  favoriteClasses: {
    items: [],
    count: 0,
  },
  progress: [],
  completed: [],
  userInvoicesByUser: [],
  userInvoices: {
    items: [],
    total: 0,
  },
  progressClass: [],
  completeClass: {
    items: [],
    total: 0,
  },
  userNotification: undefined,
};

const getters = {
  getToken: (state) => state.token,
  getProfile: (state) => state.profile,
  getUserCurses: (state) =>
    state.profile && state.profile.courses
      ? state.profile.courses.map(({ course }) => course)
      : [],
  isAuthenticated: (state) => state.isAuthenticated,
  getUserInvoicesByUser: (state) => state.userInvoicesByUser,
  getUserInvoices: (state) => state.userInvoices,

  getUserRequestError: (state) => state.error,
  getInvoices: (state) => state.invoices,
  getPartners: (state) => state.partners,
  getInvitations: (state) => state.invitations,
  getUsers: (state) => state.users,
  getNotificationsState: (state) => state.notifications,
  getUserCourses: (state) => state.courses,

  getUserFavorites: (state) => state.favorites,
  getUserFavoriteClasses: (state) => state.favoriteClasses,
  getUserProgress: (state) => state.progress,
  getUserCompleted: (state) => state.completed,
  getUserRoles: (state) =>
    state.profile && state.profile.roles && state.profile.roles.length
      ? state.profile.roles
      : [],
  getUserProgressClass: (state) => state.progressClass,
  getUserCompletedClass: (state) => state.completeClass,
  getUserNotification: (state) => state.userNotification,
};

const actions = {
  [AUTH_TOKEN]: ({ commit }, token) => {
    commit(AUTH_TOKEN, token);
  },
  [USER_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/me', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          // dispatch(NOTIFICATION_GET_RECENTS_NOTIFICATIONS);
          commit(USER_IS_AUTHENTICATE);
          commit(USER_REQUEST, resp.data);
          commit(USER_REQUEST_ERROR, resp);
          resolve(resp.data);
        })
        .catch((err) => {
          commit(USER_REQUEST_ERROR, err.response);
          reject(err);
        });
    });
  },

  [FETCH_USER_NOTIFICATION]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/notification/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(FETCH_USER_NOTIFICATION, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_USER_NOTIFICATION_DASHBOARD]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + `/user/notification/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  [USER_COURSES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/course/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(USER_COURSES, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [USER_COURSE_VIDEO_FAVORITE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/course/video/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(USER_COURSE_VIDEO_FAVORITE, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_COURSE_VIDEO_PROGRESS]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/course/video/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(USER_COURSE_VIDEO_PROGRESS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_USER_CLASS_PROGRESS]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/class/video', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(FETCH_USER_CLASS_PROGRESS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_USER_CLASS_COMPLETED]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/class/video', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(FETCH_USER_CLASS_COMPLETED, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_CLASS_FAVORITE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/class/video', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(USER_CLASS_FAVORITE, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_COURSE_VIDEO_COMPLETED]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/course/video/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            ...data,
          },
        })
        .then((resp) => {
          commit(USER_COURSE_VIDEO_COMPLETED, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [UPDATE_USER_AVATAR]: (_, formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/user/file/update`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((resp) => {
          reject(resp);
        });
    });
  },

  [UPDATE_USER]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/user/update', data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          // TODO: check notifications
          //
          // dispatch(NOTIFICATION_NEW_NOTIFICATION, {
          //   message: 'Se ha actualizado tu información personal',
          //   title: 'Perfil',
          //   user_id: getters.getProfile.id,
          // });

          resolve(resp);
        })
        .catch((resp) => {
          reject(resp);
        });
    });
  },
  [UPDATE_USER_EMAIL]: (_, user) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/update/email',
          {
            email: user.email,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((resp) => {
          reject(resp);
        });
    });
  },

  [UPDATE_USER_NOTIFICATION]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/user/setting/notification/update', data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  [FETCH_USER_INVOICES_BY_USER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/user/${params.id}/invoices`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_USER_INVOICES_BY_USER, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_ADMIN_USER_INVOICES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/user/invoices`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_ADMIN_USER_INVOICES, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // TODO: CHECK THIS

  [NOTIFICATION_USER_GET]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/get/notifications/state', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(NOTIFICATION_USER_GET, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [NOTIFICATION_USER_SET]: ({ commit }, { user_id, condition }) => {
    if (condition) {
      state.notifications = 'true';
    } else {
      state.notifications = 'false';
    }
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl +
            `/user/update/notifications/state/${user_id}/${condition}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          commit(NOTIFICATION_USER_GET, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SEND_EMAIL_HELP]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/user/send/email/help', data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          },
        })

        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /*
   * User Data section
   */

  [USER_CONTACT]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/contact',
          {
            dataObject: {
              content: data.content,
              name: data.name,
              email: data.email,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        )
        .then((resp) => {
          // console.log(resp)
          resolve(resp);
        })
        .catch((err) => {
          // console.log(err)
          reject(err);
        });
      // console.log(resp);
    });
  },
  [UPDATE_USER_PASSWORD]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/user/update/password', data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          // dispatch(NOTIFICATION_NEW_NOTIFICATION, {
          //   message: 'Recientemente se ha hecho un cambio de contraseña',
          //   title: 'Perfil',
          //   user_id: getters.getProfile.id,
          // });
          resolve(resp);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  [USER_DELETE]: ({ commit, dispatch }) => {
    axios
      .delete(config.data.apiUrl + '/user/delete/current', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
        },
      })
      .then(() => {
        commit(USER_LOGOUT);
        dispatch(AUTH_LOGOUT);
      });
  },

  [USER_LOGOUT]: ({ commit }) => {
    commit(USER_LOGOUT);
  },

  /*
   * User Payments section
   */
  [USER_REQUEST_SETUP_INTENT]: ({ dispatch }, user) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/add/payment-method', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          user.stripeClientSecret = resp.data.setupToken.client_secret;
          dispatch(STRIPE_SETUP_INTENT, user).then((resp) => {
            dispatch(
              USER_REGISTER_NEW_PAYMENT_METHOD,
              resp.setupIntent.payment_method,
            );
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_REGISTER_NEW_PAYMENT_METHOD]: ({ commit }, set_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/add/new/payment-method',
          {
            set_id: set_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          commit(USER_REGISTER_NEW_PAYMENT_METHOD, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_DELETE_PAYMENT_METHOD]: (pm_id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + '/user/delete/payment-method/' + pm_id, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_UPDATE_CARD_PAYMENT_METHOD]: (pm_id) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(config.data.apiUrl + `/user/default/payment-method/${pm_id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          //commit(USER_UPDATE_CARD_PAYMENT_METHOD, resp)
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_UPDATE_PAYMENT_METHOD]: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          config.data.apiUrl + '/user/update/payment-method/' + data.pm_id,
          data.billing,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_UPDATE_DEFAULT_PAYMENT_METHOD]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          config.data.apiUrl + '/user/update/default-method',
          {
            method: data.method,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          commit(USER_UPDATE_DEFAULT_PAYMENT_METHOD, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_UPDATE_BANK_ACCOUNTS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/get/bank-account/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(USER_UPDATE_BANK_ACCOUNTS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_UPDATE_DEFAULT_BANK_ACCOUNT]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.data.apiUrl + '/user/update/default/bank-account',
          {
            id: data.id,
            last4: data.last4,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          commit(USER_CHANGE_ACH_DATA, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_GET_INVOICES]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/user/invoice', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            page: data.page,
          },
        })
        .then((resp) => {
          commit(USER_GET_INVOICES, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_DOWNLOAD_INVOICE]: (data) => {
    return new Promise((resolve) => {
      axios({
        url: config.data.apiUrl + '/user/invoices/download/' + data.id,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          responseType: 'blob', // important
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.number + '.pdf');
        document.body.appendChild(link);
        link.click();
        resolve(response);
      });
    });
  },
  /*
   * User classes section
   */
  [USER_GET_PARTNERS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/user/partners', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(USER_GET_PARTNERS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /*
   * Status invitations list
   */
  [USER_INVITATION_STATUS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/user/invitations', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(USER_INVITATION_STATUS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_USERS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/user/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_USERS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_USER]: (_, user) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.data.apiUrl + `/admin/user/delete/${user.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_IS_AUTHENTICATE]: ({ commit }) => commit(USER_IS_AUTHENTICATE),
};

const mutations = {
  [NOTIFICATION_USER_GET]: (state, resp) => {
    state.notifications = resp.data;
  },
  [USER_REQUEST]: (state, data) => {
    state.profile = {
      avatar: data.avatar ? data.avatar : { url: '' },
      id: data.id,
      name: data.name,
      phone: data.phone,
      timezone: data.timezone,
      country: data.country ?? '',
      email: data.email,
      roles: (data.roles || []).map((role) => role.name),
      notification: data.notification,
      emailVerifiedAt: data.email_verified_at,
      status: data.status,
    };
  },

  [FETCH_USER_NOTIFICATION]: (state, resp) => {
    state.userNotification = resp.data;
  },

  [USER_REGISTER_NEW_PAYMENT_METHOD]: (state, resp) => {
    state.profile.payment_methods.push(resp.data.payment_method);
  },
  [USER_UPDATE_CARD_PAYMENT_METHOD]: (state, resp) => {
    state.profile.default_payment.card_brand =
      resp.data.payment_method.card.brand;
    state.profile.default_payment.card_last_four =
      resp.data.payment_method.card.last4;
    state.profile.default_payment.pm_id = resp.data.payment_method.id;
  },
  [USER_UPDATE_DEFAULT_PAYMENT_METHOD]: (state, resp) => {
    state.profile.default_payment.method = resp.data.method;
  },
  [USER_GET_INVOICES]: (state, resp) => {
    state.invoices = resp.data;
  },

  [USER_LOGOUT]: (state) => {
    state.profile = undefined;
    state.isAuthenticated = false;
    state.token = '';
  },

  [USER_CHANGE_ACH_DATA]: (state, resp) => {
    state.profile.default_payment.ach_account = resp.data;
  },
  [DELETE_DEFAULT_ACH]: (state, resp) => {
    console.log('Entro');
    if (resp.data.source === state.profile.default_payment.ach_account.id) {
      state.profile.default_payment.ach_account = null;
    }
  },
  [USER_UPDATE_BANK_ACCOUNTS]: (state, resp) => {
    state.profile.bank_accounts = resp.data;
  },
  [USER_GET_PARTNERS]: (state, resp) => {
    state.partners = resp.data.partners;
  },
  [USER_INVITATION_STATUS]: (state, resp) => {
    state.invitations = resp.data;
  },
  [FETCH_USERS]: (state, users) => {
    state.users = {
      total: users && users.total ? users.total : 0,
      items: users && users.data ? users.data : [],
    };
  },
  [FETCH_USER_INVOICES_BY_USER]: (state, resp) => {
    state.userInvoicesByUser = resp.data;
  },
  [FETCH_ADMIN_USER_INVOICES]: (state, userInvoices) => {
    state.userInvoices = {
      total: userInvoices && userInvoices.total ? userInvoices.total : 0,
      items: userInvoices && userInvoices.data ? userInvoices.data : [],
    };
  },

  [USER_UPDATE_PAYPAL_ACCOUNTS]: (state, resp) => {
    state.profile.paypal_accounts = resp.data.accounts;
  },

  [USER_IS_AUTHENTICATE]: (state, isAuthenticated = true) => {
    state.isAuthenticated = isAuthenticated;
  },
  [AUTH_TOKEN]: (state, token = '') => {
    state.token = token;
  },
  [USER_REQUEST_ERROR]: (state, error) => {
    state.error = error;
  },
  [USER_COURSES]: (state, courses) => {
    state.courses = courses.data;
  },
  [USER_COURSE_VIDEO_FAVORITE]: (state, favorites) => {
    state.favorites = {
      items: favorites && favorites.data ? favorites.data : [],
      count: favorites && favorites.count ? favorites.count : 0,
    };
  },
  [USER_CLASS_FAVORITE]: (state, favorites) => {
    state.favoriteClasses = {
      items: favorites && favorites.data ? favorites.data : [],
      count: favorites && favorites.total ? favorites.total : 0,
    };
  },
  [USER_COURSE_VIDEO_PROGRESS]: (state, progress) => {
    state.progress = progress.data;
  },
  [USER_COURSE_VIDEO_COMPLETED]: (state, completed) => {
    state.completed = completed.data;
  },
  [FETCH_USER_CLASS_PROGRESS]: (state, progressClass) => {
    state.progressClass = progressClass.data;
  },
  [FETCH_USER_CLASS_COMPLETED]: (state, completeClass) => {
    state.completeClass = {
      items: completeClass && completeClass.data ? completeClass.data : [],
      total: completeClass && completeClass.total ? completeClass.total : 0,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
