export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const CRETE_CATEGORY = 'CRETE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const DELETE_CATEGORY_RELATION = 'DELETE_CATEGORY_RELATION';


export const CATEGORIES_GET_PUBLIC_CATEGORIES =
  'CATEGORIES_GET_PUBLIC_CATEGORIES';
