<template>
  <div id='container-video-js'>
    <video
      :id='videoId'
      class='video-js vjs-custom-skin vjs-overlay'
      controls
      preload='metadata'
    ></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import videojscontribqualitylevels from 'videojs-contrib-quality-levels';
import videojsqualityselector from 'videojs-hls-quality-selector';
import 'video.js/dist/video-js.css';

require('@silvermine/videojs-airplay')(videojs);

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default () {
        return {};
      },
    },
    videoId: {
      type: String,
      default: 'hls-video',
    },
    video: {
      type: Object,
      default: () => {
        return {
          title: '',
          content: '',
          files: [],
          products: [],
          courseVideoUser: {
            isComplete: 0,
            currentTime: 0,
          },
        };
      },
    },
  },
  emits: [
    'start-video',
    'stop-video',
    'end-video',
    'init-video',
    'current-time-video',
  ],
  data () {
    return {
      player: null,
    };
  },
  watch: {
    video: function (newValue) {
      console.log('newValue', newValue);
      if (!newValue) return;

      videojs(`#${this.videoId}`).src({
        src: newValue.video && newValue.video.url ? newValue.video.url : '', // dynamic link
        type: 'application/x-mpegURL',
      });
      videojs(`#${this.videoId}`).poster(newValue.video.thumbnail);
      //
      videojs(`#${this.videoId}`).qualityLevels = videojscontribqualitylevels;
      videojs(`#${this.videoId}`).qualityLevels();

      const vjsIconHd = document.getElementsByClassName('vjs-icon-hd');

      if (!vjsIconHd.length) {
        videojs(`#${this.videoId}`).hlsQualitySelector = videojsqualityselector;
        videojs(`#${this.videoId}`).hlsQualitySelector({
          displayCurrentQuality: false,
          vjsIconClass: 'vjs-icon-hd',
        });
      }
      console.log('DEBUG',       newValue.courseVideoUser &&
        newValue.courseVideoUser.currentTime &&
        parseInt(newValue.video.duration) > newValue.courseVideoUser.course_video_current_time);
      if (
        newValue.courseVideoUser &&
        newValue.courseVideoUser.course_video_current_time &&
        parseInt(newValue.video.duration) > newValue.courseVideoUser.course_video_current_time
      ) {
        videojs(`#${this.videoId}`).currentTime(
          newValue.courseVideoUser.course_video_current_time,
        );
      }
    },
  },

  mounted () {
    this.player = videojs(
      `#${this.videoId}`,
      this.options,
      function onPlayerReady () {
        this.hlsQualitySelector = videojsqualityselector;
        this.hlsQualitySelector({
          displayCurrentQuality: false,
          vjsIconClass: 'vjs-icon-hd',
        });

        this.addClass('vjs-maquillate');
        this.responsive(true);
      },
    );

    let vm = this;

    this.player.on('play', function () {
      vm.startVideo();
    });

    this.player.on('pause', function () {
      vm.stopVideo();
    });

    this.player.on('ended', function () {
      vm.endVideo(this.currentTime());
    });

    this.player.on('loadeddata', function () {
      vm.initialVideoTime();
    });
    this.player.on('timeupdate', function () {
      vm.currentTimeVideo(this.currentTime());
    });

    // this.player.on('mouseout', function () {
    //   this.controls(false);
    // });
    //
    // this.player.on('mouseover', function () {
    //   this.controls(true);
    // });

    this.player.qualityLevels = videojscontribqualitylevels;
    this.player.qualityLevels();
  },
  methods: {
    stopVideo () {
      this.$emit('stop-video', this.player);
    },
    currentTimeVideo (time) {
      this.$emit('current-time-video', time);
    },
    endVideo (time) {
      this.$emit('end-video', time);
      console.log('Finish video');
    },
    startVideo () {
      console.log('startVideo');
      this.$emit('start-video', this.player);
    },
    initialVideoTime () {
      console.log('initialVideoTime');
      this.$emit('init-video', this.player);
    },
  },
  // eslint-disable-next-line
  beforeDestroy () {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang='scss'>
.video-js.vjs-custom-skin {
  .vjs-big-play-button {
    display: none;
  }

  .vjs-control-bar {
    background-color: rgb(255, 255, 255);
    color: black;
    display: flex;
  }

  .vjs-menu-button-popup {
    .vjs-menu {
      .vjs-menu-content {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .vjs-volume-level {
    background-color: black;
  }

  .vjs-custom-control-spacer {
    display: flex;
    flex: 1 1 auto;
  }

  .vjs-time-divider {
    display: inherit;
  }

  .vjs-current-time {
    margin-left: 1em;
  }

  .vjs-current-time,
  .vjs-duration {
    display: inherit;
    padding: 0;
  }

  .vjs-remaining-time {
    display: none;
  }

  .vjs-play-progress:before {
    display: none;
  }

  .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 0.5em;
    top: -0.5em;

    &:hover {
      height: 1.5em;
      top: -1.5em;
    }
  }

  .vjs-progress-holder {
    margin: 0;
    height: 100%;
  }

  .vjs-play-progress {
    background-color: #f24462;
  }

  .vjs-big-play-button {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 80px !important;
    height: 80px !important;
    -webkit-border-radius: 0.8em !important;
    -moz-border-radius: 0.8em !important;
    border-radius: 1.9em !important;
    border: none;
  }
}

.video-js {
  @media (min-width: 1440px) {
    font-size: 10px;
  }

  @media (min-width: 1680px) {
    font-size: 14px;
  }
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: '';
  background-image: url(https://devmaquillate.b-cdn.net/button-white.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 55% calc(50% - 0px);
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.vjs-poster {
  background-size: cover;
}

/*
.vjs-maquillate {
  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 30px !important;
    line-height: 47.5px !important;
  }

  .vjs-playback-rate .vjs-playback-rate-value {
    pointer-events: none;
    font-size: 1.5em !important;
    line-height: 3 !important;
    text-align: center;
  }

  .vjs-control-bar {
    height: 45px !important;
  }
} */
</style>
