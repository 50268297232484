export const checkCartState = (state) => {
  return state.cart && Array.isArray(state.cart) && state.cart.length;
};

export const getCartFromStorage = () => {
  return localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : [];
};

export const getCartTotalFromStorage = () => {
  const cart = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : [];

  return cart.reduce((total, cartItem) => total + cartItem.price, 0);
};
