<template>
  <skeleton-loading>
    <ul class='file-list'>
      <li v-for='index in count' :key='index'>
        <skeleton-square

          :box-properties="{
                height: '18px',
                width:'200px'
                }"
          :count='1'
        />
      </li>
    </ul>
  </skeleton-loading>

</template>

<script>
import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';

export default {
  name: 'SkeletonCourseVideoFile',
  components: {
    SkeletonLoading,
    SkeletonSquare,
  },
  props: {
    count: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style scoped>

</style>
