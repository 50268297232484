import axios from 'axios';
import { GET_COUNTRIES } from '../actions/countries';
import config from '@/config/config';

const state = {
  countries: [],
};

const getters = {
  getAllCountries: (state) => state.countries,
};

const actions = {
  [GET_COUNTRIES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/countries')
        .then(({ data }) => {
          data = data.sort((a, b) => {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();
            if (a === b) {
              return 0;
            }
            if (a < b) {
              return -1;
            }
            return 1;
          });
          commit(GET_COUNTRIES, data);
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [GET_COUNTRIES]: (state, resp) => {
    state.countries = resp;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
