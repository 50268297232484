export const CHARTS_GET_PROFIT_REVENUE_BY_COURSE_DATA =
  'CHARTS_GET_PROFIT_REVENUE_BY_COURSE_DATA';
export const CHARTS_GET_LAST_ORDERS_BY_COURSE_DATA =
  'CHARTS_GET_LAST_ORDERS_BY_COURSE_DATA';
export const CHARTS_GET_GENERAL_STATS_BY_COURSE_DATA =
  'CHARTS_GET_GENERAL_STATS_BY_COURSE_DATA';
export const CHARTS_GET_GENERAL_STATS_BY_USER_DATA =
  'CHARTS_GET_GENERAL_STATS_BY_USER_DATA';
export const CHARTS_GET_PROFIT_REVENUE_BY_USER_DATA =
  'CHARTS_GET_PROFIT_REVENUE_BY_USER_DATA';
export const CHARTS_GET_LAST_ORDERS_BY_USER_DATA =
  'CHARTS_GET_LAST_ORDERS_BY_USER_DATA';
export const CHARTS_GET_TOP_COURSES = 'CHARTS_GET_TOP_COURSES';
export const CHARTS_GET_TOP_CUSTOMERS = 'CHARTS_GET_TOP_CUSTOMERS';
