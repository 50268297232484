<template>
  <base-nav
    :class="{ 'navbar-dark': $route.meta.navbarType === 'default' }"
    class="navbar-top navbar-expand pt-4"
    container-classes="container-fluid"
  >
    <div class="mobile-navbar">
      <h4 class="mb-0 text-muted" style="color: black !important">
        {{ title ? title : this.getTitle() }}
      </h4>
      <div v-if="isLoading" class="my-1">
        <skeleton-loading>
          <skeleton-square
            :box-properties="{
              width: '400px',
              height: '16px',
              top: 5,
            }"
            :count="1"
          ></skeleton-square>
        </skeleton-loading>
      </div>
      <div v-else class="welcome-text my-1">
        <div v-if="!getSubTitleOption()">
          {{ subtitle ? subtitle : this.getSubtitle() }}
        </div>
        <div v-else>
          {{ getSubTitleOption() }}
        </div>
      </div>
    </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          :class="{
            active: this.$sidebar.showSidebar,
            'sidenav-toggler-dark': $route.meta.navbarType === 'default',
            'sidenav-toggler-light': $route.meta.navbarType === 'light',
          }"
          class="pr-3 sidenav-toggler"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          data-action="search-show"
          data-target="#navbar-search-main"
          href="#"
        >
        </a>
      </li>
    </ul>

    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        class="nav-item"
        menu-on-right
        tag="li"
        title-classes="nav-link pr-0"
        title-tag="a"
      >
        <template #title-container>
          <a class="nav-link pr-0" href="#" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img :src="getProfile.avatar.url" alt="Image placeholder" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block mb-2">
                <span
                  class="mb-0 text-sm font-weight-bold text-active name-admin"
                  >{{ getProfile.name }}</span
                >
              </div>
            </div>
          </a>
        </template>

        <div class="dropdown-item">
          <router-link class="link" to="/admin/configuracion/cuenta">
            <span>Configuración</span>
          </router-link>
        </div>
        <div class="dropdown-item" @click="logout">
          <span>Cerrar sesión</span>
        </div>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import { AUTH_LOGOUT } from '@/store/actions/login';
import { mapGetters } from 'vuex';
import moment from 'moment';
import {
  COURSE_ROUTE_NAME,
  ADMIN_COURSE_SUBTITLE,
  ADMIN_COURSE_TITLE,
  COURSE_DETAIL_NAME,
  INSTRUCTOR_ROUTE_NAME,
  ADMIN_COURSE_INSTRUCTOR_TITLE,
  ADMIN_COURSE_INSTRUCTOR_SUBTITLE,
  COURSE_CATEGORY_ROUTE_NAME,
  ADMIN_COURSE_CATEGORY_TITLE,
  ADMIN_COURSE_CATEGORY_SUBTITLE,
  COURSE_VIDEOS_ROUTE_NAME,
  ADMIN_COURSE_VIDEOS_TITLE,
  ADMIN_COURSE_VIDEOS_SUBTITLE,
  ACTIVE_ROUTE_NAME,
  ADMIN_ACTIVE_TITLE,
  ADMIN_ACTIVE_SUBTITLE,
  USERS_ROUTE_NAME,
  ADMIN_USERS_TITLE,
  ADMIN_USERS_SUBTITLE,
  EDIT_COURSE_ROUTE_NAME,
  EDIT_COURSE_TITLE,
  EDIT_COURSE_SUBTITLE,
  ADVERTISEMENT_NAME,
  ADVERTISEMENT_TITLE,
  ADVERTISEMENT_SUBTITLE,
  USER_INVOICES_BY_USER_NAME,
  USER_INVOICES_BY_USER_TITLE,
  MEDIA_ROUTE_NAME,
  MEDIA_TITLE,
  MEDIA_SUBTITLE,
  DASHBOARD_TITLE,
  DASHBOARD_NAME,
  DASHBOARD_SUBTITLE,
  ADMIN_PRODUCT_CATEGORY_ROUTE_NAME,
  ADMIN_PRODUCT_CATEGORY_TITLE,
  ADMIN_PRODUCT_CATEGORY_SUBTITLE,
  ADMIN_COUPONS_ROUTE_NAME,
  ADMIN_COUPONS_TITLE,
  ADMIN_COUPONS_SUBTITLE,
  ADMIN_PARTNERS_ROUTE_NAME,
  ADMIN_PARTNERS_TITLE,
  ADMIN_PARTNERS_SUBTITLE,
  ADMIN_PARTNERSPAYMENT_ROUTE_NAME,
  ADMIN_PARTNERSPAYMENT_TITLE,
  ADMIN_PARTNERSPAYMENT_SUBTITLE,
  ADMIN_USER_INVOICE_ROUTE_NAME,
  ADMIN_USER_INVOICE_TITLE,
  ADMIN_USER_INVOICE_SUBTITLE,
  ADMIN_REPORTS_ROUTE_NAME,
  ADMIN_REPORTS_TITLE,
  ADMIN_REPORTS_SUBTITLE,
  ADMIN_USER_SETTING_ROUTE_NAME,
  ADMIN_USER_SETTING_TITLE,
  ADMIN_USER_SETTING_SUBTITLE,
  ADMIN_USER_SETTING_REPORTS_ROUTE_NAME,
  ADMIN_USER_SETTING_REPORTS_TITLE,
  ADMIN_USER_SETTING_REPORTS_SUBTITLE_OPTION_ACCOUNT,
  ADMIN_USER_SETTING_REPORTS_ROUTE_NAME_OPTION_ACCOUNT,
  ADMIN_USER_SETTING_REPORTS_SUBTITLE_OPTION_REPORTS,
  ADMIN_USER_SETTING_REPORTS_ROUTE_NAME_OPTION_REPORTS,
  ADMIN_COURSE_CATEGORY_COURSE_ROUTE_NAME,
  ADMIN_COURSE_CATEGORY_COURSE_TITLE,
  ADMIN_COURSE_CATEGORY_COURSE_SUBTITLE,
  ADMIN_COURSE_CATEGORY_CLASS_TITLE,
  ADMIN_COURSE_CATEGORY_CLASS_ROUTE_NAME,
  ADMIN_COURSE_CATEGORY_CLASS_SUBTITLE,
  CLASS_NAME,
  CLASS_TITLE,
  CLASS_SUBTITLE,
  CLASS_DETAILS_NAME,
  INSTRUCTOR_COURSE_ROUTE_NAME,
  ADMIN_COURSE_INSTRUCTOR_COURSES_TITLE,
  ADMIN_COURSE_INSTRUCTOR_COURSES_SUBTITLE,
  INSTRUCTOR_CLASS_ROUTE_NAME,
  ADMIN_COURSE_INSTRUCTOR_CLASSES_TITLE,
  ADMIN_COURSE_INSTRUCTOR_CLASSES_SUBTITLE,
  MARKETING_EMAIL_CAMPAIGN_ROUTE_NAME,
  MARKETING_EMAIL_CAMPAIGN_ROUTE_TITLE,
  MARKETING_EMAIL_CAMPAIGN_ROUTE_SUBTITLE,
  MARKETING_EMAIL_SEGMENT_ROUTE_NAME,
  MARKETING_EMAIL_SEGMENT_ROUTE_TITLE,
  MARKETING_EMAIL_SEGMENT_ROUTE_SUBTITLE,
  MARKETING_EMAIL_SEQUENCE_ROUTE_NAME,
  MARKETING_EMAIL_SEQUENCE_ROUTE_TITLE,
  MARKETING_EMAIL_SEQUENCE_ROUTE_SUBTITLE,
} from '../../util/constants';
import SkeletonLoading from '../../components/Skeleton/SkeletonLoading';
import SkeletonSquare from '../../components/Skeleton/components/SkeletonSquare';

moment.locale('es');

export default {
  components: {
    BaseNav,
    SkeletonLoading,
    SkeletonSquare,
  },
  data() {
    return {
      isLoading: false,
      title: '',
      subtitle: '',
    };
  },

  computed: {
    ...mapGetters([
      'getProfile',
      'getPath',
      'getUserRoles',
      'getCurrentCourse',
      'getUserInvoicesByUser',
      'getClass',
    ]),
  },
  watch: {
    $route: function (newValue) {
      const name = newValue.name;
      console.log(name);
      if (name === COURSE_DETAIL_NAME) {
        this.isLoading = true;
      } else if (name === USER_INVOICES_BY_USER_NAME) {
        this.isLoading = true;
        this.title = this.getTitle();
      } else if (name === CLASS_DETAILS_NAME) {
        this.isLoading = true;
        this.title = this.getTitle();
      } else {
        this.title = this.getTitle();
        this.subtitle = this.getSubtitle();
      }
    },
    getCurrentCourse: function (newValue) {
      this.isLoading = false;
      this.subtitle = newValue.name;
    },
    getUserInvoicesByUser: function (newValue) {
      this.isLoading = false;
      if (Array.isArray(newValue) && newValue.length) {
        this.subtitle = newValue[0].user.name;
      }
    },
    getClass: function (newValue) {
      this.isLoading = false;
      this.subtitle = newValue.title;
    },
  },
  mounted() {
    const name = this.$route.name;
    if (name === USER_INVOICES_BY_USER_NAME) this.isLoading = true;
  },
  methods: {
    getTitle() {
      const name = this.$route.name;
      switch (name) {
        case COURSE_ROUTE_NAME:
          return ADMIN_COURSE_TITLE;
        case COURSE_DETAIL_NAME:
          return ADMIN_COURSE_TITLE;
        case INSTRUCTOR_ROUTE_NAME:
          return ADMIN_COURSE_INSTRUCTOR_TITLE;

        case COURSE_CATEGORY_ROUTE_NAME:
          return ADMIN_COURSE_CATEGORY_TITLE;

        case COURSE_VIDEOS_ROUTE_NAME:
          return ADMIN_COURSE_VIDEOS_TITLE;

        case ACTIVE_ROUTE_NAME:
          return ADMIN_ACTIVE_TITLE;
        case USERS_ROUTE_NAME:
          return ADMIN_USERS_TITLE;

        case EDIT_COURSE_ROUTE_NAME:
          return EDIT_COURSE_TITLE;
        case ADVERTISEMENT_NAME:
          return ADVERTISEMENT_TITLE;
        case USER_INVOICES_BY_USER_NAME:
          return USER_INVOICES_BY_USER_TITLE;
        case DASHBOARD_NAME:
          return DASHBOARD_TITLE;
        case MEDIA_ROUTE_NAME:
          return MEDIA_TITLE;
        case ADMIN_PRODUCT_CATEGORY_ROUTE_NAME:
          return ADMIN_PRODUCT_CATEGORY_TITLE;
        case ADMIN_COUPONS_ROUTE_NAME:
          return ADMIN_COUPONS_TITLE;
        case ADMIN_PARTNERS_ROUTE_NAME:
          return ADMIN_PARTNERS_TITLE;
        case ADMIN_PARTNERSPAYMENT_ROUTE_NAME:
          return ADMIN_PARTNERSPAYMENT_TITLE;
        case ADMIN_USER_INVOICE_ROUTE_NAME:
          return ADMIN_USER_INVOICE_TITLE;
        case ADMIN_REPORTS_ROUTE_NAME:
          return ADMIN_REPORTS_TITLE;
        case ADMIN_USER_SETTING_ROUTE_NAME:
          return ADMIN_USER_SETTING_TITLE;
        case CLASS_NAME:
          return CLASS_TITLE;
        case CLASS_DETAILS_NAME:
          return CLASS_TITLE;
        case ADMIN_USER_SETTING_REPORTS_ROUTE_NAME:
          return ADMIN_USER_SETTING_REPORTS_TITLE;
        case ADMIN_COURSE_CATEGORY_COURSE_ROUTE_NAME:
          return ADMIN_COURSE_CATEGORY_COURSE_TITLE;
        case ADMIN_COURSE_CATEGORY_CLASS_ROUTE_NAME:
          return ADMIN_COURSE_CATEGORY_CLASS_TITLE;
        case INSTRUCTOR_COURSE_ROUTE_NAME:
          return ADMIN_COURSE_INSTRUCTOR_COURSES_TITLE;
        case INSTRUCTOR_CLASS_ROUTE_NAME:
          return ADMIN_COURSE_INSTRUCTOR_CLASSES_TITLE;
        case MARKETING_EMAIL_CAMPAIGN_ROUTE_NAME:
          return MARKETING_EMAIL_CAMPAIGN_ROUTE_TITLE;
        case MARKETING_EMAIL_SEGMENT_ROUTE_NAME:
          return MARKETING_EMAIL_SEGMENT_ROUTE_TITLE;
        case MARKETING_EMAIL_SEQUENCE_ROUTE_NAME:
          return MARKETING_EMAIL_SEQUENCE_ROUTE_TITLE;
        default:
          return this.getPath.title;
      }
    },
    getSubTitleOption() {
      const subname = this.$route.params.option;

      switch (subname) {
        case ADMIN_USER_SETTING_REPORTS_ROUTE_NAME_OPTION_REPORTS:
          return ADMIN_USER_SETTING_REPORTS_SUBTITLE_OPTION_REPORTS;
        case ADMIN_USER_SETTING_REPORTS_ROUTE_NAME_OPTION_ACCOUNT:
          return ADMIN_USER_SETTING_REPORTS_SUBTITLE_OPTION_ACCOUNT;

        default:
          return '';
      }
    },
    getSubtitle() {
      const name = this.$route.name;
      switch (name) {
        case COURSE_ROUTE_NAME:
          return ADMIN_COURSE_SUBTITLE;
        case INSTRUCTOR_ROUTE_NAME:
          return ADMIN_COURSE_INSTRUCTOR_SUBTITLE;
        case COURSE_CATEGORY_ROUTE_NAME:
          return ADMIN_COURSE_CATEGORY_SUBTITLE;
        case COURSE_VIDEOS_ROUTE_NAME:
          return ADMIN_COURSE_VIDEOS_SUBTITLE;
        case ACTIVE_ROUTE_NAME:
          return ADMIN_ACTIVE_SUBTITLE;
        case USERS_ROUTE_NAME:
          return ADMIN_USERS_SUBTITLE;
        case EDIT_COURSE_ROUTE_NAME:
          return EDIT_COURSE_SUBTITLE;
        case ADVERTISEMENT_NAME:
          return ADVERTISEMENT_SUBTITLE;
        case DASHBOARD_NAME:
          return DASHBOARD_SUBTITLE;
        case MEDIA_ROUTE_NAME:
          return MEDIA_SUBTITLE;
        case ADMIN_PRODUCT_CATEGORY_ROUTE_NAME:
          return ADMIN_PRODUCT_CATEGORY_SUBTITLE;
        case ADMIN_COUPONS_ROUTE_NAME:
          return ADMIN_COUPONS_SUBTITLE;
        case ADMIN_PARTNERS_ROUTE_NAME:
          return ADMIN_PARTNERS_SUBTITLE;
        case ADMIN_PARTNERSPAYMENT_ROUTE_NAME:
          return ADMIN_PARTNERSPAYMENT_SUBTITLE;
        case ADMIN_USER_INVOICE_ROUTE_NAME:
          return ADMIN_USER_INVOICE_SUBTITLE;
        case ADMIN_REPORTS_ROUTE_NAME:
          return ADMIN_REPORTS_SUBTITLE;
        case ADMIN_USER_SETTING_ROUTE_NAME:
          return ADMIN_USER_SETTING_SUBTITLE;
        case CLASS_NAME:
          return CLASS_SUBTITLE;
        case ADMIN_COURSE_CATEGORY_COURSE_ROUTE_NAME:
          return ADMIN_COURSE_CATEGORY_COURSE_SUBTITLE;
        case ADMIN_COURSE_CATEGORY_CLASS_ROUTE_NAME:
          return ADMIN_COURSE_CATEGORY_CLASS_SUBTITLE;
        case INSTRUCTOR_COURSE_ROUTE_NAME:
          return ADMIN_COURSE_INSTRUCTOR_COURSES_SUBTITLE;
        case INSTRUCTOR_CLASS_ROUTE_NAME:
          return ADMIN_COURSE_INSTRUCTOR_CLASSES_SUBTITLE;
        case MARKETING_EMAIL_CAMPAIGN_ROUTE_NAME:
          return MARKETING_EMAIL_CAMPAIGN_ROUTE_SUBTITLE;
        case MARKETING_EMAIL_SEGMENT_ROUTE_NAME:
          return MARKETING_EMAIL_SEGMENT_ROUTE_SUBTITLE;
        case MARKETING_EMAIL_SEQUENCE_ROUTE_NAME:
          return MARKETING_EMAIL_SEQUENCE_ROUTE_SUBTITLE;
        default:
          return this.getPath.title;
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT, this.getProfile.id).then(() => {
        window.location.href = '/';
      });
    },
  },
};
</script>
<style>
.dropdown .dropdown-menu .dropdown-item {
  font-size: 14px !important;
}
.link {
  text-decoration: none !important;
  color: black !important;
}
</style>
