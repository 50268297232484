<template>
  <div id="chapterForm" class="pt-4">
    <div class="form-group row">
      <label class="col-md-3 col-form-label form-control-label"
        >TITULO DE LA LECCIÓN</label
      >
      <div class="col-md-9">
        <base-input
          v-model="formData.title"
          placeholder="Titulo de la lección"
        ></base-input>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-3 col-form-label form-control-label"
        >ASIGNAR PRODUCTOS</label
      >
      <div class="col-md-9">
        <multiselect
          v-model="formData.products"
          :loading="isLoadingProducts"
          :multiple="true"
          :options="this.getProducts.items"
          :taggable="true"
          class="modify-input"
          label="title"
          placeholder="Elige los productos a asociar."
          tag-placeholder="Add this as new tag"
          track-by="id"
          @search-change="searchProduct"
        >
          <template #noOptions>La lista esta vacía</template>
          <template #noResult> No se encontraron elementos. </template>
        </multiselect>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-3 col-form-label form-control-label"
        >ASIGNAR VIDEO</label
      >
      <div class="col-md-9">
        <multiselect
          v-model="formData.video"
          :loading="isLoadingVideos"
          :option-height="104"
          :options="this.getVideos"
          :show-labels="true"
          class="modify-input"
          label="title"
          placeholder="Seleccione video de la galeria"
          track-by="id"
          deselect-label="x"
          selected-label="x"
          @search-change="searchVideo"
        >
          <template #noOptions>La lista esta vacía</template>

          <template #option="props">
            <img
              :src="props.option.thumbnail"
              alt="Video"
              class="option__image"
            />
            <div class="option__desc">
              <span class="option__title">{{ props.option.title }}</span>
              <span class="option__small">{{ props.option.content }}</span>
            </div>
          </template>
          <template #noResult>
            <div>Sin resultados</div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="form-group row">
      <div class="course-image-container col-md-6 mx-auto">
        <img
          v-if="formData.poster"
          :src="formData.poster"
          alt="Thumbnail"
          class="course-image"
        />
        <img
          v-else
          alt="Thumbnail"
          class="course-image"
          src="@/assets/images/logo/placeholder.png"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <label class="style-text-config">Imagen de video</label>
        <file-input @change="courseVideoPosterChange"></file-input>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3 col-form-label form-control-label">ARCHIVOS</label>
      <div class="col-md-9">
        <vue-dropzone
          id="file-dropzone"
          ref="filedropzone"
          :options="courseVideoFileOptions"
          @vdropzone-success="successUploadCourseVideoFile"
        ></vue-dropzone>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3 col-form-label form-control-label"
        >LISTA DE ARCHIVOS</label
      >

      <div v-if="isLoadingCourseVideoFiles" class="col-md-9">
        <skeleton-course-video-file />
      </div>
      <div v-else class="col-md-9 pt-2">
        <div v-if="this.getCourseVideoFiles.length">
          <ul class="file-list">
            <li
              v-for="courseVideoFile in this.getCourseVideoFiles"
              :key="courseVideoFile.id"
            >
              <i
                class="fas fa-times delete-button mx-3"
                @click="deleteCourseVideoFile(courseVideoFile.id)"
              ></i>

              <a
                :href="courseVideoFile.url"
                class="file-list__linkcontainer"
                style="color: #b7227e"
                target="_blank"
              >
                <i class="fas fa-file-archive"></i>
                {{ courseVideoFile.filename }}
              </a>
            </li>
          </ul>
        </div>
        <div v-else class="dark-font">
          No tiene archivos asignados a este video
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3 col-form-label form-control-label">ESTADO</label>

      <div class="col-md-9">
        <select v-model="formData.status" class="select-status">
          <option
            v-for="status in statuses"
            :key="status.status"
            :value="status.status"
          >
            {{ status.name }}
          </option>
        </select>
        <i class="fas fa-chevron-down details-select"></i>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-3 col-form-label form-control-label"
        >CONTENIDO DE LA LECCIÓN</label
      >
      <div class="col-md-9">
        <quill-editor
          ref="myQuillEditor"
          v-model="formData.content"
          :options="editorOption"
          placeholder="Contenido de la lección ..."
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12 button-container">
        <base-button
          class="btn-maquillate-cancel"
          outline
          type="danger"
          @click="closeCourseVideoForm"
        >
          Cerrar
        </base-button>

        <base-button
          :disabled="requiredFields"
          :loading="isLoading"
          class="btn-maquillate"
          @click="updateCourseVideo"
          >Guardar cambios
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import vue2Dropzone from 'vue2-dropzone';
import config from '@/config/config';
import { sha256 } from 'js-sha256';
import { quillEditor } from 'vue-quill-editor';
import { DEFAULT_COURSE_VIDEO_VALUES } from '../../util/constants';
import { FETCH_VIDEOS } from '../../store/actions/video';
import { FETCH_PRODUCTS } from '../../store/actions/products';
import {
  CLOSE_COURSE_VIDEO_FORM,
  UPDATE_COURSE_VIDEO,
} from '../../store/actions/courseVideo';
import {
  DELETE_COURSE_VIDEO_FILE,
  FETCH_COURSE_VIDEO_FILES,
} from '../../store/actions/courseVideoFile';
import SkeletonCourseVideoFile from '../../views/Components/skeletons/SkeletonCourseVideoFile';
import { UPDATE_COURSE_VIDEO_POSTER } from '../../store/actions/course';
import FileInput from '@/components/Inputs/FileInput';

let tus = require('tus-js-client');

export default {
  name: 'VideoForm',
  components: {
    Multiselect,
    vueDropzone: vue2Dropzone,
    quillEditor,
    SkeletonCourseVideoFile,
    FileInput
  },
  props: {
    courseVideo: {
      type: Object,
      default: DEFAULT_COURSE_VIDEO_VALUES,
    },
  },
  emits: ['success'],
  data() {
    return {
      isLoading: false,
      isLoadingVideos: true,
      isLoadingProducts: true,
      formData: DEFAULT_COURSE_VIDEO_VALUES,
      isLoadingCourseVideoFiles: true,
      statuses: [
        {
          status: 'DRAFT',
          name: 'Editando',
        },
        {
          status: 'PUBLISH',
          name: 'Publicado',
        },
      ],
      dropzoneOptions: {
        url: `${config.data.apiUrl}/admin/video/upload`,
        thumbnailWidth: 150,
        maxFilesize: 9999999, // MB
        timeout: 9999999, // Miliseconds
        addRemoveLinks: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
        },
      },
      courseVideoFileOptions: {
        url: `${config.data.apiUrl}/admin/course/video/file/create?course_video_id=${this.courseVideo.id}`,
        thumbnailWidth: 150,
        maxFilesize: 4000, // MB
        timeout: 9999999, // Miliseconds
        addRemoveLinks: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
        },
      },
      editorOption: {
        placeholder: 'Inserta un texto aquí...',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCourseVideo',
      'getVideoCourseProducts',
      'getInstructors',
      'getProducts',
      'getVideos',
      'getCourseVideoFiles',
    ]),
    requiredFields: function () {
      return !this.formData.title;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  beforeMount() {
    this.formData = this.courseVideo;
  },
  mounted() {
    this.$store.dispatch(FETCH_VIDEOS).then(() => {
      this.isLoadingVideos = false;
    });

    this.$store.dispatch(FETCH_PRODUCTS).then(() => {
      this.isLoadingProducts = false;
    });
    this.fetchCoursesVideoFiles();
  },
  methods: {
    fetchCoursesVideoFiles() {
      this.isLoadingCourseVideoFiles = true;
      this.$store
        .dispatch(FETCH_COURSE_VIDEO_FILES, {
          course_video_id: this.courseVideo.id,
        })
        .then(() => {
          this.isLoadingCourseVideoFiles = false;
        });
    },
    closeCourseVideoForm() {
      this.$store.dispatch(CLOSE_COURSE_VIDEO_FORM);
    },
    updateCourseVideo() {
      const oldFormData = this.formData;

      if (oldFormData.video && oldFormData.video.id) {
        oldFormData.video_id = oldFormData.video.id;
      } else {
        oldFormData.video_id = 0;
      }

      delete oldFormData.video;
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_COURSE_VIDEO, oldFormData)
        .then(() => {
          this.notify('success', 'Video se actualizo con éxito!');
          this.$emit('success');
          this.isLoading = false;
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar el video');
          this.isLoading = false;
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },

    searchVideo(searchVideoQuery) {
      this.isLoadingVideos = true;
      this.$store
        .dispatch(FETCH_VIDEOS, {
          search: searchVideoQuery,
        })
        .then(() => {
          this.isLoadingVideos = false;
        });
    },

    searchProduct(searchVideoQuery) {
      this.isLoadingProducts = true;
      this.$store
        .dispatch(FETCH_PRODUCTS, {
          search: searchVideoQuery,
        })
        .then(() => {
          this.isLoadingProducts = false;
        });
    },

    removeAllFiles() {
      this.$refs.dropzone.removeAllFiles(true);
    },
    videoSuccessUpload(file, response) {
      const vm = this;
      console.log('response', response);
      vm.$refs.dropzone.disable();
      const upload = new tus.Upload(file, {
        endpoint: 'https://video.bunnycdn.com/tusupload',
        headers: {
          AuthorizationSignature: sha256(
            '36805' +
              '08d7c26c-2f59-4de9-b3172dd59982-9aaf-4861' +
              '99999999999' +
              `${response.hash_name}`,
          ), // SHA256 signature (library_id + api_key + expiration_time + video_id)
          AuthorizationExpire: 99999999999, // Expiration time as in the signature,
          VideoId: `${response.hash_name}`, // The guid of a previously created video object through the Create Video API call
          LibraryId: 36805,
        },
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: function (error) {
          console.log('Failed because: ' + error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log('progress', percentage);
        },
        onSuccess: function () {
          vm.$refs.dropzone.enable();
          vm.notify('success', 'Se ha creado el video con éxito!');
        },
      });

      // Check if there are any previous uploads to continue.
      upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        upload.start();
      });
    },
    successUploadCourseVideoFile(file) {
      this.fetchCoursesVideoFiles();
      this.$refs.filedropzone.removeFile(file);
    },
    deleteCourseVideoFile(fileId) {
      this.$store
        .dispatch(DELETE_COURSE_VIDEO_FILE, fileId)
        .then(() => {
          this.notify('success', 'Se ha eliminado el archivo correctamente!');
          this.fetchCoursesVideoFiles();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas al eliminar el archivo');
        });
    },
    onEditorChange({ html }) {
      this.formData.content = html;
    },
    courseVideoPosterChange(files) {
      this.files = files;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.files[0]);
      const formData = new FormData();

      formData.append('id', this.formData.id);
      formData.append('file', this.files[0]);
      this.$store
        .dispatch(UPDATE_COURSE_VIDEO_POSTER, formData)
        .then(() => {
          this.$emit('success');
          this.notify('success', 'Se ha actualizado la imagen correctamente');
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar la imagen');
          this.isLoading = false;
        });
    },

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__tags-wrap {
  display: flex;
  overflow: auto;
}
.ql-toolbar .ql-stroke {
  stroke: black !important;
}
.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent;
  color: black !important;
}

.multiselect__option--selected:after {
  background: #b5016d !important;
  color: #fff;
  font-weight: 600;
  padding-right: 20px;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #b5016d !important;
  color: #fff;
}

.dark-font {
  color: #000;
}
</style>
