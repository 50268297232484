import axios from 'axios';
import {
  FETCH_CATEGORIES,
  CRETE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  FETCH_CATEGORY,
  DELETE_CATEGORY_RELATION,
} from '../actions/categories';
import config from '@/config/config';

const state = {
  categories: {
    total: 0,
    items: [],
  },
  category: [],
};

const getters = {
  getCategories: (state) => state.categories,
  getCategoryCourse: (state) => state.category,
};

const mutations = {
  [FETCH_CATEGORIES]: (state, categories) => {
    state.categories = {
      total: categories && categories.total ? categories.total : 0,
      items: categories && categories.data ? categories.data : [],
    };
  },
  [FETCH_CATEGORY]: (state, resp) => {
    state.category = {
      ...resp,
      courses: (resp.courses || []).filter(({ course }) => course),
    };
  },
};

const actions = {
  [FETCH_CATEGORY]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/course/category/${filter.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params: {
            search_course: filter.search_course,
          },
        })
        .then((resp) => {
          commit(FETCH_CATEGORY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CRETE_CATEGORY]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/course/category/create', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
          console.log(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_CATEGORY]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/course/category/update', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp);
          console.log(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [FETCH_CATEGORIES]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.data.apiUrl + '/admin/course/category/all', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params: {
            ...filter,
          },
        })
        .then((resp) => {
          commit(FETCH_CATEGORIES, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_CATEGORY]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.data.apiUrl}/admin/course/category/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_CATEGORY_RELATION]: (_, { categoryId, courseId }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${config.data.apiUrl}/admin/course/category/${categoryId}/course/${courseId}/delete`,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
