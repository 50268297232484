export const FETCH_CLASSES = 'FETCH_CLASSES';
export const FETCH_CLASS = 'FETCH_CLASS';

export const CREATE_CLASS = 'CREATE_CLASS';
export const UPDATE_CLASS = 'UPDATE_CLASS';

export const UPDATE_CLASS_FEATURE = 'UPDATE_CLASS_FEATURE';

export const DUPLICATE_CLASS = 'DUPLICATE_CLASS';

export const DELETE_CLASS = 'DELETE_CLASS';

export const FETCH_CURRENT_CLASS_USER = 'FETCH_CURRENT_CLASS_USER';

export const UPDATE_CLASS_USER = 'UPDATE_CLASS_USER';

export const UPDATE_CLASS_POSTER = 'UPDATE_CLASS_POSTER';

export const FETCH_CLASS_FILES = 'FETCH_CLASS_FILES';

export const DELETE_CLASS_FILE = 'DELETE_CLASS_FILE';

export const DELETE_CLASS_RELATION = 'DELETE_CLASS_RELATION';
