import axios from 'axios';
import {
  FETCH_METRIC_COURSES,
  FETCH_METRIC_GENERAL,
  FETCH_METRIC_INVOICES,
  FETCH_METRIC_ORDERS,
  FETCH_METRIC_USER_ORDERS,
  FETCH_METRIC_USERS,
} from '../actions/metric';
import config from '@/config/config';

const state = {
  metricInvoices: [],
  metricUsers: [],
  metricUserOrders: [],
  metricOrders: [],
  metricCourses: [],
  metricGeneral: {
    profit: {
      total: 0,
      variation: 0,
    },
    user: {
      total: 0,
      variation: 0,
    },
  },
};

const getters = {
  getMetricInvoices: (state) => state.metricInvoices,
  getMetricCourses: (state) => state.metricCourses,
  getMetricUsers: (state) => state.metricUsers,
  getMetricUserOrders: (state) => state.metricUserOrders,
  getMetricGeneral: (state) => state.metricGeneral,
  getMetricOrders: (state) => state.metricOrders,
};

const actions = {
  [FETCH_METRIC_INVOICES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/metric/all`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_METRIC_INVOICES, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_METRIC_USERS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/metric/users`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_METRIC_USERS, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_METRIC_GENERAL]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/metric/general`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_METRIC_GENERAL, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_METRIC_ORDERS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/metric/orders`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_METRIC_ORDERS, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_METRIC_COURSES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/metric/courses`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_METRIC_COURSES, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_METRIC_USER_ORDERS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/metric/user-orders`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), // the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_METRIC_USER_ORDERS, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
const mutations = {
  [FETCH_METRIC_INVOICES]: (state, metricInvoices) => {
    state.metricInvoices = metricInvoices;
  },
  [FETCH_METRIC_USERS]: (state, metricUsers) => {
    state.metricUsers = metricUsers;
  },
  [FETCH_METRIC_GENERAL]: (state, metricGeneral) => {
    state.metricGeneral = metricGeneral;
  },
  [FETCH_METRIC_ORDERS]: (state, metricOrders) => {
    state.metricOrders = metricOrders.data;
  },
  [FETCH_METRIC_COURSES]: (state, metricCourses) => {
    state.metricCourses = metricCourses.data;
  },
  [FETCH_METRIC_USER_ORDERS]: (state, metricUserOrders) => {
    state.metricUserOrders = metricUserOrders.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
