<template>
  <!-- <div class="row" v-loading="true" id="loading"></div> -->
  <div id="loading" :style="this.style" class="row m-0">
    <div>
      <img
        class="gifLoader"
        src="../../public/logo-maquillate-favicon_gif.gif"
        alt="maquillate favicon gif"
      />
    </div>

    <div v-if="title">
      {{ title }}
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Loading } from 'element-ui';

Vue.use(Loading.directive);
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      style: 'height: 100vh;',
    };
  },
  mounted() {
    this.routeLoaderLogin();
  },
  methods: {
    routeLoaderLogin() {
      if (
        this.$route.fullPath === '/login' ||
        this.$route.fullPath === '/register' ||
        this.$route.fullPath === '/recordar'
      ) {
        this.style = 'height: 100vh; background: white;';
      } else if (this.$route.path === '/explorar') {
        this.style = 'height: 200px;';
      } else {
        (this.style =
          'height: 100vh;background: white;z-index:9999;position:relative;'),
          (document.body.style = 'overflow:hidden;');
        setTimeout(function () {
          document.body.style = 'overflow:auto;';
        }, 1500);
      }
    },
  },
};
</script>
<style>
#loading {
  min-height: 100px;
  display: flex;
  justify-content: Center;
  /*background: white;*/
  align-items: center;
  flex-direction: column;
}

.heightLoading {
  height: 100vh;
}

.gifLoader {
  width: 100px;
  height: 100px !important;
}

.el-loading-spinner .path {
  stroke: #66615b !important;
}

.el-loading-mask {
  background: transparent !important;
}
</style>
