<template>
  <div class="">
    <side-bar>
      <!--      eslint-->
      <template v-if="getProfile.roles.includes('admin')" #links>
        <sidebar-item
          :link="{
            name: 'Inicio',
            icon: 'fa fa-home',
            path: '/admin',
          }"
        >
          <template #title>
            <a
              :class="
                $route.fullPath === '/admin' ? 'nav-link active' : 'nav-link'
              "
              href="/admin"
              ><i class="fa fa-home"></i
              ><span class="nav-link-text">Inicio</span></a
            >
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Videos',
            icon: 'far fa-play-circle',
          }"
        >
          <sidebar-item
            :link="{ name: 'Cursos', path: '/admin/cursos' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Clases', path: '/admin/clases' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Libreria de videos',
              path: '/admin/galeria',
            }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              name: 'Instructores',
              path: '/admin/instructores',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Categorias',
              path: '/admin/categorias',
            }"
          >
          </sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Anuncios',
            icon: 'fa fa-bullhorn',
            path: '/admin/anuncios',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Activos',
            icon: 'fas fa-palette',
            path: '/admin/medios',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Productos',
            icon: 'far fa-chart-bar',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Libreria',
              path: '/admin/productos/libreria',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Categorias',
              path: '/admin/productos/categorias',
            }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Cupones',
            icon: 'fas fa-ticket-alt',
            path: '/admin/cupones',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Socios',
            icon: 'far fa-handshake',
          }"
        >
          <sidebar-item
            :link="{ name: 'Ver Socios', path: '/admin/partners' }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              name: 'Pagos',
              path: '/admin/partners/pagos',
            }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Usuarios',
            icon: 'fas fa-user',
          }"
        >
          <sidebar-item
            :link="{ name: 'Ver Usuarios', path: '/admin/usuarios' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Facturas', path: '/admin/facturas' }"
          ></sidebar-item>
        </sidebar-item>
        <!--nueva categoria-->
        <sidebar-item
          :link="{
            name: 'Mercadeo',
            icon: 'fas fa-hand-holding-usd',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Email',
              path: '/admin/marketing/email',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Campañas',
                path: '/admin/marketing/email/campaigns',
              }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Segmentos',
                path: '/admin/marketing/email/segments',
              }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Secuencias',
                path: '/admin/marketing/email/sequence',
              }"
            ></sidebar-item>
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'SMS',
              path: '/admin/marketing/text-marketing',
            }"
          ></sidebar-item>
        </sidebar-item>
        <!--nueva categoria-->
        <sidebar-item
          :link="{
            name: 'Reportes',
            icon: 'fas fa-chart-area',
            path: '/admin/reportes',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Configuración',
            icon: 'fab fa-whmcs',
            path: '/admin/configuracion/cuenta',
          }"
        >
        </sidebar-item>
      </template>

      <template v-if="getProfile.roles.includes('partner')">
        <sidebar-item
          :link="{
            name: 'Inicio',
            icon: 'fa fa-home',
            path: '/dashboard',
          }"
        >
          <template #title>
            <a
              :class="
                $route.fullPath == '/dashboard' ? 'nav-link active' : 'nav-link'
              "
              href="/dashboard"
              ><i class="fa fa-home"></i
              ><span class="nav-link-text">Inicio</span></a
            >
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Mis cursos',
            icon: 'fas fa-archive',
            path: '/partner/cursos',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Activos',
            icon: 'fas fa-file-image',
            path: '/partner/medios',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Comisiones & Retiros',
            icon: 'fas fa-credit-card',
            path: '/partner/retiros',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Configuración',
            icon: 'fab fa-whmcs',
            path: '/partner/perfil/editar',
          }"
        >
        </sidebar-item>
      </template>

      <template v-if="getProfile.roles.includes('user')">
        <sidebar-item
          :link="{
            name: 'Inicio',
            icon: 'fa fa-home',
            path: '/user',
          }"
        >
          <template #title>
            <a
              :class="
                $route.fullPath === '/user' ? 'nav-link active' : 'nav-link'
              "
              href="/user"
              ><i class="fa fa-home"></i
              ><span class="nav-link-text">Inicio</span></a
            >
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Compras',
            icon: 'far fa-play-circle',
          }"
        >
          <sidebar-item
            :link="{ name: 'Ver compras', path: '/user/invoices' }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              name: 'Ordenes',
              path: '/user/orders',
            }"
          ></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" mode="out-in" origin="center top">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { mapGetters } from 'vuex';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import SidebarItem from '../../components/SidebarPlugin/SidebarItem.vue';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    SidebarItem,
  },
  data() {
    return {
      title: '',
      subtitle: '',
      button: null,
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getPath']),
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
  },
};
</script>
<style lang="scss"></style>
