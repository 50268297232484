export const FETCH_COURSE = 'FETCH_COURSE';
export const FETCH_COURSES = 'FETCH_COURSES';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_COURSE_THUMBNAIL = 'UPDATE_COURSE_THUMBNAIL';
export const UPDATE_COURSE_POSTER = 'UPDATE_COURSE_POSTER';
export const UPDATE_TRAILER_COURSE_POSTER = 'UPDATE_TRAILER_COURSE_POSTER';
export const UPDATE_COURSE_VIDEO_POSTER = 'UPDATE_COURSE_VIDEO_POSTER';
export const CREATE_COURSE = 'CREATE_COURSE';
export const CHANGE_COURSE_FEATURE = 'CHANGE_COURSE_FEATURE';

export const CHANGE_COURSE_FAVORITE = 'CHANGE_COURSE_FAVORITE';

export const DELETE_COURSE = 'DELETE_COURSE';
export const CREATE_COURSE_USER = 'CREATE_COURSE_USER';
export const FETCH_COURSE_USER = 'FETCH_COURSE_USER';
export const FETCH_COURSE_VIDEO_USER = 'FETCH_COURSE_VIDEO_USER';
export const CHANGE_COURSE_VIDEO_USER = 'CHANGE_COURSE_VIDEO_USER';

export const FETCH_COURSE_BULLETS = 'FETCH_COURSE_BULLETS';
export const CREATE_COURSE_BULLET = 'CREATE_COURSE_BULLET';
export const UPDATE_COURSE_BULLET = 'UPDATE_COURSE_BULLET';
export const DELETE_COURSE_BULLET = 'DELETE_COURSE_BULLET';
//TODO: CHECK ALL ENDPOINTS

export const COURSE_GET_COURSE = 'COURSE_GET_COURSE';
export const COURSE_GET_COURSEE = 'COURSE_GET_COURSEE';
export const COURSE_USER_CHECK = 'COURSE_USER_CHECK';
export const CHECK_COURSE_USER = 'CHECK_COURSE_USER';
export const COURSE_UPDATE_COURSE = 'COURSE_UPDATE_COURSE';
export const COURSE_UPDATE_THUMBNAIL_COURSE = 'COURSE_UPDATE_THUMBNAIL_COURSE';

export const COURSE_UPDATE_CHAPTER = 'COURSE_UPDATE_CHAPTER';
export const COURSE_DELETE_CHAPTER = 'COURSE_DELETE_CHAPTER';

export const COURSE_REPLICATE_COURSE = 'COURSE_REPLICATE_COURSE';

export const COURSE_UPDATE_PARTNER_DATA = 'COURSE_UPDATE_PARTNER_DATA';

export const SUCCESS_ATTACH_NEW_LESSON = 'SUCCESS_ATTACH_NEW_LESSON';

export const GET_COURSES_CLASS_CATEGORIES_ID =
  'GET_COURSES_CLASS_CATEGORIES_ID';

export const GET_CATEGORY = 'GET_CATEGORY';
/**
 * [descubre>category]
 */
export const GET_COURSES_BY_CATEGORY = 'GET_COURSES_BY_CATEGORY';
