import {
  CREATE_CLASS,
  FETCH_CLASSES,
  FETCH_CLASS,
  UPDATE_CLASS,
  UPDATE_CLASS_FEATURE,
  DUPLICATE_CLASS,
  DELETE_CLASS,
  FETCH_CURRENT_CLASS_USER,
  UPDATE_CLASS_USER,
  UPDATE_CLASS_POSTER,
  FETCH_CLASS_FILES,
  DELETE_CLASS_FILE,
  DELETE_CLASS_RELATION,
} from '../actions/classes';
import axios from 'axios';
import config from '@/config/config';

const state = {
  classes: {
    total: 0,
    items: [],
  },
  class: undefined,
  currentClassUser: undefined,
  currentClassFiles: [],
};

const getters = {
  getAllClasses: (state) => state.classes,
  getClass: (state) => state.class,
  getCurrentClassUser: (state) => state.currentClassUser,
  getClassFiles: (state) => state.currentClassFiles,
};

const actions = {
  [FETCH_CLASSES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/class/all`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_CLASSES, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_CLASS]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/class/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(FETCH_CLASS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_CURRENT_CLASS_USER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/user/class`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
          params,
        })
        .then((resp) => {
          commit(FETCH_CURRENT_CLASS_USER, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_CLASS]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/class/create`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_CLASS]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/class/update`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_CLASS_FEATURE]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/admin/class/update/featured`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DUPLICATE_CLASS]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.data.apiUrl}/admin/class/duplicate/${id}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [UPDATE_CLASS_USER]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.data.apiUrl}/user/class/update`, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_CLASS_POSTER]: (_, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.data.apiUrl + '/admin/class/update/poster', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_CLASS]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.data.apiUrl}/admin/class/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_CLASS_FILES]: ({ commit }, filter) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.data.apiUrl}/admin/class/file/all`, {
          params: filter,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          commit(FETCH_CLASS_FILES, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [DELETE_CLASS_FILE]: (_, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.data.apiUrl}/admin/class/file/delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  [DELETE_CLASS_RELATION]: (_, { categoryId, classId }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${config.data.apiUrl}/admin/class/category/${categoryId}/class/${classId}/delete`,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [FETCH_CLASSES]: (state, classes) => {
    state.classes = {
      total: classes && classes.total ? classes.total : 0,
      items: classes && classes.data ? classes.data : [],
    };
  },
  [FETCH_CLASS]: (state, _class) => {
    state.class = {
      ..._class,
      seo: {
        title: _class.seo && _class.seo.title ? _class.seo.title : '',
        description:
          _class.seo && _class.seo.description ? _class.seo.description : '',
        id: _class.seo && _class.seo.id ? _class.seo.id : '',
        keyPhrases:
          _class.seo && _class.seo.key_phrase
            ? JSON.parse(_class.seo.key_phrase)
            : [],
      },
    };

    console.log('state.class', state.class);
  },
  [FETCH_CURRENT_CLASS_USER]: (state, classUser) => {
    state.currentClassUser = {
      id: classUser.id,
      currentTime: classUser.class_current_time,
      isCompleted: classUser.is_completed ?? 0,
      isFavorite: classUser.is_favorite ?? 0,
    };
  },
  [FETCH_CLASS_FILES]: (state, classFiles) => {
    state.currentClassFiles = classFiles;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
